import { useEffect } from 'react'
import { useForgotPassword, useForgotPasswordForm } from '../../hooks'
import Swal from 'sweetalert2'

export const usePassword = ({ onClose, onOpen }) => {
  const { data: dataForgotPassword, error: errorForgotPassword, loading, forgotPassword } = useForgotPassword()

  const { handleSubmit, handleChange, setFieldValue, values, errors, resetForm } = useForgotPasswordForm({
    onSubmit: values => {
      const trimmedValues = Object.fromEntries(Object.entries(values).map(([key, value]) => [key, value.trim()]))

      forgotPassword(trimmedValues)
    },
  })

  const handleCloseModal = () => {
    onClose()
    resetForm()
  }

  useEffect(() => {
    if (!dataForgotPassword) return
    onClose()
    Swal.fire({
      title: 'Email enviado com sucesso',
      text: 'Enviamos um email para você alterar sua senha!',
      icon: 'success',
      confirmButtonText: 'Ok',
    })
  }, [dataForgotPassword])

  useEffect(() => {
    if (!errorForgotPassword) return
    onClose()
    Swal.fire({
      title: 'Ocorreu um erro',
      text: 'Não foi possível enviar um email para alteração de senha, tente novamente!',
      icon: 'error',
      confirmButtonText: 'Ok',
    })
  }, [errorForgotPassword])

  useEffect(() => {
    if (location.pathname === '/sign-in/forgot-password') {
      onOpen()
    }
  }, [])

  return {
    onClose,
    onOpen,
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    resetForm,
    loading,
    handleCloseModal,
  }
}
