import { Button } from '@/components/Form'
import React, { useEffect } from 'react'
import { useEditPassword } from './hooks/useEditPassword'
import { useFormPassword } from './hooks/useFormPassword'
import ReCAPTCHA from 'react-google-recaptcha'
import { FieldMessage, PasswordField } from '@/components/Form/Fields'
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom'

export const ResetPassword = () => {
  const { loadingPassword, editPassword, dataPassword } = useEditPassword()
  const navigate = useNavigate()
  const { verificationCode } = useParams()
  const {
    handleSubmit: handleSubmitPassword,
    values: valuesPassword,
    handleChange: handleChangePassword,
    errors: errorsPassword,
    setFieldValue,
  } = useFormPassword({
    onSubmit: async values => {
      const { password, recaptcha } = values
      editPassword(verificationCode, {
        password,
        recaptcha,
      })
    },
  })

  useEffect(() => {
    if (!dataPassword) return
    Swal.fire({
      title: 'Redefinição de Senha',
      text: 'Senha alterarada sua senha!',
      icon: 'success',
      confirmButtonText: 'Ok',
    })
    navigate('/sign-in')
  }, [dataPassword])

  return (
    <div className='flex flex-col w-full lg:max-w-[625px] justify-center'>
      <h2 className='text-text text-xl font-semibold lg:text-4xl my-2.5 lg:my-4'>Redefinição de senha</h2>
      <form onSubmit={handleSubmitPassword} className='w-full'>
        <div className='text-white py-2 mb-3 text-sm bg-secondary-100 rounded-md max-w-xs lg:max-w-full'>
          <span className='lg:text-md'>*Preencha o formulário abaixo com sua nova senha.</span>
        </div>
        <div>
          <PasswordField
            label='Nova senha'
            name='password'
            placeholder='*******'
            value={valuesPassword.password}
            isDisabled={loadingPassword}
            errorMessage={errorsPassword.password}
            onChange={handleChangePassword}
          />
          <PasswordField
            label='Confirmar nova senha'
            name='confirmNewPassword'
            placeholder='*******'
            value={valuesPassword.confirmNewPassword}
            isDisabled={loadingPassword}
            errorMessage={errorsPassword.confirmNewPassword}
            onChange={handleChangePassword}
          />
        </div>

        <div className='mb-2 mt-4 flex justify-center items-center flex-col'>
          <ReCAPTCHA
            name='recaptcha'
            render='explicit'
            onChange={value => setFieldValue('recaptcha', value)}
            sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
          />
          {errorsPassword.recaptcha && <FieldMessage message={errorsPassword.recaptcha} className='text-red-500' />}
        </div>

        <Button className='w-full py-3 lg:py-2 my-2' type='submit' isDisabled={loadingPassword}>
          <i className='fa-solid fa-check' /> Editar senha
        </Button>
      </form>
    </div>
  )
}
