import { PercentAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { twMerge } from 'tailwind-merge'

export const ProfitItem = ({ icon, value = 0, title, border, percent, className }) => {
  return (
    <div className={twMerge('w-full h-[90%] flex gap-3 items-center lg:justify-center', className)}>
      <div className='flex p-2 aspect-square rounded-2 bg-primary-50 shrink-0'>
        <img className='w-4 lg:w-[28px]' src={icon} alt='icon' />
      </div>

      <div className='flex flex-col items-start'>
        <div className='text-lg lg:text-2xl flex -mb-1 whitespace-nowrap'>
          {percent ? <PercentAsText value={value} /> : <MoneyAsText value={value} />}
        </div>
        <p className='text-xs md:text-sm text-slate-400 md:text-slate-100 whitespace-nowrap'>{title}</p>
      </div>

      {border && <span className='w-[2px] h-5 ml-3 bg-slate-700 hidden lg:block' />}
    </div>
  )
}
