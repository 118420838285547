import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchPercentLicense = () => {
  const { data, error, makeRequest, loading } = useApi(params => {
    const { route, method } = getEndpoint('percentLicense')
    return httpClient[method](route, { ...params })
  })

  return { dataPercentLicense: data, isLoadingPercentLicense: loading, fetchPercentLicense: makeRequest, error }
}
