import httpClient from '@/utils/httpClient'
import { useApi } from '@/hooks'
import { getEndpoint } from '@/endpoints'

export const useFetchAmountBet = () => {
  const { route, method } = getEndpoint('contractOffers')
  const { data, loading, makeRequest } = useApi(() => httpClient[method](route))

  return {
    contractOffers: data || [],
    loadingAmountBet: !data || loading,
    fetchAmountBet: makeRequest,
  }
}
