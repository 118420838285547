import { useAuth } from '@/contexts'
import { formatPhone } from '@/utils/stringHelper'
import { AiOutlineMail } from 'react-icons/ai'
import { BsPersonCircle, BsTelephone } from 'react-icons/bs'
import { twMerge } from 'tailwind-merge'

export const PersonalInfo = ({ className }) => {
  const { user } = useAuth()
  return (
    <div
      className={twMerge(
        'hidden md:flex gap-3 flex-col xl:flex-row justify-between w-full pt-1 md:pb-4 md:border-b md:mt-3',
        className
      )}
    >
      <p className='flex items-center gap-2 text-text'>
        <BsPersonCircle size={22} className='text-primary' />
        {user?.username}
      </p>
      <p className='flex items-center gap-2 text-text'>
        <AiOutlineMail size={22} className='text-primary' />
        <span className='truncate'>{user?.email}</span>
      </p>
      {user?.phone ? (
        <p className='flex items-center gap-2 text-text'>
          <BsTelephone size={22} className='text-primary' />
          {formatPhone(user?.phone)}
        </p>
      ) : (
        '-'
      )}
    </div>
  )
}
