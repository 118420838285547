import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Form'
import { ModalWithdrawalsExtract } from './ModalWithdrawalsExtract'
import { useState } from 'react'
import { BalanceCards } from '@/components/Common/BalanceCards'

export const CardWithdrawSection = () => {
  const { t } = useTranslation()
  const [isOpenModal, setOpenModal] = useState(false)

  return (
    <div className='flex flex-col gap-3'>
      <div className='w-full flex justify-between items-center flex-wrap gap-2'>
        <p className='text-xl'>{t('withdraw.titleWithdraw')}</p>
        <Button onClick={() => setOpenModal(true)} text='Solicitações de saque' className='text-base' />
      </div>

      <ModalWithdrawalsExtract isOpen={isOpenModal} onClose={() => setOpenModal(false)} />

      <BalanceCards />
    </div>
  )
}
