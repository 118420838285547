import { GraduationItem } from './GraduationItem'
import { Loading } from '@/components/Common'

export const GraduationList = ({ dataGraduation, isLoadingGraduation }) => {
  return (
    <div className='w-full grid min-[425px]:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-3'>
      {!isLoadingGraduation && dataGraduation && dataGraduation.map(item => <GraduationItem key={item.id} graduation={item} />)}

      {isLoadingGraduation && <Loading />}
    </div>
  )
}
