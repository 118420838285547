import { LoginHeader } from '@/components/Menu'
import React from 'react'

export const LoginLayout = ({ children }) => {
  return (
    <main className='flex flex-col h-screen items-center bg-background font-sora'>
      <LoginHeader />
      <section className='w-full max-w-[1440px]'>
        <div className='px-4 lg:flex lg:justify-center lg:items-center lg:px-10 lg:py-3'>{children}</div>
      </section>
    </main>
  )
}
