import { LuCalendarDays } from 'react-icons/lu'
import { VscListSelection } from 'react-icons/vsc'
import { useFetchProfitList } from '../hooks/usefetchProfitList'
import { useEffect } from 'react'
import { DateAsText, PercentAsText } from '@/components/Common'
import { Spinner } from '@chakra-ui/react'
import { LuFileSpreadsheet } from 'react-icons/lu'

export const ProfitList = () => {
  const { data, fetchProfitList, isLoading } = useFetchProfitList()

  useEffect(() => {
    fetchProfitList()
  }, [])

  return (
    <div className='w-full flex flex-col gap-3 md:gap-5 mb-3'>
      <h3 className='text-lg md:text-xl'>Tabela de lucros</h3>

      {data.length > 0 && (
        <div className='w-full grid grid-cols-4 p-3 md:p-0 md:pb-3 bg-blue-400 md:bg-transparent rounded-2 md:rounded-none md:border-b border-zinc-600'>
          <div className='flex items-center gap-2 md:pr-2'>
            <LuCalendarDays />
            <p className='block md:hidden'>Data</p>
            <p className='hidden md:block pr-3 md:border-r-2 border-zinc-500 truncate'>Data da operação</p>
          </div>

          <div className='col-span-2 flex items-center gap-2'>
            <VscListSelection />
            <p>Descrição</p>
          </div>

          <p className='text-center md:text-end flex gap-2 justify-end'>
            <span className='hidden md:block mr-2 h-4 w-[2px] bg-zinc-500' />
            <span>%</span>
            <span className='hidden md:block'>Porcentagem final</span>
          </p>
        </div>
      )}

      {data &&
        data.map(item => (
          <div
            key={item.id}
            className='w-full grid md:grid-cols-4 items-center gap-2 p-3 md:p-0 md:pb-3 md:text-base bg-blue-400 md:bg-transparent rounded-2 md:rounded-none md:border-b border-zinc-600 text-sm'
          >
            <p className='flex justify-start items-center'>
              <DateAsText date={item.scheduleDate} />
            </p>

            <div className='col-span-2 flex justify-start items-center '>
              <p>{item.description}</p>
            </div>

            <div className='flex flex-row-reverse md:flex-row justify-end gap-1 md:gap-2 md:items-end'>
              <p>
                <PercentAsText value={item.percent} />
              </p>
              <img className='w-4' src={item.percent > 0 ? '/icons/trendUp.svg' : '/icons/trendDown.svg'} alt='percent graph' />
            </div>
          </div>
        ))}

      {data.length === 0 && !isLoading && (
        <div className='w-full flex justify-center items-center gap-2 p-3 bg-blue-400 rounded-2 text-sm'>
          <LuFileSpreadsheet className='text-lg' />
          <p>Nenhum registro encontrado</p>
        </div>
      )}

      {isLoading && <Spinner className='mx-auto' />}
    </div>
  )
}
