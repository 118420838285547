import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchProfitList = () => {
  const { data, error, makeRequest, loading } = useApi(params => {
    const { route, method } = getEndpoint('lastOperations')
    return httpClient[method](route, { ...params })
  })

  return { data: data || [], isLoading: loading, fetchProfitList: makeRequest, error }
}
