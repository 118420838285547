import { useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { twJoin } from 'tailwind-merge'
import { motion } from 'framer-motion'
import { LayerBlur } from '@/components/Common/LayerBlur'
import { getGraduationName, getRulesDescription } from '@/constants/careerPath'

export const GraduationMain = ({ graduation, showBlur, title, open = false }) => {
  const [openDrop, setOpenDrop] = useState(open)

  const handleToggleDrop = () => {
    setOpenDrop(prev => !prev)
  }

  return (
    <div className='relative z-[2]'>
      {showBlur && (
        <>
          <LayerBlur
            className={twJoin('hidden md:block bg-primary-50 top-0 -left-2 transition-all opacity-0', openDrop && 'opacity-100')}
          />

          <LayerBlur
            className={twJoin('md:hidden bg-secondary-100 bottom-2 -right-2 opacity-0 delay-500', openDrop && 'opacity-100')}
          />
        </>
      )}

      <div className='flex flex-col gap-3 md:gap-4 bg-blue-400 rounded-2 h-fit relative z-[2]'>
        <button onClick={handleToggleDrop} className='flex justify-between gap-3 items-center p-3 md:p-4 md:pb-0 pb-0'>
          <p className='text-[18px] text-start'>
            {title}: {getGraduationName(graduation?.position) || ''}
          </p>

          <MdOutlineKeyboardArrowDown className={twJoin('transition text-xl', openDrop && 'rotate-180')} />
        </button>

        <motion.div
          variants={{ open: { opacity: 1, maxHeight: 500 }, closed: { opacity: 0, maxHeight: 0 } }}
          animate={openDrop ? 'open' : 'closed'}
          className='flex flex-col gap-3 ease-in duration-200 overflow-hidden'
        >
          <div className='w-full h-[2px] bg-slate-700/50' />

          <div className='flex flex-col lg:flex-row items-start md:items-center gap-3 md:gap-5 p-3 md:p-4 md:pt-0 pt-0'>
            <div className='flex justify-start mr-auto items-center gap-3 shrink-0'>
              <img className='w-9 md:w-32 shrink-0' src='/icons/goalIconFull.svg' alt='goal' />
              <p className='text-[18px] font-light flex lg:hidden'>Você bateu todas as metas, parabéns!</p>
            </div>

            <div className='w-full flex flex-col justify-center gap-2 md:gap-3'>
              <p className='text-[18px] font-light hidden lg:flex'>Você bateu todas as metas, parabéns!</p>

              {graduation?.rules &&
                graduation.rules?.map(item => (
                  <div key={item.id} className='flex gap-2 items-center'>
                    <span className='flex justify-center items-center w-3 h-3 shrink-0 bg-primary-50 rounded-full'>
                      <FaCheck className='text-[8px] text-slate-500' />
                    </span>
                    <p className='text-slate-400'>{getRulesDescription(item.value, item.type)}</p>
                  </div>
                ))}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}
