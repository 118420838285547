import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react'

export function SimpleAccordion({ children }) {
  return (
    <Accordion
      defaultIndex={[0]}
      allowToggle
      backgroundColor='#212530'
      minW='326px'
      border='none'
      borderRadius='10px'
      overflow='hidden'
    >
      {children}
    </Accordion>
  )
}

function AccordionContent({ title, children }) {
  return (
    <AccordionItem>
      <Box>
        <AccordionButton _focus={{ bg: 'initial !important' }} padding='28px 17px'>
          <Box as='span' flex='1' textAlign='left' fontSize='18px' textColor='white'>
            {title}
          </Box>
          <AccordionIcon fontSize={26} textColor='white' />
        </AccordionButton>
      </Box>
      {children}
    </AccordionItem>
  )
}

function AccordionItemPanel({ children }) {
  return (
    <AccordionPanel borderTop='1px dashed #414755' pt={5} textColor='white' pb={4}>
      {children}
    </AccordionPanel>
  )
}

SimpleAccordion.Content = AccordionContent
SimpleAccordion.Item = AccordionItemPanel
