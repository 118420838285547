import { useApi } from '@/hooks'
import { getEndpoint } from '@/endpoints'
import httpClient from '@/utils/httpClient'

export const useFetchBetList = () => {
  const { route, method } = getEndpoint('myBets')
  const { data, loading, makeRequest, error } = useApi(() => httpClient[method](route))

  return { betList: data || [], isLoadingBetList: loading, fetchBetLis: makeRequest, error }
}
