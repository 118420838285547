import React from 'react'
import { Filters } from './components/Filters'
import { ExtractList } from './components/ExtractList'
import { useExtractPage } from './hooks/useExtractPage'

export const Extract = () => {
  const { extractList, fetchExtract, formFilters } = useExtractPage()

  return (
    <div className='flex flex-col p-3 text-slate-50'>
      <p className='font-medium text-lg mb-5'>EXTRATO</p>

      <Filters formFilters={formFilters} loading={fetchExtract.loading} />

      <ExtractList extract={extractList || []} fetchExtract={fetchExtract} />
    </div>
  )
}
