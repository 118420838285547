import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

export const InputText = ({ name, value, onChange, type, className, isDisabled, isInvalid, ...rest }) => {
  const inputClassName = twMerge(
    classNames(
      'w-full block bg-transparent mt-2 py-3 px-3 lg:py-2.5 border border-gray-200 rounded-[0.284rem] outline-none transition ease-in-out duration-300 delay-100 focus:border-white-50',
      className,
      {
        'text-text border-gray-200': !isInvalid,
        'text-negative border-negative': isInvalid,
        'text-gray-100 opacity-30': isDisabled,
      }
    )
  )
  return (
    <input
      style={{ colorScheme: 'dark' }}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      disabled={isDisabled}
      className={inputClassName}
      {...rest}
    />
  )
}

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
}

InputText.defaultProps = {
  type: 'text',
  isDisabled: false,
  isInvalid: false,
}
