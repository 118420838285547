import { Badge, DateAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { FiList } from 'react-icons/fi'
import { IoCalendarOutline } from 'react-icons/io5'

export const ExtractCard = ({ date, description, value, type, item }) => {
  const { t } = useTranslation()

  return (
    <div className='w-full flex-col md:flex-row flex justify-between items-center p-3 px-4 bg-blue-400 rounded-3 bg-surface relative'>
      <div className='w-full md:w-auto flex flex-col gap-3 items-start mb-6 md:mb-0'>
        <div className='flex gap-2 items-center text-slate-300'>
          <IoCalendarOutline className='md:text-lg' />
          <p className='leading-4 mb-[-2px] text-sm ms:text-base'>
            <DateAsText type='datetime' date={date} />
          </p>
        </div>

        <p className='text-base md:text-[18px]'>{description}</p>

        <p
          className={classNames('-mt-1 font-medium text-base md:text-[18px]', {
            'text-green-700': value > 0,
            'text-red-700': value < 0,
          })}
        >
          <MoneyAsText value={value} />
        </p>
      </div>

      <div className='flex flex-col md:flex-row items-center gap-2 absolute md:static bottom-[12px] right-4'>
        {item.details?.from && (
          <Badge
            className='p-2 md:px-4 flex items-center gap-2 font-normal bg-secondary text-zinc-100 text-base'
            text={
              <p className='text-sm md:text-base'>
                Afiliado: {item.details?.from.user.username} (Nível {item.details?.level})
              </p>
            }
          />
        )}

        <Badge
          className='p-2 md:px-4 flex items-center gap-2 font-normal bg-primary text-zinc-900 text-base '
          text={
            <>
              <FiList className='md:text-lg' />
              <p className='text-sm md:text-base'>{t(type)}</p>
            </>
          }
        />
      </div>
    </div>
  )
}
