import { CpfCnpjField, PhoneField, SelectField, TextField } from '@/components/Form/Fields'
import { ButtonSection } from '.'
import { useTranslation } from 'react-i18next'
import { getCurrencyCodeOptions } from '../constants'
import { useAuth } from '@/contexts'
import { BiEditAlt } from 'react-icons/bi'
import { Tooltip } from '@chakra-ui/react'
import { FcCancel } from 'react-icons/fc'
import classNames from 'classnames'

export const FormProfile = ({ formProfile, loadingEditProfile, setEditCpf, editCpf }) => {
  const { t } = useTranslation()
  const { handleSubmit, values, handleChange, errors, setFieldValue, setFieldError } = formProfile
  const { user } = useAuth()

  return (
    <form onSubmit={handleSubmit} className='flex flex-col gap-1'>
      <div className='flex flex-col lg:flex-row items-center gap-1 md:gap-3 w-full'>
        <TextField
          name='name'
          placeholder='Ex.: João da Silva'
          value={values.name}
          onChange={handleChange}
          errorMessage={errors.name}
          label='Nome'
          className='w-full'
        />
        <TextField
          name='username'
          placeholder='joao_da_silva'
          value={values.username}
          onChange={handleChange}
          errorMessage={errors.username}
          label='Login'
          className='w-full'
        />
        <TextField
          name='email'
          value={values.email}
          onChange={e => setFieldValue('email', e.target.value.trim())}
          errorMessage={errors.email}
          label='E-mail'
          placeholder='Ex.: joaodasilva@email.com'
          className='w-full'
        />
      </div>
      <div className='flex flex-col lg:flex-row items-center gap-1 md:gap-3 w-full'>
        <PhoneField
          name='phone'
          isDisabled={loadingEditProfile}
          value={values.phone}
          onChange={handleChange}
          errorMessage={errors.phone}
          label='Telefone'
          placeholder='Ex.: 99999-9999'
          className='w-full'
        />
        {editCpf ? (
          <div className='flex flex-row gap-1 w-full relative'>
            <CpfCnpjField
              name='cpf'
              label='CPF'
              placeholder='Ex.: 123.345.678-90'
              isDisabled={loadingEditProfile}
              value={values.cpf}
              onChange={handleChange}
              errorMessage={errors.cpf}
              className='w-full'
            />
            {user?.cpf && (
              <div
                className={classNames('absolute bottom-[32px] lg:bottom-[28px] right-3 cursor-pointer text-text', {
                  'bottom-[50px] lg:bottom-[42px]': errors.cpf,
                })}
                onClick={() => {
                  setEditCpf(!editCpf)
                  setFieldValue('cpf', user.cpf)
                  setFieldError('cpf', '')
                }}
              >
                <Tooltip hasArrow label='Cancelar'>
                  <div>
                    <FcCancel className='w-4 h-4' />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        ) : (
          <div className='flex flex-row gap-1 w-full relative'>
            <TextField
              isDisabled
              value={user?.cpf ? user?.maskedCpf : '-'}
              label={t('global.cpf')}
              placeholder={t('global.placeholder.placeholderCpf')}
              className='w-full'
            />
            <div
              className='absolute bottom-[32px] lg:bottom-[30px] right-3 cursor-pointer text-text'
              onClick={() => {
                setEditCpf(!editCpf)
                setFieldValue('cpf', '')
              }}
            >
              <Tooltip hasArrow label='Editar CPF'>
                <div>
                  <BiEditAlt className='w-4 h-4' />
                </div>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      <div className='flex flex-col lg:flex-row items-center gap-1 md:gap-3 w-full md:border-b md:pb-4 '>
        <TextField
          name='address'
          placeholder='Rua A, 123, Bairro dos Anjos'
          isDisabled={loadingEditProfile}
          value={values.address}
          onChange={handleChange}
          errorMessage={errors.address}
          label='Endereço'
          className='w-full'
        />
        <SelectField
          name='currencyCode'
          isDisabled={loadingEditProfile}
          value={values.currencyCode}
          onChange={handleChange}
          errorMessage={errors.currencyCode}
          label='Moeda'
          options={getCurrencyCodeOptions()}
          className='w-full text-text'
        />
      </div>
      <ButtonSection loadingEditProfile={loadingEditProfile} />
    </form>
  )
}
