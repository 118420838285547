import { BetStatusCard } from './BetStatusCard'

export function FollowYourBets({ data }) {
  return (
    <div className='mt-[41px] pt-[37px] border-t border-[#363C4D] flex flex-col gap-[37px]'>
      <h3 className='text-white text-[18px] md:text-lg lg:text-xl'>Acompanhe seus contratos</h3>
      <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-[22px]'>
        {data && data.length > 0 ? (
          data.map((item, ind) => (
            <BetStatusCard
              key={ind}
              value={item.amount}
              date={item.createdAt}
              type={item.status}
              refuseReason={item.refuseReason || ''}
            />
          ))
        ) : (
          <div className='border rounded-2 px-3 py-4 flex items-center'>
            <p className='text-white'>Ainda não há contratações realizadas!</p>
          </div>
        )}
      </div>
    </div>
  )
}
