import { useState } from 'react'
import { AffiliateUser } from './AffiliateUser'
import classNames from 'classnames'

export const AffiliateNetwork = ({ affiliate }) => {
  const [isExpanded, setExpanded] = useState(false)
  return (
    <div
      className={classNames('w-full flex flex-col gap-3', {
        'border lg:border-0 border-gray rounded-3 p-2 sm:p-3 lg:p-0': affiliate.level === 1,
      })}
    >
      <AffiliateUser
        phone={affiliate.phone}
        username={affiliate.username}
        name={affiliate.name}
        level={affiliate.level}
        isActive={affiliate.isActive}
        affiliateChildren={affiliate.children}
        affiliate={affiliate}
        type='Ativo'
        isExpanded={isExpanded}
        setExpanded={setExpanded}
      />

      {isExpanded && (
        <div className='w-full flex flex-col gap-3'>
          {affiliate.children.length > 0 &&
            affiliate.children.map((item, ind) => <AffiliateNetwork key={ind} affiliate={item} />)}
        </div>
      )}
    </div>
  )
}
