import httpClient from '@/utils/httpClient'
import { useApi } from '@/hooks'
import { getEndpoint } from '@/endpoints'

export const useFetchContractOffers = () => {
  const { route, method } = getEndpoint('contractOffers')
  const { data, loading, makeRequest } = useApi(() => httpClient[method](route))

  return {
    dataContractOffers: data,
    loadingContractOffers: !data || loading,
    fetchContractOffers: makeRequest,
  }
}
