import React from 'react'
import { motion } from 'framer-motion'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { MdContentCopy } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Form'
import { useBets } from '@/contexts/BetsContext'

export const GetawayPixPasteAndCole = () => {
  const { t } = useTranslation()
  const { qrCodeData, handleCancelPix } = useBets()
  return (
    <div className='flex flex-col items-center gap-4 w-full lg:grid lg:grid-cols-2'>
      <div className='flex flex-col items-center lg:flex-row lg:items-start'>
        <motion.img
          initial={{ scale: 0.75, opacity: 0.5 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ type: 'spring', mass: 0.8 }}
          className='max-w-[300px] mx-auto w-full object-cover shrink-0'
          src={qrCodeData.qrCode}
          alt='QR Code'
        />
      </div>

      <div className='flex flex-col gap-3 w-full h-full lg:border-l lg:pl-4'>
        <h2 className='text-text'>Chave Pix:</h2>
        <CopyToClipboard
          text={qrCodeData.pixKey}
          onCopy={() => {
            toast.success(t('bets.toastCopy'), {
              position: 'top-center',
              autoClose: 2000,
            })
          }}
        >
          <button className='flex gap-2 items-center overflow-hidden w-full border border-gray p-4 px-3 rounded-lg'>
            <MdContentCopy className='text-xl text-secondary' />
            <p className='text-text truncate'>{qrCodeData.pixKey}</p>
          </button>
        </CopyToClipboard>
        <Button text={t('bets.cancel')} className='bg-negative py-3 w-full' onClick={() => handleCancelPix()} />
      </div>
    </div>
  )
}
