import { MoneyField, TextField } from '@/components/Form/Fields'
import { useWithdraw } from '../context/WithdrawContext'
import { Button } from '@/components/Form'
import { ClockInformation } from '.'
import { useTranslation } from 'react-i18next'

export const FormPaypal = () => {
  const { t } = useTranslation()
  const { formPaypal, loadingRequestWithdraw } = useWithdraw()
  const { handleSubmit, values, errors, setFieldValue } = formPaypal
  return (
    <form onSubmit={handleSubmit}>
      <div className='flex flex-col md:flex-row gap-3 items-center w-full'>
        <MoneyField
          label={t('withdraw.amountField')}
          isDisabled={loadingRequestWithdraw}
          errorMessage={errors.amount}
          name='amount'
          onChange={e => setFieldValue('amount', e.numberValue)}
          className='w-full'
          inputClassName='bg-blue-300'
          value={values.amount}
          placeholder='Ex.: R$ 100,00'
          noMargins
        />
        <TextField
          label='E-mail ou Telefone'
          isDisabled={loadingRequestWithdraw}
          errorMessage={errors.paypalEmailOrPhone}
          name='paypalEmailOrPhone'
          onChange={e => setFieldValue('email', e.target.value.trim())}
          className='w-full'
          inputClassName='bg-blue-300'
          value={values.paypalEmailOrPhone}
          placeholder='Ex.: joaosilva@email.com'
          noMargins
        />
      </div>
      <ClockInformation className='my-3' />
      <Button
        isLoading={loadingRequestWithdraw}
        isDisabled={loadingRequestWithdraw}
        type='submit'
        text={t('withdraw.buttonConfirm')}
        className='w-full py-3'
      />
    </form>
  )
}
