import httpClient from '@/utils/httpClient'
import { useAxios } from '@/hooks'

export const useFetchPrice = () => {
  const {
    data: dataCoins,
    makeRequest: makeRequestCoins,
    loading: loadingCoins,
  } = useAxios(() => httpClient.get('https://economia.awesomeapi.com.br/last/BTC-BRL,USD-BRL'))
  return { dataCoins, makeRequestCoins, loadingCoins }
}
