import React from 'react'
import { MoneyAsInput } from '.'

export const SpinnerInput = ({ name, value, onChange, handleDecrease, handleIncrease, isDisabled, inputClassName, ...rest }) => {
  return (
    <div className='flex items-center gap-2'>
      <button
        type='button'
        onClick={handleDecrease}
        className='rounded-full h-[42px] bg-primary text-black text-lg aspect-square'
        disabled={value === 0 || isDisabled}
      >
        -
      </button>
      <MoneyAsInput
        onChange={onChange}
        value={value}
        name={name}
        disabled={isDisabled}
        inputClassName={inputClassName}
        {...rest}
      />

      <button
        type='button'
        onClick={handleIncrease}
        className='rounded-full h-[42px] bg-primary text-black text-lg aspect-square'
        disabled={isDisabled}
      >
        +
      </button>
    </div>
  )
}
