import { LayerBlur } from '@/components/Common/LayerBlur'
import { GraduationList } from '../components/GraduationList'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { GraduationItem } from '../components/GraduationItem'
import { useFetchGraduation } from '../hooks/useFetchGraduation'
import { useAuth } from '@/contexts'

export const CareerPath = () => {
  const [select, setSelect] = useState('list')
  const { dataGraduation, fetchGraduation, isLoadingGraduation } = useFetchGraduation()
  const { user } = useAuth()

  useEffect(() => {
    fetchGraduation()
  }, [])

  return (
    <div className='relative z-[2] mb-6'>
      <LayerBlur className='bg-primary text-black -left-1 bottom-[50%]' />

      <div className='w-full flex flex-col gap-3 md:gap-4 bg-blue-400 rounded-2'>
        <div className='gap-3 p-3 pt-4 md:px-5 pb-0 w-full grid grid-cols-2 max-w-md'>
          <button
            onClick={() => setSelect('list')}
            className={classNames('bg-blue-100 p-2 py-2.5 rounded-2 text-sm', { 'bg-primary text-black': select === 'list' })}
          >
            Plano de Qualificação
          </button>

          <button
            onClick={() => setSelect('actual')}
            className={classNames('bg-blue-100 p-2 py-2.5 rounded-2 text-sm', { 'bg-primary text-black': select === 'actual' })}
          >
            Qualificação atual
          </button>
        </div>

        <div className='w-full h-[2px] bg-slate-700/50' />

        <div className='flex flex-col items-center gap-3 md:gap-4 p-3 pb-4 md:px-5 pt-0'>
          <div className='w-full flex justify-center'>
            {select === 'actual' ? (
              <h3 className='text-lg mx-auto md:text-xl text-center'>Sua Qualificação</h3>
            ) : (
              <h3 className='text-lg mr-auto md:text-xl'>Acompanhe sua evolução</h3>
            )}
          </div>

          {select === 'list' && <GraduationList dataGraduation={dataGraduation} isLoadingGraduation={isLoadingGraduation} />}

          {select === 'actual' && (
            <div className='w-full flex justify-center'>
              <GraduationItem
                graduation={[...dataGraduation].reverse().find(item => item.position === user.careerPath.graduation)}
                hiddeRules
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
