import classNames from 'classnames'

export const SummationItem = ({ icon, value, text, isSelected }) => {
  return (
    <div
      className={classNames(
        'flex flex-col justify-evenly items-center gap-2 rounded p-3 transition duration-200',
        {
          'bg-white': isSelected,
        },
        { 'bg-gray-50': !isSelected }
      )}
    >
      <div className='flex items-center gap-2 p-[12px] py-2 rounded bg-primary '>
        <img src={icon} className='w-3 md:w-4 aspect-square' />
        <p className='leading-3'>{value}</p>
      </div>

      <p className='my-auto text-center w-[140px] text-sm md:text-base text-white'>{text}</p>
    </div>
  )
}
