import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { useCreateCustomer, useIndicationCode, useSignUpForm } from './index'
import { useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import { useToast } from '@chakra-ui/react'

export const useSignUpPage = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { t } = useTranslation()
  const { indicatedCode } = useParams()
  const captchaRef = useRef(null)

  const createCustomer = useCreateCustomer()
  const { data: dataIndicatedBy, indicatedByRequest } = useIndicationCode()

  const formSignUp = useSignUpForm({
    onSubmit: async values => {
      await createCustomer
        .makeRequest(values)
        .then(() => {
          Swal.fire({
            title: `${t('signUp.successMessageTitle')}`,
            text: `${t('signUp.successMessageText')}`,
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then(() => navigate('/sign-in'))
        })
        .catch(err => {
          const errorResponse = err.response.data.data
          if (errorResponse?.error === 'usernameAlreadyExists') {
            formSignUp.setFieldError('username', 'Username já em uso!')
            return
          }
          if (errorResponse?.error === 'emailAlreadyExists') {
            formSignUp.setFieldError('email', 'E-mail já cadastrado!')
            return
          }
          if (errorResponse?.field === 'recaptcha') {
            formSignUp.setFieldValue('recaptcha', '')
            formSignUp.setFieldError('recaptcha', 'Preencha novamente!')
            captchaRef.current?.reset()
            return
          }
          toast({
            description: 'Erro ao Realizar Cadastro.',
            status: 'error',
            duration: 2000,
            isClosable: true,
            position: 'top-right',
          })
        })
    },
  })

  useEffect(() => {
    indicatedByRequest(indicatedCode).then(({ data }) => {
      const dataResponse = data.data
      formSignUp.setFieldValue('indicatedBy', dataResponse.id)
    })
  }, [])

  return { indicatedCode, dataIndicatedBy, createCustomer, formSignUp, captchaRef }
}
