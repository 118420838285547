import { Modal } from '@/components/Common/Modal/Modal'
import { Button } from '@/components/Form'
import { TextField } from '@/components/Form/Fields'
import { usePassword } from './usePassword'

export const ModalForgotPassword = ({ isOpen, onClose, onOpen }) => {
  const { handleSubmit, setFieldValue, values, errors, handleCloseModal, loading } = usePassword({ onClose, onOpen })

  return (
    <Modal.Root isOpen={isOpen} onClose={handleCloseModal} size='lg' bg='#000'>
      <Modal.Header text='Esqueci a Senha' classNameHeader='bg-black text-white rounded-t-md border-b border-blue-100' />
      <Modal.Body className='bg-black rounded-b-md'>
        <form onSubmit={handleSubmit} className='flex flex-col gap-2 mb-3'>
          <TextField
            label='Seu Email'
            name='email'
            type='text'
            placeholder='Ex.: mariosilva@gmail.com'
            value={values.email}
            errorMessage={errors.email}
            onChange={e => setFieldValue('email', e.target.value.trim())}
            isDisabled={loading}
          />
          <div className='flex items-center justify-end'>
            <Button isLoading={loading} type='submit' isDisabled={loading} text='Redefinir Senha' />
          </div>
        </form>
      </Modal.Body>
    </Modal.Root>
  )
}
