import { Button } from '@/components/Form'
import { CustomDateInput } from './CustomDateInput'
import { CustomSelect } from './CustomSelect'
import { getAllTypes } from '../utils/constants'
import { useTranslation } from 'react-i18next'
// import { IoCalendarOutline } from 'react-icons/io5'

export const Filters = ({ loading, formFilters }) => {
  const { values, handleChange, handleSubmit } = formFilters
  const { t } = useTranslation()
  const selectOptions = getAllTypes().map(item => {
    return { value: item.value, label: t(item.label) }
  })

  return (
    <form onSubmit={handleSubmit} className='w-full grid md:grid-cols-2 lg:grid-cols-4 gap-5 md:gap-4 sm:mt-3 md:mt-0'>
      <CustomDateInput
        label='Data Inicial'
        value={values.startDate}
        name='startDate'
        onChange={handleChange}
        placeholder='Data Inicial'
      />

      <CustomDateInput
        label='Data Final'
        value={values.endDate}
        name='endDate'
        onChange={handleChange}
        disabled={!values.startDate}
        min={values.startDate && values.startDate}
        placeholder='Data Final'
      />

      <CustomSelect value={values.type} onChange={handleChange} optionPrimary='Todos os tipos' options={selectOptions} />

      <Button
        text='Consultar'
        isLoading={loading}
        isDisabled={loading}
        type='submit'
        className='w-full self-end py-2.5 bg-primary text-zinc-800'
      />
    </form>
  )
}
