import React from 'react'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react'
import { useAuth } from '@/contexts'
import { GridPaymentItem } from './GridPaymentItem'

export const ResumeInfos = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const dataAccount = {
    accountName: user && user.company ? user.company.pix.accountName : '',
    bankName: user && user.company ? user.company.pix.bankName : '',
    agency: user && user.company ? user.company.pix.agency : '',
    accountNumber: user && user.company ? user.company.pix.accountNumber : '',
  }
  return (
    <>
      <div className='hidden md:grid grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 items-start gap-4 w-full lg:mt-3'>
        <GridPaymentItem title={t('bets.accountName')} value={dataAccount.accountName} />

        <GridPaymentItem title={t('bets.agency')} value={dataAccount.agency} />

        <GridPaymentItem title={t('bets.bankName')} value={dataAccount.bankName} />

        <GridPaymentItem title={t('bets.accountCode')} value={dataAccount.accountNumber} />
      </div>

      <Accordion allowToggle className='w-full mt-3 md:hidden'>
        <AccordionItem borderWidth={0} _last={{ borderBottomWidth: 0 }}>
          <AccordionButton
            fontSize={14}
            paddingX={4}
            paddingY={3}
            _hover={{ bg: '#D0D5DD' }}
            className='justify-between border hover:!bg-transparent rounded-2'
          >
            {t('bets.accountDetail')}
            <AccordionIcon fontSize={24} />
          </AccordionButton>

          <AccordionPanel p={0} pt={4} display='flex' flexDir='column' gap={4}>
            <GridPaymentItem title={t('bets.accountName')} value={dataAccount.accountName} />

            <GridPaymentItem title={t('bets.agency')} value={dataAccount.agency} />

            <GridPaymentItem title={t('bets.bankName')} value={dataAccount.bankName} />

            <GridPaymentItem title={t('bets.accountCode')} value={dataAccount.accountNumber} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  )
}
