import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

export const useFormProfile = ({ onSubmit }) => {
  const { t } = useTranslation()
  const Schema = Yup.object({
    name: Yup.string().required(t('global.validation.required')).nullable(t('global.validation.required')),
    username: Yup.string().required(t('global.validation.required')).nullable(t('global.validation.required')),
    email: Yup.string()
      .required(t('global.validation.required'))
      .nullable(t('global.validation.required'))
      .email(t('global.validation.invalidEmail')),
    phone: Yup.string()
      .required(t('global.validation.required'))
      .nullable(t('global.validation.required'))
      .test('lengthMinPhone', t('global.validation.invalidPhone'), value => {
        const clearValue = String(value).replace(/[^0-9]/g, '')
        return clearValue.length === 11 || clearValue.length === 0
      }),
    address: Yup.string().required(t('global.validation.required')).nullable(t('global.validation.required')),
    currencyCode: Yup.string().required(t('global.validation.required')).nullable(t('global.validation.required')),
    cpf: Yup.string()
      .required(t('global.validation.required'))
      .nullable(t('global.validation.required'))
      .test('lengthMinCpf', t('global.validation.cpfLength'), value => {
        const clearValue = String(value).replace(/[^0-9]/g, '')
        return clearValue.length === 11 || clearValue.length === 0
      }),
  })

  return useFormik({
    initialValues: {
      name: '',
      username: '',
      email: '',
      phone: '',
      address: '',
      currencyCode: '',
      cpf: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
