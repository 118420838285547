import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'

export const NavItem = ({ path, text = '', className, icon, activeClassName, onClick }) => {
  const { pathname } = useLocation()

  const getActiveClasseNames = () => {
    if (pathname === path && !activeClassName) {
      return 'text-gray-100 border-gray-700 underline'
    }

    if (pathname === path && activeClassName) {
      return activeClassName
    }
  }

  return (
    <Link
      to={path}
      className={classNames(
        'p-2 text-gray-300 hover:text-gray-300/50 transition shrink-0 underline-offset-2',
        className,
        getActiveClasseNames()
      )}
      onClick={onClick}
    >
      {icon ? icon : null}

      {text}
    </Link>
  )
}
