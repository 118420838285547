import { TwClasses } from '@/utils'
import person from '@/assets/images/person.svg'

export const Avatar = ({ classname, img }) => {
  return (
    <div
      className={TwClasses(
        'rounded-full overflow-hidden border-[0.375rem] w-[6.25rem] h-[6.25rem] sm:h-32 sm:w-32 border-primary',
        classname
      )}
    >
      <img src={img ?? person} className='object-cover h-full w-full' />
    </div>
  )
}
