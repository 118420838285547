import { useBets } from '@/contexts/BetsContext'
import { FollowYourBets, LicenseHeader, PaymentMethodBoard, ValuesBoard } from './components'
import { BalanceCards } from '@/components/Common/BalanceCards'
import { LayerBlur } from '@/components/Common/LayerBlur'

export const License = () => {
  const { betsList, isDisable } = useBets()

  return (
    <main className='px-4 pb-5 relative z-[2]'>
      <LayerBlur className='bg-primary-50 fixed right-0 translate-x-[50%] translate-y-[85%]' />

      <LicenseHeader />
      <BalanceCards />
      <ValuesBoard />
      <PaymentMethodBoard isDisable={isDisable} />
      <FollowYourBets data={betsList} />
    </main>
  )
}
