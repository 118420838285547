import { ProfitList } from './components/ProfitList'
import { Ballance } from './container/Ballance'
import { Betting } from './container/Betting'
import { BonusProfit } from './container/BonusProfit'
import { CareerPath } from './container/CareerPath'
import { Heading } from './container/Heading'

export const Dashboard = () => {
  return (
    <div className='w-full flex flex-col gap-4 p-3 text-slate-50'>
      <Heading />

      <Ballance />

      <Betting />

      <BonusProfit />

      <ProfitList />

      <CareerPath />
    </div>
  )
}
