import { Button } from '@/components/Form'
import { TextField } from '@/components/Form/Fields'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePurchaseWithBalance } from '../hooks'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { useBalance } from '@/contexts'
import { useBets } from '@/contexts/BetsContext'
import { useFormBalanceBet } from '../hooks/useFormBalance'

export const GetawayBallanceForm = () => {
  const { t } = useTranslation()
  const { paymentValue } = useBets()
  const { data } = useBalance()
  const { purchaseWithBalance, purchaseWithBalanceData, purchaseWithBalanceLoading, purchaseWithBalanceError } =
    usePurchaseWithBalance()

  const { values, handleSubmit, handleChange } = useFormBalanceBet({
    onSubmit: value => {
      handleRequestBallance(value)
    },
    ballance: true,
  })

  const handleRequestBallance = values => {
    if (paymentValue > data.balance) {
      toast.error(t('bets.insuficienteBalance'), { position: 'top-right', autoClose: 2000 })
      return
    }
    purchaseWithBalance({ giftCustomerUsername: values.username, amount: paymentValue })
  }

  useEffect(() => {
    if (!purchaseWithBalanceData) return
    Swal.fire({
      title: t('global.successTitle'),
      text: t('bets.successBallanceDeposit'),
      icon: 'success',
      confirmButtonText: t('global.ok'),
    })
  }, [purchaseWithBalanceData])

  useEffect(() => {
    if (!purchaseWithBalanceError) return
    if (purchaseWithBalanceError.error === 'giftCustomerNotFound') {
      toast.error(t('bets.giftCustomerNotFound'), {
        position: 'top-right',
        autoClose: 2000,
      })
      return
    }

    if (purchaseWithBalanceError.error === 'minimumLicensePurchase') {
      const minAmount = purchaseWithBalanceError.minimum.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'BRL',
      })

      toast.error(`${t('bets.minimumLicensePurchase')} ${minAmount}`, {
        position: 'top-right',
        autoClose: 2000,
      })
      return
    }
    toast.error(t('bets.errorBallancePurchase'), {
      position: 'top-right',
      autoClose: 2000,
    })
  }, [purchaseWithBalanceError])

  return (
    <form className='flex flex-col md:flex-row md:items-center gap-3 mb-3 lg:mb-3 lg:px-3' onSubmit={handleSubmit}>
      <TextField
        type='text'
        label={t('bets.giftAFriendLabel')}
        name='username'
        value={values.username}
        placeholder={t('bets.giftAFriendPlaceholder')}
        className='w-full rounded'
        onChange={handleChange}
      />
      <Button
        text={t('bets.confirm')}
        className='py-3 w-full md:mt-[17px] lg:py-[9px]'
        type='submit'
        isLoading={purchaseWithBalanceLoading}
        isDisabled={purchaseWithBalanceLoading}
      />
    </form>
  )
}
