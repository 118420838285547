import { Link } from 'react-router-dom'
import { Logo } from '../Common'
import { NavItem } from './NavItem'
import { FiDownload } from 'react-icons/fi'
import { WhatsAppButton } from './components/Whatsapp'
import SwitchLanguage from '../SwitchLanguage'
import { MenuMobile } from './components/MenuMobile'
import { useAuth } from '@/contexts'

export const Header = ({ hiddeMenu }) => {
  const { user } = useAuth()
  return (
    <nav className='w-full flex items-center justify-center flex-wrap mb-4 p-3 bg-blue-400 xl:bg-blue-300 border-b border-b-gray-500'>
      <div className='container xl:flex justify-between hidden'>
        <Link className='flex items-center shrink-0 text-white mr-6' to='/'>
          <Logo width={120} />
        </Link>

        {!hiddeMenu && (
          <div className='flex gap-4 items-center justify-center font-medium text-base text-gray-200'>
            <NavItem path={'/dashboard'} text='Dashboard' />
            <NavItem path={'/bets'} text='Contratar' />
            <NavItem path={'/withdraw'} text='Saque' />
            <NavItem path={'/extract'} text='Extrato' />
            <NavItem path={'/network'} text='Afiliados' />
            <NavItem path={'/career-path'} text='Plano Crescimento' />

            <a href={user?.company.apnUrl} target='_blank' rel='noopener noreferrer' className='flex gap-2 items-center'>
              <p>Download APN</p>
              <FiDownload className='text-lg -mt-1' />
            </a>

            <WhatsAppButton />

            <SwitchLanguage />
          </div>
        )}
      </div>

      <MenuMobile />
    </nav>
  )
}
