import * as Yup from 'yup'
import { useFormik } from 'formik'
import { CNPJ, CPF, EMAIL, PHONE, RANDOM_KEY } from '../constants'
import { useTranslation } from 'react-i18next'

export const useFormPix = ({ onSubmit }) => {
  const { t } = useTranslation()
  const Schema = Yup.object({
    amount: Yup.number()
      .required(t('global.validation.required'))
      .test('minValue', t('global.validation.invalidValue'), value => {
        return value > 0
      }),
    name: Yup.string().required(t('global.validation.required')),
    email: Yup.string().required(t('global.validation.required')).email(t('global.validation.invalidEmail')),
    cpf: Yup.string()
      .required(t('global.validation.required'))
      .test('lengthMinCpf', t('global.validation.cpfLength'), value => {
        const clearValue = String(value).replace(/[^0-9]/g, '')
        return clearValue.length === 11 || clearValue.length === 0
      }),
    pixKey: Yup.string().required(t('global.validation.required')),
    key: Yup.string()
      .when('pixKey', {
        is: value => value == EMAIL,
        then: Yup.string().email(t('global.validation.invalidEmail')).required(t('global.validation.required')),
        otherwise: Yup.string().required(t('global.validation.required')),
      })
      .when('pixKey', {
        is: value => value == CPF,
        then: Yup.string()
          .test('lengthMinCpf', t('global.validation.cpfLength'), value => {
            const clearValue = String(value).replace(/[^0-9]/g, '')
            return clearValue.length === 11 || clearValue.length === 0
          })
          .required(t('global.validation.required')),
        otherwise: Yup.string().required(t('global.validation.required')),
      })
      .when('pixKey', {
        is: value => value == CNPJ,
        then: Yup.string()
          .test('lengthMinCnpj', t('global.validation.cnpjLength'), value => {
            const clearValue = String(value).replace(/[^0-9]/g, '')
            return clearValue.length === 14 || clearValue.length === 0
          })
          .required(t('global.validation.required')),
        otherwise: Yup.string().required(t('global.validation.required')),
      })
      .when('pixKey', {
        is: value => value == PHONE,
        then: Yup.string()
          .test('lengthMinPhone', t('global.validation.invalidPhone'), value => {
            const clearValue = String(value).replace(/[^0-9]/g, '')
            return clearValue.length === 11 || clearValue.length === 0
          })
          .required(t('global.validation.required')),
        otherwise: Yup.string().required(t('global.validation.required')),
      })
      .when('pixKey', {
        is: value => value == RANDOM_KEY,
        then: Yup.string().required(t('global.validation.required')),
        otherwise: Yup.string().required(t('global.validation.required')),
      }),
  })

  return useFormik({
    initialValues: {
      amount: '',
      name: '',
      email: '',
      cpf: '',
      pixKey: '',
      key: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
