import React, { createContext, useContext, useEffect, useState } from 'react'
import httpClient from '@/utils/httpClient'
import { useApi } from '@/hooks/useApi'
import { useBalance } from '.'

const AuthContext = createContext({
  token: null,
  user: null,
  loading: false,
  loadingBalance: false,
  daysWithdraw: [],
  isWithdrawDay: () => {},
  refresh: () => {},
})

export const AuthProvider = ({ children }) => {
  const { refreshBalance, loading: loadingBalance } = useBalance()
  const [token, setToken] = useState(window.localStorage.getItem('auth:user'))
  const [user, setUser] = useState(null)
  const [daysWithdraw, setDaysWithdraw] = useState([])

  const { data, makeRequest, loading } = useApi(() => httpClient.get('/auth/me'))

  /*const isWithdrawDay = now => {
    const dayOfWeekAllowed = user.company.withdraw.daysOfWeek.find(({ day }) => day === now.getDay())
    const days = user?.company?.withdraw.fixedDays
    const isWithdrawFixDay = days.find(({ day }) => day === now.getDate())

    if (user.company.withdraw.fixedDays.length > 0 && user.company.withdraw.daysOfWeek.length > 0) {
      if (dayOfWeekAllowed || isWithdrawFixDay) return true
      return false
    } else if (user.company.withdraw.daysOfWeek.length > 0) {
      if (dayOfWeekAllowed) return true
      return false
    } else if (user.company.withdraw.fixedDays.length > 0) {
      if (isWithdrawFixDay) return true
      return false
    }
  }*/

  const isWithdrawAllowed = () => {
    const now = new Date()
    if (user.blockWithdraw === true) return false

    //if (isWithdrawDay(now)) {
    const [startHour, startMinutes] = user.company.withdraw.startTime.split(':')
    const startTime = new Date()
    startTime.setHours(startHour)
    startTime.setMinutes(startMinutes)
    startTime.setSeconds(0)

    const [endHour, endMinutes] = user.company.withdraw.endTime.split(':')
    const endTime = new Date()
    endTime.setHours(endHour)
    endTime.setMinutes(endMinutes)
    endTime.setSeconds(0)

    return !(now < startTime || now > endTime)
    //}

    //return false
  }

  const isPurchaseWithBalanceDays = () => {
    const now = new Date()
    const days = user?.company?.purchaseWithBalanceDays
    if (!days?.includes(now.getDate())) return false

    const [startHour, startMinutes] = user.company.purchaseWithBalanceStartTime.split(':')
    const startTime = new Date()
    startTime.setHours(startHour)
    startTime.setMinutes(startMinutes)
    startTime.setSeconds(0)

    const [endHour, endMinutes] = user.company.purchaseWithBalanceEndTime.split(':')
    const endTime = new Date()
    endTime.setHours(endHour)
    endTime.setMinutes(endMinutes)
    endTime.setSeconds(0)

    return !(now < startTime || now > endTime)
  }

  useEffect(() => {
    if (!token) {
      setUser(null)
      window.localStorage.removeItem('auth:user')
      return
    }
    window.localStorage.setItem('auth:user', token)
    makeRequest()
    refreshBalance()
  }, [token])

  useEffect(() => {
    if (!data) return
    setUser(data)
    setDaysWithdraw(data.company.withdrawalDays)
  }, [data])

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        loading,
        loadingBalance,
        daysWithdraw,
        setToken,
        refresh: makeRequest,
        isWithdrawAllowed,
        isPurchaseWithBalanceDays,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => ({ ...useContext(AuthContext) })
