import { useTranslation } from 'react-i18next'
import { IoArrowForwardOutline } from 'react-icons/io5'
import { BetItem } from '../components/BetItem'
import { useEffect, useState } from 'react'
import { useFetchContractOffers } from '../hooks/useFetchContractOffers'
import { Spinner } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { LayerBlur } from '@/components/Common/LayerBlur'
import { twJoin } from 'tailwind-merge'

export const Betting = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [betValue, setBetValue] = useState(null)
  const { dataContractOffers, fetchContractOffers, loadingContractOffers } = useFetchContractOffers()

  useEffect(() => {
    fetchContractOffers()
  }, [])

  const handleMoreValues = () => {
    betValue ? navigate(`/bets/${betValue}`) : navigate(`/bets`)
  }

  const ActionButton = ({ mobile }) => {
    return (
      <button
        onClick={handleMoreValues}
        className={twJoin('w-fit justify-start items-center gap-2', mobile ? 'flex md:hidden' : 'hidden md:flex ')}
      >
        <p className='relative before:absolute before:w-1/2 before:h-full before:left-0 before:bottom-0 before:border-b before:border-slate-100'>
          {betValue ? 'Continuar' : t('dashboard.moreValues')}
        </p>
        <IoArrowForwardOutline />
      </button>
    )
  }

  return (
    <div className='flex flex-col items-start gap-3'>
      <div className='w-full flex justify-between items-center'>
        <h2 className='text-[18px]'>{t('dashboard.howMuchBet')}</h2>

        <ActionButton />
      </div>

      {dataContractOffers && (
        <div className='w-full grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2 relative z-[2]'>
          {dataContractOffers.map((item, ind) => (
            <BetItem key={ind} hide={ind > 3} value={item} active={betValue === item} setBetValue={setBetValue} />
          ))}
          <LayerBlur className='bg-primary-50/50 right-0 -bottom-2' />
        </div>
      )}

      {!dataContractOffers && !loadingContractOffers && <p>Nenhuma Oferta Disponível</p>}

      {!dataContractOffers && loadingContractOffers && (
        <div className='w-full flex justify-center items-center'>
          <Spinner />
        </div>
      )}

      <ActionButton mobile />

      <span className='w-full h-[1px] border-b border-slate-400/50 mt-4' />
    </div>
  )
}
