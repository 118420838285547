import { Button } from '@/components/Form'
import { CpfCnpjField, PhoneField } from '@/components/Form/Fields'
import { useBets } from '@/contexts/BetsContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineQrcode } from 'react-icons/ai'
import { useFormRequestBet } from '../hooks'

export const PixAndColeForm = () => {
  const { t } = useTranslation()
  const { handleRequestDeposit, isLoading } = useBets()
  const { values, handleSubmit, handleChange, errors } = useFormRequestBet({
    onSubmit: () => {
      handleRequestDeposit(values)
    },
  })

  return (
    <form className='flex flex-col lg:flex-row lg:items-start mb-3 lg:mb-3 gap-3 lg:px-3' onSubmit={handleSubmit}>
      <PhoneField
        className='w-full rounded'
        placeholder='Ex.: 99999-9999'
        label='Telefone'
        name='phone'
        value={values.phone}
        onChange={handleChange}
        errorMessage={errors.phone}
      />
      <CpfCnpjField
        type='text'
        label={t('global.labelCpf')}
        name='cpf'
        value={values.cpf}
        placeholder={t('global.placeholderCpf')}
        className='w-full rounded'
        onChange={handleChange}
        errorMessage={errors.cpf}
      />
      <Button
        text={isLoading ? isLoading : t('bets.qrCodeGenerator')}
        className='py-3 w-full lg:mt-[30px] lg:py-3.5 max-h-[52px]'
        iconLeft={isLoading ? '' : <AiOutlineQrcode size={24} />}
        type='submit'
        isLoading={isLoading}
      />
    </form>
  )
}
