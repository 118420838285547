import { Boarding } from '@/components/Common/Boarding'
import { useTranslation } from 'react-i18next'

export function LicenseHeader() {
  const { t } = useTranslation()
  return (
    <div className='flex flex-col gap-4 mb-4'>
      <Boarding text={t('license.letsBet')} icon='/icons/handMoney.svg' />
    </div>
  )
}
