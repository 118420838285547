export const ACTIVE_AFFILIATES = 1
export const TOTAL_AFFILIATES = 2
export const DIRECT_INDICATORS = 3
export const ACTIVE_DIRECT_INDICATORS = 4
export const ALL_AFFILIATES = 5

export const getIndicatorsTypeNames = type => {
  switch (type) {
    case ACTIVE_AFFILIATES:
      return {
        text: 'Afiliados Ativos',
        icon: '/icons/personCheck.svg',
      }
    case TOTAL_AFFILIATES:
      return {
        text: 'Total de Afiliados',
        icon: '/icons/group.svg',
      }
    case DIRECT_INDICATORS:
      return {
        text: 'Indicados Diretos',
        icon: '/icons/userNetwork.svg',
      }
    case ACTIVE_DIRECT_INDICATORS:
      return {
        text: 'Indicados Diretos Ativos',
        icon: '/icons/activeNetwork.svg',
      }

    default:
      return {
        text: 'Ver todos os Afiliados',
        icon: '/icons/userAfiliates.svg',
      }
  }
}
