import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchProfitTotal = () => {
  const { data, error, makeRequest, loading } = useApi(params => {
    const { route, method } = getEndpoint('profitTotal')
    return httpClient[method](route, { ...params })
  })

  return { dataProfitTotal: data, isLoadingProfitTotal: loading, fetchProfitTotal: makeRequest, error }
}
