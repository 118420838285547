import * as Yup from 'yup'
import { useFormik } from 'formik'

export const useFormPassword = ({ onSubmit }) => {
  const Schema = Yup.object({
    password: Yup.string().required('Nova senha é obrigatória').min(4, 'Mínimo 4 caracteres'),
    confirmNewPassword: Yup.string()
      .required('Confirmação da nova senha é obrigatória')
      .min(4, 'Mínimo 4 caracteres')
      .when('password', {
        is: val => Boolean(val?.length),
        then: Yup.string().oneOf([Yup.ref('password')], 'As senhas precisam ser iguais'),
      }),
    recaptcha: Yup.string().required('reCAPTCHA é obrigatório'),
  })
  return useFormik({
    initialValues: {
      password: '',
      confirmNewPassword: '',
      recaptcha: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
