import { useEffect, useState } from 'react'
import { useFetchExtract } from './useFetchExtract'
import { useFormFilters } from './useFormFilters'
import { FilterBuilder } from '@/utils'

export const useExtractPage = () => {
  const [extractList, setExtractList] = useState([])
  const fetchExtract = useFetchExtract()
  const formFilters = useFormFilters({
    onSubmit: values => {
      const { endDate, startDate, type } = values
      const filterOptions = FilterBuilder().equal('startDate', startDate).equal('endDate', endDate).equal('type', type).build()
      fetchExtract.onFetchExtract({ ...filterOptions, page: 1 })
      setExtractList([])
    },
  })

  useEffect(() => {
    if (formFilters.values.startDate.length === 0) {
      formFilters.setFieldValue('endDate', '')
    }
  }, [formFilters.values.startDate])

  useEffect(() => {
    if (!fetchExtract.data || !fetchExtract.data.length === 0) return
    setExtractList(prev => [...prev, ...fetchExtract.data])
  }, [fetchExtract.data])

  return { extractList, formFilters, fetchExtract }
}
