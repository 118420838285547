import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

export const useFormPaypal = ({ onSubmit }) => {
  const { t } = useTranslation()
  const Schema = Yup.object({
    amount: Yup.number()
      .required(t('global.validation.required'))
      .test('minValue', t('global.validation.invalidValue'), value => {
        return value > 0
      }),
    paypalEmailOrPhone: Yup.string().required(t('global.validation.required')),
  })

  return useFormik({
    initialValues: {
      amount: '',
      cryptoWallet: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
