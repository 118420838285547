import { useFormik } from 'formik'
import * as Yup from 'yup'

export const useFormFilters = ({ onSubmit }) => {
  const Schema = Yup.object({
    startDate: Yup.string(),
    endDate: Yup.string(),
    type: Yup.string(),
  })

  return useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
      type: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
