import { Badge } from '@/components/Common'
import classNames from 'classnames'
import { BsPersonCircle, BsTelephone } from 'react-icons/bs'
import { IoIosArrowUp } from 'react-icons/io'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import MoneyAsText from '@/components/Common/MoneyAsText'

export const AffiliateUser = ({
  phone,
  name,
  level,
  isExpanded,
  setExpanded,
  affiliateChildren,
  isActive,
  username,
  affiliate,
}) => {
  const isHead = level === 1
  const networkLength = affiliateChildren.length
  const elementRef = useRef()
  const { t } = useTranslation()

  const handleExpand = () => {
    if (networkLength > 0) {
      setExpanded(!isExpanded)
    }
  }

  const formatPhoneNumber = phoneNumber => {
    if (!phoneNumber) return '-'
    const cleaned = ('' + phoneNumber).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`
    }
    return phoneNumber
  }

  useEffect(() => {
    const useLevel = level < 9 ? level : 9
    const levelSize = 100 - 8 * (useLevel - 1)

    elementRef.current?.style.setProperty('--levelSize', `${levelSize}%`)
    elementRef.current?.style.setProperty('--levelOpacity', useLevel * 0.05)
  }, [])

  return (
    <div className='w-full flex justify-end items-center relative'>
      {!isHead && (
        <span className='hidden lg:block absolute top-[-16px] right-[calc(var(--levelSize)+16px)] h-[calc(50%+16px)] w-5 border-b-2 border-l-2 border-gray ' />
      )}

      <div
        className={classNames(
          `w-full flex flex-col md:flex-row justify-between items-start md:items-center flex-wrap gap-2.5 p-3 md:px-5 md:p-4 rounded-2 text-white border border-gray`,
          { 'lg:border border-gray bg-gray text-text': isHead },
          { 'bg-gray-400 bg-opacity-[var(--levelOpacity)]': !isHead },
          { 'cursor-pointer': networkLength > 0 },
          { 'lg:w-[calc(var(--levelSize))]': !isHead }
        )}
        onClick={handleExpand}
      >
        <div className='flex flex-col gap-2'>
          <div className='flex items-center flex-wrap md:flex-nowrap gap-3 text-gray-500'>
            <div className='flex items-center gap-3 whitespace-nowrap'>
              <BsPersonCircle className='text-primary text-lg' />
              <p className='-ml-2 leading-3 text-text'>{username}</p>
            </div>

            {phone && (
              <div className='flex items-center gap-3 whitespace-nowrap'>
                <BsTelephone className='text-primary text-lg' />
                <p className='-ml-2 leading-3 text-text'>{formatPhoneNumber(phone)}</p>
              </div>
            )}
          </div>

          <div className='flex items-center flex-wrap gap-[12px]'>
            <p className='capitalize'>{name}</p>
            {networkLength > 0 && (
              <>
                <span className='hidden md:block w-2 h-2 bg-secondary rounded-full' />
                <p className={'underline underline-offset-2 flex items-center gap-1 leading-3'}>
                  {'Mostrar ramificação de Afiliados'}{' '}
                  <IoIosArrowUp size={20} className={classNames('transition', { 'rotate-180': !isExpanded })} />
                </p>
              </>
            )}
          </div>
        </div>

        <div className='flex gap-2'>
          {affiliate.contractsAmountSum > 0 && (
            <Badge
              text={
                <>
                  {t('network.volume')}: <MoneyAsText value={affiliate.contractsAmountSum} />
                </>
              }
              bg='bg-secondary-100'
              textColor='text-white'
              className='font-normal px-3 py-2 '
            />
          )}
          <Badge
            text={`${t('network.level')} ${level}`}
            bg='bg-gray-100'
            textColor='text-black'
            className='font-normal px-3 py-2'
          />
          <Badge
            text={
              <>
                <img className='w-[20px]' src='/icons/personCheck.svg' />
                <p>{isActive ? 'Ativo' : 'Inativo'}</p>
              </>
            }
            bg={isActive ? 'bg-primary' : 'bg-[#FF6F6F]'}
            textColor='text-black'
            className='flex items-center gap-1 font-normal px-3 py-2'
          />
        </div>
      </div>
    </div>
  )
}
