import { Button, CheckBox } from '@/components/Form'
import { FieldMessage, PasswordField, PhoneField, TextField } from '@/components/Form/Fields'
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link } from 'react-router-dom'

export const SignUpForm = ({ loading, formSignUp, captchaRef }) => {
  const { handleSubmit, handleChange, setFieldValue, values, errors } = formSignUp

  return (
    <main className='flex flex-col w-full lg:max-w-[625px] justify-center'>
      <h1 className='text-text text-xl font-semibold lg:text-4xl my-2.5 lg:mb-4'>Cadastre-se gratuitamente</h1>
      <form onSubmit={handleSubmit} className='w-full text-text'>
        <TextField
          label='Seu Nome Completo'
          name='name'
          placeholder='Ex.: Maria da Silva'
          value={values.name}
          errorMessage={errors.name}
          onChange={handleChange}
          isDisabled={loading}
        />
        <TextField
          label='Seu Email'
          name='email'
          placeholder='Ex.: mariosilva@gmail.com'
          value={values.email}
          errorMessage={errors.email}
          onChange={e => setFieldValue('email', e.target.value.trim())}
          isDisabled={loading}
        />
        <PhoneField
          label='Seu Telefone'
          name='phone'
          placeholder='Ex.: (11) 99999-7777'
          value={values.phone}
          errorMessage={errors.phone}
          onChange={handleChange}
          isDisabled={loading}
        />
        <TextField
          label='Login'
          name='username'
          placeholder='Ex.: mario_silva'
          value={values.username}
          errorMessage={errors.username}
          onChange={handleChange}
          isDisabled={loading}
        />
        <PasswordField
          label='Crie uma Senha'
          name='password'
          placeholder='*******'
          value={values.password}
          errorMessage={errors.password}
          onChange={handleChange}
          isDisabled={loading}
        />
        <PasswordField
          label='Confirme sua Senha'
          name='confirmPassword'
          placeholder='*******'
          value={values.confirmPassword}
          errorMessage={errors.confirmPassword}
          onChange={handleChange}
          isDisabled={loading}
        />
        <CheckBox
          label={
            <p>
              Eu concordo com os{' '}
              <Link to='/ThermOfUsei7trader.pdf' className='underline' target='_blank'>
                {' '}
                termos e condições de uso
              </Link>
            </p>
          }
          name='check'
          value={values.check}
          onChange={e => {
            setFieldValue('check', e.target.checked)
          }}
          isDisabled={loading}
          checked={values.check}
        />
        <div className='flex flex-col gap-3 w-full my-2 mb-4'>
          <div className='flex flex-col mt-2 mb-1 w-full'>
            <ReCAPTCHA
              ref={captchaRef}
              name='recaptcha'
              render='explicit'
              onChange={value => setFieldValue('recaptcha', value)}
              sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
            />
            {errors.recaptcha && <FieldMessage message={errors.recaptcha} />}
          </div>
          <Button text='Cadastre-se' type='submit' isDisabled={loading || !values.check} className='py-3 lg:py-2' />
          <Link to='/sign-in' className='w-full'>
            <Button className='w-full bg-transparent border border-gray-200 py-3 lg:py-2 text-white'>
              Já tem uma Conta? Faça Login
            </Button>
          </Link>
        </div>
      </form>
    </main>
  )
}
