import classNames from 'classnames'
import { menuItems } from '../utils/menuItems'
import { IoClose } from 'react-icons/io5'
import { Logo } from '../../Common'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ProfileDropdown } from './ProfileDropdown'
import { NavItem } from './MobileNavItem'

export const SidebarMobile = ({ isMenuOpen, setOpenMenu }) => {
  const { t } = useTranslation()
  return (
    <>
      <div
        className={classNames(
          'w-80 transition-all ease-in fixed z-50 top-0 left-0 h-full overflow-hidden bg-blue-300 overflow-y-auto py-3 rounded-br-xl rounded-tr-xl shadow-2xl flex flex-col justify-start gap-2',
          {
            'translate-x-0': isMenuOpen,
          },
          { '-translate-x-full': !isMenuOpen }
        )}
      >
        <div className='w-full flex justify-between items-center pb-2 px-3'>
          <Link onClick={() => setOpenMenu(false)} className='flex items-center shrink-0 text-white' to='/dashboard'>
            <Logo src='/images/logo_v1_sf.png' className='w-full max-h-6 object-cover' />
          </Link>

          <button onClick={() => setOpenMenu(false)} className='text-slate-200'>
            <IoClose size={32} />
          </button>
        </div>

        <div className='py-4 px-3 border-gray-500 border-y'>
          <ProfileDropdown handleClick={() => setOpenMenu(false)} />
        </div>

        <nav className='flex flex-col gap-2 py-3'>
          {menuItems.map((menuitem, ind) => {
            const { Icon, route, name, img } = menuitem
            return (
              <NavItem
                key={ind}
                path={route}
                text={t(name)}
                onClick={() => setOpenMenu(false)}
                icon={Icon ? <Icon size={24} /> : <img className='w-4' src={img} />}
                className='flex justify-start items-center gap-2 relative w-full hover:bg-blue-200/25'
                activeClassName='text-slate-200 bg-gradient-to-r from-secondary to-75% to-transparent rounded-lg'
              />
            )
          })}
        </nav>
      </div>

      <div
        className={classNames(
          {
            ' w-3/4 border-negative fixed h-full right-0 top-0 xl:hidden z-30 backdrop-blur-sm transition-all': isMenuOpen,
          },
          { ' ': !isMenuOpen }
        )}
        onClick={() => setOpenMenu(false)}
      />
    </>
  )
}
