import GreenCheckIcon from '/images/green_check.svg'
import RejectedIcon from '/images/rejected_icon.svg'
import AnalyzeIcon from '/images/analyzes_icon.svg'
import MoneyAsText from '@/components/Common/MoneyAsText'

export function BetStatusCard({ value, date, type, refuseReason }) {
  let icon = ''

  if (type === 4) {
    icon = RejectedIcon
  }

  if (type === 5 || type === 10) {
    icon = GreenCheckIcon
  }

  if (type === 3) {
    icon = AnalyzeIcon
  }

  let [dataPart, hourPart] = date.split(' ')
  let formattedDate = dataPart.split('-').reverse().join('/')
  let formattedHour = hourPart.split(':').slice(0, 2).join(':')

  const label = {
    5: 'Ativo',
    4: 'Recusado',
    3: 'Em análise',
    10: 'Ativo (Saldo)',
  }

  const color = {
    5: 'text-[#D4F101]',
    4: 'text-[#FF4A4A]',
    3: 'text-[#EDD604]',
    10: 'text-[#D4F101]',
  }

  const borderColor = {
    5: 'border-t-[#D4F101]',
    4: 'border-t-[#FF4A4A]',
    3: 'border-t-[#EDD604]',
    10: 'border-t-[#D4F101]',
  }

  return (
    <div
      className={`bg-[#414755] py-[25px] min-h-[152px] rounded-[10px] backdrop-blur-[22.328357696533203px] border-t-[5px] ${borderColor[type]}`}
    >
      <div className='pb-3 px-4 flex justify-between items-center border-b-2 border-[#363C4D]'>
        <p className='text-[#F5F5F5] text-lg'>
          <MoneyAsText value={value} />
        </p>
        <div className='flex gap-1 items-center'>
          <img className='h-3 w-3' src={icon} />
          <span className={`text-[18px] ${color[type]}`}>{label[type]}</span>
        </div>
      </div>
      <div className='flex flex-col pt-[17px] px-4'>
        <p className='text-[#F5F5F5] text-[18px]'>
          {type === 4 ? `Data: ${formattedDate} ${formattedHour}` : `Data: ${formattedDate}`}
        </p>
        <p className='text-[#F5F5F5] text-[18px]'>{type === 4 ? `Motivo: ${refuseReason}` : `Hora: ${formattedHour}`}</p>
      </div>
    </div>
  )
}
