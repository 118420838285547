import { getGraduationName, getRulesDescription } from '@/constants/careerPath'
import { Progress } from '@chakra-ui/react'
import { GoTasklist } from 'react-icons/go'
import { twJoin, twMerge } from 'tailwind-merge'

export const GraduationItem = ({ graduation, currentGraduation }) => {
  const isFutureGraduation = graduation.position > currentGraduation.position + 1

  return (
    <div className='flex flex-col border transition duration-300 border-blue-200 hover:border-primary/50 group rounded-2'>
      <div
        className={twJoin(
          'flex items-center gap-3 p-3 md:p-4 px-4 md:px-5',
          graduation.isGraduated && 'flex-col justify-center text-center h-full'
        )}
      >
        <img
          className={twMerge(twJoin('w-[52px]', graduation.isGraduated && 'w-7'))}
          src={graduation.isGraduated ? '/icons/goalIconFull.svg' : '/icons/goalIconNull.svg'}
        />

        <div className='flex flex-col'>
          <p className='text-[18px]'>{getGraduationName(graduation.position)}</p>
          {isFutureGraduation ? (
            <p className='text-gray-300 text-sm'>Futura Graduação</p>
          ) : (
            <p className='text-gray-300 text-sm'>{graduation.isGraduated ? 'Graduação atingida' : 'Próxima Graduação'}</p>
          )}
        </div>
      </div>

      {graduation.position !== 1 && (
        <>
          <div className='w-full h-[1px] bg-blue-200 group-hover:bg-primary/50 transition duration-300' />

          <div className='flex flex-col gap-4 justify-start items-center p-3 px-4 md:p-4 md:px-5 my-auto'>
            <div className='w-full flex gap-2 justify-start items-center -mb-2'>
              <GoTasklist className='text-primary-50 text-xl' />
              {graduation.isGraduated ? (
                <p className='text-gray-300'>Tarefas concluídas</p>
              ) : (
                <p className='text-gray-300'>{graduation.rules.filter(item => item.done === false).length} Tarefas restantes</p>
              )}
            </div>

            {graduation.rules.map(item => (
              <div key={item.id} className='w-full flex flex-col gap-2 justify-start'>
                <p className='text-sm'>{getRulesDescription(item.value, item.type)}</p>
                <Progress
                  value={item.percent}
                  rounded={12}
                  height={2}
                  sx={{
                    background: '#9D9D9D',
                    '& > div': {
                      background: '#D4F101',
                    },
                  }}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
