import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchMyProfits = () => {
  const {
    data: dataNetwork,
    error: errorNetwork,
    makeRequest: makeRequestNetwork,
    loadingNetwork,
  } = useApi(params => {
    const { route, method } = getEndpoint('networkProfit')
    return httpClient[method](route, { ...params })
  })

  const {
    data: dataOperations,
    error: errorOperations,
    makeRequest: makeRequestOperations,
    loadingOperations,
  } = useApi(params => {
    const { route, method } = getEndpoint('operationsProfit')
    return httpClient[method](route, { ...params })
  })

  const fetchMyProfits = () => {
    makeRequestNetwork()
    makeRequestOperations()
  }

  const data = { dataNetwork, dataOperations }
  const error = { errorNetwork, errorOperations }
  const loading = { loadingNetwork, loadingOperations }

  return { data: data, isLoading: loading, fetchMyProfits, error }
}
