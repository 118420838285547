import { React } from 'react'
import { SignUpForm } from './components'
import { useSignUpPage } from './hooks'

export const SignUp = () => {
  const { indicatedCode, dataIndicatedBy, createCustomer, formSignUp, captchaRef } = useSignUpPage()

  return (
    <main className='flex flex-col'>
      <div className='text-end mt-4'>
        {indicatedCode && (
          <p className='text-text text-sm'>
            {'Indicado por'}: <span className='font-bold'>{dataIndicatedBy?.username}</span>
          </p>
        )}
      </div>
      <SignUpForm formSignUp={formSignUp} loading={createCustomer.loading} captchaRef={captchaRef} />
    </main>
  )
}
