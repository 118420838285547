import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import CopyToClipboard from 'react-copy-to-clipboard'
import { MdContentCopy } from 'react-icons/md'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Form'
import { TbFileImport } from 'react-icons/tb'
import { useAuth } from '@/contexts'
import { useSendContract } from '../hooks/useSendContract'
import Swal from 'sweetalert2'
import { useBets } from '@/contexts/BetsContext'
import { ResumeInfos } from './ResumeInfos'

export const GetawayPixForm = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { handleFileChange, handleFileSubmit, paymentReceiptAttached, paymentValue } = useBets()

  const { dataPurchase, sendContract, isLoading: isLoadingContract, errorPurchase } = useSendContract()

  const handleSubmit = async e => {
    e.preventDefault()
    const paymentType = 1
    const formData = new FormData()
    formData.append('amount', paymentValue)
    formData.append('paymentType', String(paymentType))
    formData.append('paymentProof', paymentReceiptAttached)
    formData.append('validatePrice', false)

    if (paymentValue < 100) {
      toast.warn('O valor do contrato deve ser maior que R$ 100,00'),
        {
          position: 'top-right',
          autoClose: 2000,
        }
      return
    }
    sendContract(formData)
  }

  const dataAccount = {
    qrCode: user && user.company ? user.company.pix.qrcode : '',
    key: user && user.company ? user.company.pix.key : '',
    keyType: user && user.company ? user.company.pix.keyType : '',
  }

  useEffect(() => {
    if (!dataPurchase) return
    Swal.fire({
      title: t('global.successTitle'),
      text: t('bets.successBallanceDeposit'),
      icon: 'success',
      confirmButtonText: t('global.ok'),
    }).then(() => {
      handleFileSubmit()
    })
  }, [dataPurchase])

  useEffect(() => {
    if (!errorPurchase) return

    if (errorPurchase.error === 'blockedPurchaseLicense') {
      Swal.fire({
        title: t('global.warningTitle'),
        text: t('bets.errorBlockedDeposit'),
        icon: 'warning',
        confirmButtonText: t('global.ok'),
      })

      return
    }

    toast.error(t('bets.errorPurchase'), {
      position: 'top-right',
      autoClose: 2000,
    })
  }, [errorPurchase])

  return (
    <form onSubmit={handleSubmit} className='flex flex-col items-center gap-4 w-full lg:grid lg:grid-cols-2'>
      <div className='mt-4 w-full flex flex-col gap-3 items-center lg:flex-row lg:items-start'>
        <motion.img
          initial={{ scale: 0.75, opacity: 0.5 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ type: 'spring', mass: 0.8 }}
          className='w-full max-w-[280px] object-cover shrink-0'
          src={dataAccount.qrCode}
          alt='QR Code'
        />
        <ResumeInfos />
      </div>

      <div className='flex flex-col gap-3 w-full h-full lg:border-l lg:pl-4'>
        <p className='text-white'>
          {t('bets.pixCode')} ({dataAccount.keyType}):
        </p>
        <CopyToClipboard
          text={dataAccount.key}
          onCopy={() => {
            toast.success(t('bets.toastCopy'), {
              position: 'top-center',
              autoClose: 2000,
            })
          }}
        >
          <div className='h-5 flex p-4 px-3 rounded-lg gap-2 items-center justify-center border border-gray w-full text-center cursor-pointer hover:opacity-70 text-white'>
            <div className='text-md'>
              <MdContentCopy size={24} className='text-primary' />
            </div>
            <div className='text-sm text-white truncate'>{dataAccount.key}</div>
          </div>
        </CopyToClipboard>

        <Button className='w-full p-0 hover:bg-gray-400'>
          <label htmlFor='paymentReceipt' className='w-full flex justify-center items-center gap-2 py-2.5 px-3 cursor-pointer'>
            <TbFileImport className='shrink-0 text-xl' />
            <p className='truncate'>{paymentReceiptAttached ? paymentReceiptAttached.name : t('bets.paymentVoucher')}</p>
          </label>
        </Button>

        <input
          className='w-0 h-0 hidden'
          type='file'
          accept='.png, .jpeg, .jpg, .pdf'
          name='paymentReceipt'
          id='paymentReceipt'
          onChange={handleFileChange}
        />

        <Button
          text={t('bets.submitVoucher')}
          type='submit'
          className='py-3 w-full'
          isDisabled={isLoadingContract || !paymentReceiptAttached}
          isLoading={isLoadingContract}
        />
      </div>
    </form>
  )
}
