import { CpfCnpjField, PhoneField, TextField } from '@/components/Form/Fields'
import { CNPJ, CPF, EMAIL, PHONE, RANDOM_KEY } from '../constants'
import { useWithdraw } from '../context/WithdrawContext'
import { useTranslation } from 'react-i18next'

export const verifyPixKeyType = type => {
  const { t } = useTranslation()
  const { formPix } = useWithdraw()
  const { values, handleChange, errors } = formPix
  switch (type) {
    case CPF:
      return (
        <CpfCnpjField
          name='key'
          value={values.key}
          onChange={handleChange}
          errorMessage={errors.key}
          label={t('withdraw.key')}
          className='w-full'
          inputClassName='bg-blue-300'
          noMargins
          placeholder='Ex.: 123.456.789-01'
        />
      )
    case CNPJ:
      return (
        <CpfCnpjField
          name='key'
          format='cnpj'
          value={values.key}
          onChange={handleChange}
          errorMessage={errors.key}
          label={t('withdraw.key')}
          className='w-full'
          inputClassName='bg-blue-300'
          noMargins
          placeholder='Ex.: 12.345.678/0001-90'
        />
      )
    case EMAIL:
      return (
        <TextField
          name='key'
          format='cnpj'
          value={values.key}
          onChange={handleChange}
          errorMessage={errors.key}
          label={t('withdraw.key')}
          className='w-full'
          inputClassName='bg-blue-300'
          noMargins
          placeholder='Ex.: joaosilva@email.com'
        />
      )
    case RANDOM_KEY:
      return (
        <TextField
          name='key'
          format='cnpj'
          value={values.key}
          onChange={handleChange}
          errorMessage={errors.key}
          label={t('withdraw.key')}
          className='w-full'
          inputClassName='bg-blue-300'
          noMargins
          placeholder='Ex.: db4f965d-677c-49ff-b9da-5131da1505f3'
        />
      )
    case PHONE:
      return (
        <PhoneField
          name='key'
          value={values.key}
          onChange={handleChange}
          errorMessage={errors.key}
          label={t('withdraw.key')}
          className='w-full'
          noMargins
          placeholder='Ex.: (01) 912345678'
        />
      )
    default:
      return (
        <TextField
          name='key'
          format='cnpj'
          value={values.key}
          onChange={handleChange}
          errorMessage={errors.key}
          label={t('withdraw.key')}
          className='w-full'
          inputClassName='bg-blue-300'
          noMargins
          placeholder='Ex.: db4f965d-677c-49ff-b9da-5131da1505f3'
          isDisabled
        />
      )
  }
}
