import { SimpleAccordion } from '@/components/Common/Accordion'
import { PaymentMethodCard } from './PaymentMethodCard'
import BitcoinWhiteIcon from '/images/bitcoinIcon.svg'
import PixIcon from '/images/pixIcon.svg'
import DolarWhiteIcon from '/images/dolar_black_icon.svg'
import CoinHandIcon from '/images/coin_hand_black.svg'
import { FormSection } from './FormSection'
import { useBets } from '@/contexts/BetsContext'
import { BALLANCE, BITCOIN, DOLLAR, PIX, PIXPASTEANDCOLE } from '@/constants/betsConstants'
import { LayerBlur } from '@/components/Common/LayerBlur'
import { useEffect, useState } from 'react'
import { useAuth } from '@/contexts'

export function PaymentMethodBoard({ isDisable }) {
  const { paymentMethod, setPaymentMethod } = useBets()
  const { user } = useAuth()
  const [isDisabled, setIsDisabled] = useState(false)

  const handlePaymentMethodSelect = newPaymentMethod => {
    setPaymentMethod(newPaymentMethod)
  }

  useEffect(() => {
    const dateNow = new Date()

    if (user.company.purchaseWithBalanceDays.length < 1 || !user.company.purchaseWithBalanceDays.includes(dateNow.getDate())) {
      setIsDisabled(true)
    }
  }, [user])

  return (
    <div className='mt-[40px] relative z-[2]'>
      <LayerBlur className='bg-secondary-100 top-6 -left-2' />
      <SimpleAccordion>
        <SimpleAccordion.Content title='Selecione o método de pagamento'>
          <SimpleAccordion.Item>
            <div className='flex flex-col lg:flex-row items-start lg:items-center justify-around gap-3'>
              <PaymentMethodCard
                title='PIX Cópia&Cola'
                path={PixIcon}
                isSelected={paymentMethod === PIXPASTEANDCOLE}
                handleSelect={() => handlePaymentMethodSelect(PIXPASTEANDCOLE)}
              />
              <PaymentMethodCard
                title='PIX'
                path={PixIcon}
                isSelected={paymentMethod === PIX}
                handleSelect={() => handlePaymentMethodSelect(PIX)}
                isDisable={isDisable}
              />
              <PaymentMethodCard
                title='BITCOIN'
                path={BitcoinWhiteIcon}
                isSelected={paymentMethod === BITCOIN}
                handleSelect={() => handlePaymentMethodSelect(BITCOIN)}
                isDisable={isDisable}
              />
              <PaymentMethodCard
                title='Dólar'
                path={DolarWhiteIcon}
                isSelected={paymentMethod === DOLLAR}
                handleSelect={() => handlePaymentMethodSelect(DOLLAR)}
                isDisable={isDisable}
              />
              <PaymentMethodCard
                title='Saldo'
                path={CoinHandIcon}
                isSelected={paymentMethod === BALLANCE}
                handleSelect={() => handlePaymentMethodSelect(BALLANCE)}
                isDisable={isDisable || isDisabled}
              />
            </div>
            <div className='mt-5 border-t border-dashed border-t-[#414755]'>
              <FormSection />
            </div>
          </SimpleAccordion.Item>
        </SimpleAccordion.Content>
      </SimpleAccordion>
    </div>
  )
}
