import { Header } from '@/components/Menu/Header'
import { ToastContainer } from 'react-toastify'
import React, { useEffect } from 'react'
import { Loading, WhatsappButton } from '@/components/Common'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@/contexts'

export const Layout = ({ children }) => {
  const navigate = useNavigate()
  const { user, loading } = useAuth()

  useEffect(() => {
    if (user && !user.isActive) {
      navigate('/bets')
    }
  }, [user])

  return (
    <div className='w-full min-h-screen flex flex-col items-center bg-img bg-background font-sora'>
      {loading ? (
        <Loading />
      ) : (
        <>
          <ToastContainer />
          <Header hiddeMenu={!user?.isActive} />
          <main className='container mx-auto'>{children}</main>
          {user?.isActive && <WhatsappButton />}
        </>
      )}
    </div>
  )
}
