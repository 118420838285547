import MoneyAsText from '@/components/Common/MoneyAsText'
import { useFetchMyProfits } from '../hooks/useFetchMyProfits'
import { useEffect } from 'react'

export const BonusProfit = () => {
  const { data: dataProfit, fetchMyProfits } = useFetchMyProfits()

  useEffect(() => {
    fetchMyProfits()
  }, [])

  return (
    <div className='flex flex-col gap-4'>
      <h3 className='text-lg md:text-xl md:my-2'>Resumo de Lucros</h3>

      <div className='w-full flex flex-col lg:flex-row gap-3 lg:gap-5'>
        <Bonusitem
          img={
            <img
              className='h-[100px] md:h-[140px] w-[85px] md:w-[120px] object-cover'
              src='/images/bonusIlustration1.png'
              alt='ilustration'
            />
          }
          value={dataProfit.dataNetwork?.profit}
          title='Saldo de Equipe'
        />

        <Bonusitem
          img={
            <div className='h-[100px] md:h-[140px] w-[85px] md:w-[120px]'>
              <img className='h-full object-cover overflow-visible' src='/images/bonusIlustration2.png' alt='ilustration' />
            </div>
          }
          value={dataProfit.dataOperations?.profit}
          title='Saldo de Lucros'
        />
      </div>
    </div>
  )
}

const Bonusitem = ({ img, value = 0, title }) => {
  return (
    <div className='w-full flex bg-gradient-to-b from-blue-600 to-80% to-blue-500 p-[2px] rounded-2'>
      <div className='bg-blue-500 w-full flex items-center gap-3 rounded-2 overflow-hidden'>
        {img}

        <div className='flex gap-3 items-center'>
          <div className='p-2 rounded bg-primary shrink-0'>
            <img className='w-4 md:w-5' src='/icons/walletCoins.svg' alt='coins' />
          </div>

          <div className='flex flex-col'>
            <p className='text-lg md:text-xl lg:text-2xl whitespace-nowrap'>
              <MoneyAsText value={value} />
            </p>
            <p className='text-gray-300 text-sm md:text-base'>{title}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
