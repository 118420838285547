import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@/components/Form'
import { FieldMessage, PasswordField, TextField } from '@/components/Form/Fields'
import ReCAPTCHA from 'react-google-recaptcha'
import { Box } from '@chakra-ui/react'
import { usePageSignIn } from './hooks'
import { ModalForgotPassword } from './container/ForgotPassword'

export const SignIn = () => {
  const { isOpen, onClose, onOpen, loading, handleSubmit, handleChange, setFieldValue, values, errors } = usePageSignIn()

  return (
    <>
      <ModalForgotPassword isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

      <Box className='flex flex-col w-full lg:max-w-[625px] justify-center lg:mt-10'>
        <h1 className='text-text text-xl font-semibold lg:text-4xl my-2.5 lg:my-4'>Bem vindo(a) de volta!</h1>
        <form onSubmit={handleSubmit} className='w-full'>
          <TextField
            label='Seu Login'
            name='username'
            placeholder='Ex.: João'
            value={values.username}
            errorMessage={errors.username}
            onChange={handleChange}
            isDisabled={loading}
          />
          <PasswordField
            label='Sua Senha'
            name='password'
            placeholder='*******'
            value={values.password}
            errorMessage={errors.password}
            onChange={handleChange}
            isDisabled={loading}
          />
          <div className='flex mb-2 mt-[-8px]'>
            <Button text='Esqueci minha senha' onClick={onOpen} className='bg-transparent p-0 underline text-white' />
          </div>
          <div className='flex flex-col gap-2 w-full'>
            <div className='flex flex-col mt-2 mb-1 w-full'>
              <ReCAPTCHA
                name='recaptcha'
                render='explicit'
                onChange={value => setFieldValue('recaptcha', value)}
                sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
              />
              {errors.recaptcha && <FieldMessage message={errors.recaptcha} />}
            </div>
            <Button text='Entre no seu BackOffice' type='submit' isDisabled={loading} className='py-3 lg:py-2' />
            <Link to='/sign-up' className='w-full'>
              <Button className='w-full bg-transparent border border-gray-200 py-3 lg:py-2 text-white'>
                Não tem conta? Cadastre-se
              </Button>
            </Link>
          </div>
        </form>
      </Box>
    </>
  )
}
