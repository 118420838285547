import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchtWithdrawals = () => {
  const { data, error, makeRequest, loading } = useApi(params => {
    const { route, method } = getEndpoint('fetchtWithdrawals')
    return httpClient[method](route, { ...params })
  })

  return {
    dataWithdrawals: data,
    errorWithdrawals: error,
    loadingWithdrawals: loading,
    makeWithdrawals: makeRequest,
  }
}
