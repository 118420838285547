import MoneyAsText from '@/components/Common/MoneyAsText'
import { useBets } from '@/contexts/BetsContext'

export function MoneyCard({ value, handleSelect, isActive }) {
  const { isDisable } = useBets()
  const types = {
    active: 'bg-primary text-black font-semibold',
    inactive: 'bg-[#414755] hover:bg-primary/75 hover:font-semibold hover:text-black',
  }

  return (
    <div
      onClick={isDisable ? null : handleSelect}
      className={`flex flex-1 md:flex-none ${
        isActive ? types['active'] : types['inactive']
      } rounded-[3px] md:rounded-2 cursor-pointer w-full max-h-[70px] items-center justify-center py-2`}
    >
      <MoneyAsText value={value ? value : 0} />
    </div>
  )
}
