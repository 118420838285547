import clock from '/images/clock.svg'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

export const ClockInformation = ({ className }) => {
  const { t } = useTranslation()
  return (
    <div className={classNames('flex gap-2.5 items-center', className)}>
      <img className='mb-1' src={clock} />
      <p>{t('withdraw.timeWithdraw')}</p>
    </div>
  )
}
