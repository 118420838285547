import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import classNames from 'classnames'
import { useState } from 'react'
import { IoIosArrowDown } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { avatarDropdownItems } from '../utils/menuItems'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/contexts'

export const ProfileDropdown = ({ className, handleClick }) => {
  const { t } = useTranslation()
  const { setToken, user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  return (
    <Menu isLazy placement='bottom-end' isOpen={isOpen} onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)}>
      <MenuButton className='w-full'>
        <div className={classNames('w-full flex gap-2 items-center', className)}>
          <div className='rounded-full border-[3px] border-white overflow-hidden w-12 h-12'>
            <img src='/images/avatar_placeholder.png' className='object-cover w-full h-full' />
          </div>

          <div className='flex flex-col items-start'>
            <strong className='font-medium text-gray-100 -mb-1'>{user?.name}</strong>
            <p className='font-normal text-sm text-gray-100/25'>{user?.username}</p>
          </div>

          <IoIosArrowDown className={classNames('text-slate-400 text-xl transition ml-auto', { 'rotate-180': isOpen })} />
        </div>
      </MenuButton>

      <MenuList>
        {avatarDropdownItems.map((item, ind) => {
          if (item.name === 'Grupo Whatsapp' && !user?.isActive) return null
          return (
            <MenuItem
              key={ind}
              py='12px'
              onClick={() => {
                if (item.isExit) setToken()
                if (item.route) {
                  navigate(item.route)
                } else {
                  item.action(item.name, user)
                }
                handleClick && handleClick()
              }}
              className='flex items-end gap-2 text-typography'
              color='#111315'
              _hover={{ bg: item.isExit && 'rgb(248, 113, 113, .5)' }}
            >
              <item.Icon size={item.iconSize ? item.iconSize : 18} />
              <p>{t(item.name)}</p>
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}
