import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'

export const CustomCircularProgress = ({ children, value, className, thickness = '8px', size = 100, trackColor = '#f8f8f8' }) => {
  return (
    <CircularProgress
      className={className}
      value={value}
      color='#A2EA2C'
      thickness={thickness}
      size={size}
      style={{ rotate: 'y 180deg' }}
      trackColor={trackColor}
    >
      <CircularProgressLabel style={{ rotate: 'y 180deg', translate: '-100%' }}>{children}</CircularProgressLabel>
    </CircularProgress>
  )
}
