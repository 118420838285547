import { useEffect, useState } from 'react'
import { useFetchGraduationList } from './useFetchGraduationList'
import { useAuth } from '@/contexts'

export const useGraduation = () => {
  const [graduationStats, setGraduationStats] = useState({})
  const [graduationMonthly, setGraduationMonthly] = useState({})
  const [graduationList, setGraduationList] = useState([])
  const { user } = useAuth()

  const { data: dataGraduationList, isLoading: isLoadingGraduationList, fetchGraduationList } = useFetchGraduationList()
  const data = {
    graduationStats,
    graduationList,
    graduationMonthly,
  }
  const isLoadingData = isLoadingGraduationList

  const calculateGraduationProgress = graduation => {
    if (!graduation) return 0

    const rulesLength = graduation.rules?.length
    let progressSum = 0

    graduation.rules?.forEach(rule => {
      progressSum += rule.percent
    })

    return progressSum / rulesLength
  }

  useEffect(() => {
    if (!dataGraduationList) return

    const graduation = dataGraduationList.find(item => item.position === user.careerPath.graduation)
    const graduationMonth = dataGraduationList.find(item => item.position === user.careerPath.monthlyGraduation)
    setGraduationStats(graduation)
    setGraduationMonthly(graduationMonth)
    setGraduationList(dataGraduationList)
  }, [dataGraduationList])

  const requestGraduation = () => {
    fetchGraduationList()
  }

  return { data, isLoading: isLoadingData, requestGraduation, calculateGraduationProgress }
}
