const endpoints = [
  { name: 'contractOffers', route: '/contracts/offers', method: 'get' },
  { name: 'myBets', route: '/customers/my-contracts', method: 'get' },
  { name: 'refreshQrCode', route: '/financial/deposit/{id}/verify-payment', method: 'get' },
  { name: 'requestWithdraw', route: '/financial/withdrawal', method: 'post' },
  { name: 'fetchtWithdrawals', route: '/customers/my-withdrawals', method: 'get' },
  { name: 'requestExtract', route: '/customers/extract', method: 'get' },
  { name: 'requestNetwork', route: '/customers/{id}/network', method: 'get' },
  { name: 'editProfile', route: '/auth/profile', method: 'put' },
  { name: 'lastOperations', route: '/financial/last-operations', method: 'get' },
  { name: 'resumeProfit', route: '/financial/stats/profitability-summary', method: 'get' },
  { name: 'profitTotal', route: '/financial/stats/customer-operation-percents', method: 'get' },
  { name: 'customerProfit', route: '/financial/stats/customer-profits', method: 'get' },
  { name: 'affiliateProgram', route: '/affiliate-program', method: 'get' },
  { name: 'pusrchaseWithBalance', route: '/contracts/activate-balance', method: 'post' },
  { name: 'generatePixCode', route: '/contracts/generate-pix-code', method: 'post' },
  { name: 'sendContract', route: '/contracts/purchase', method: 'post' },
  { name: 'percentLicense', route: '/financial/stats/licenses-percentage', method: 'get' },
  { name: 'editProfile', route: '/auth/profile', method: 'put' },
  { name: 'cancelPix', route: '/contracts/{id}/cancel', method: 'post' },
  { name: 'verifyContract', route: '/contracts/{id}/verify-payment', method: 'get' },
  { name: 'networkProfit', route: '/financial/stats/my-network-profit', method: 'get' },
  { name: 'operationsProfit', route: '/financial/stats/my-operations-profit', method: 'get' },
]

export const getEndpoint = (routeName, routeParams = {}) => {
  const endpointsCopy = JSON.parse(JSON.stringify(endpoints))
  const endpoint = endpointsCopy.find(row => row.name === routeName)
  if (!endpoint) throw new Error(`Invalid endpoint name '${routeName}'.`)
  for (let paramName in routeParams) {
    endpoint.route = endpoint.route.replace(`{${paramName}}`, routeParams[paramName])
  }
  return endpoint
}
