import { AffiliateNetwork } from './AffiliateNetwork'
import { Spinner } from '@chakra-ui/react'
import { TbFolderOff } from 'react-icons/tb'

export const NetworkList = ({ loading, dataNetwork }) => {
  if (loading) {
    return (
      <div className='w-full flex justify-center my-6'>
        <Spinner size='xl' className='text-primary' thickness='4px' />
      </div>
    )
  }

  return (
    <div className='w-full flex flex-col gap-4 my-5'>
      {dataNetwork.length === 0 && (
        <div className='w-full p-2 py-4 bg-red-300 rounded-2 flex justify-center items-center gap-2'>
          <TbFolderOff className='text-xl' />
          <p>Nenhum afiliado encontrado.</p>
        </div>
      )}
      {dataNetwork.map((item, ind) => (
        <AffiliateNetwork key={ind} affiliate={item} />
      ))}
    </div>
  )
}
