import httpClient from '@/utils/httpClient'
import { useApi } from '@/hooks'
import { getEndpoint } from '@/endpoints'

export const useGeneratePixCode = () => {
  const { route, method } = getEndpoint('generatePixCode')
  const { data, loading, makeRequest } = useApi(values => {
    return httpClient[method](route, { ...values, validatePrice: false })
  })

  return {
    dataPixCode: data,
    isLoading: loading,
    generatePixCode: makeRequest,
  }
}
