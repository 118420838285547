import { BiDollarCircle, BiHomeAlt } from 'react-icons/bi'
import { TbReportMoney, TbFileDownload } from 'react-icons/tb'
import { IoIosGitNetwork, IoMdLogOut } from 'react-icons/io'
import { BsClipboardCheck } from 'react-icons/bs'
import { FaRegUserCircle, FaWhatsapp } from 'react-icons/fa'
import { RiExchangeFundsLine } from 'react-icons/ri'

export const menuItems = [
  { route: '/bets', name: 'header.bet', Icon: RiExchangeFundsLine },
  { route: '/dashboard', name: 'header.dashboard', Icon: BiHomeAlt },
  { route: '/withdraw', name: 'header.withdraw', Icon: BiDollarCircle },
  { route: '/extract', name: 'header.extract', Icon: TbReportMoney },
  { route: '/network', name: 'header.network', Icon: IoIosGitNetwork },
  { route: '/career-path', name: 'header.careerPath', Icon: BsClipboardCheck },
]

const handleAction = (route, user) => {
  switch (route) {
    case 'APN':
      window.open(user.company.apnUrl, '_blank')
      break
    case 'Grupo Whatsapp':
      window.open(user.company.whatsapp.supportGroupUrl, '_blank')
      break

    default:
      break
  }
}

export const avatarDropdownItems = [
  { route: '/profile', name: 'header.profile', Icon: FaRegUserCircle },
  { name: 'APN', Icon: TbFileDownload, iconSize: 20, action: handleAction },
  { name: 'Grupo Whatsapp', Icon: FaWhatsapp, action: handleAction },
  { route: '/sign-in', name: 'header.logout', Icon: IoMdLogOut, isExit: true },
]
