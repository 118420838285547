import React, { useEffect } from 'react'
import { SearchField, SelectField } from '@/components/Form/Fields'
import { useFetchNetwork, useFormFilters } from './hooks'
import { SummationItem } from './components/SummationItem'
import { getIndicatorsTypeNames } from './ultis/constants'
import { useTranslation } from 'react-i18next'
import { FilterBuilder } from '@/utils/searchHelper'
import { NetworkList } from './components'
import { useAuth } from '@/contexts'
import { Boarding } from '@/components/Common/Boarding'

export const Network = () => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const { data: dataNetwork, loading, makeRequest } = useFetchNetwork()
  const { values, handleSubmit, handleChange } = useFormFilters({
    onSubmit: values => {
      const { term } = values
      const filterOptions = FilterBuilder().equal('term', term).build()
      makeRequest({ ...filterOptions })
    },
  })

  const getOrderOptions = () => [
    {
      label: 'Alfabético (A-Z)',
      value: 'name',
    },
    {
      label: 'Alfabético (Z-A)',
      value: '-name',
    },
    {
      label: 'Data de Ativação (Crescente)',
      value: 'activatedAt',
    },
    {
      label: 'Data de Ativação (Decrescente)',
      value: '-activatedAt',
    },
    {
      label: 'Data de Criação (Crescente)',
      value: 'createdAt',
    },
    {
      label: 'Data de Criação (Decrescente)',
      value: '-createdAt',
    },
    // {
    //   label: 'Contratos Ativos (Crescente)',
    //   value: 'directVolumeSum',
    // },
    // {
    //   label: 'Contratos Ativos (Decrescente)',
    //   value: '-directVolumeSum',
    // },
  ]

  const handleChangeOrder = orderParms => {
    makeRequest({ sort: orderParms })
  }

  useEffect(() => {
    if (!user) return
    makeRequest(user?.id)
  }, [user])

  return (
    <main className='flex flex-col gap-3 mx-4'>
      <header className='flex flex-col gap-4'>
        <Boarding text='Afiliados' hiddeName />
      </header>

      <section className='flex flex-col items-center justify-center gap-2 mt-2'>
        <SearchField handleChange={handleChange} handleSubmit={handleSubmit} values={values} loading={loading} />

        <div className='w-full mt-5 grid grid-cols-2 lg:grid-cols-4 gap-3'>
          <SummationItem
            key={1}
            icon={getIndicatorsTypeNames(1).icon}
            value={dataNetwork ? dataNetwork.sumActivated : 0}
            text={t(getIndicatorsTypeNames(1).text)}
          />
          <SummationItem
            key={2}
            icon={getIndicatorsTypeNames(2).icon}
            value={dataNetwork ? dataNetwork.sumAllNetwork : 0}
            text={t(getIndicatorsTypeNames(2).text)}
          />
          <SummationItem
            key={3}
            icon={getIndicatorsTypeNames(3).icon}
            value={dataNetwork ? dataNetwork.directIndications : 0}
            text={t(getIndicatorsTypeNames(3).text)}
          />
          <SummationItem
            key={4}
            icon={getIndicatorsTypeNames(4).icon}
            value={dataNetwork ? dataNetwork.activeDirectReferrals : 0}
            text={t(getIndicatorsTypeNames(4).text)}
          />
        </div>

        <div className='flex flex-col gap-2 mt-3 w-full lg:flex-row lg:justify-end lg:items-center'>
          <h3 className='font-bold text-white'>Ordenar por:</h3>
          <SelectField
            optionPrimary={null}
            options={getOrderOptions()}
            onChange={e => handleChangeOrder(e.target.value)}
            className='text-sm text-white'
          />
        </div>

        <NetworkList dataNetwork={dataNetwork.children || []} loading={loading} />
      </section>
    </main>
  )
}
