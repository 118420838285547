const SIGNED = 1
const AFFILIATE = 2
const TRADER = 3
const TRAADER_GOLD = 4
const TRADER_BLACK = 5
const BROKER = 6
const BROKER_GOLD = 7
const BROKER_BLACK = 8

export const calculateGraduationProgress = graduation => {
  if (!graduation) return 0

  const rulesLength = graduation.rules?.length
  let progressSum = 0

  graduation.rules?.forEach(rule => {
    progressSum += rule.percent
  })

  return progressSum / rulesLength
}

export const getGraduationName = position => {
  switch (position) {
    case SIGNED:
      return 'Cadastrado'
    case AFFILIATE:
      return 'Afiliado'
    case TRADER:
      return 'Trader'
    case TRAADER_GOLD:
      return 'Trader GOLD'
    case TRADER_BLACK:
      return 'Trader BLACK'
    case BROKER:
      return 'Broker'
    case BROKER_GOLD:
      return 'Broker GOLD'
    case BROKER_BLACK:
      return 'Broker BLACK'
    default:
      break
  }
}

export const getRulesDescription = (value, type) => {
  let moneyValue
  switch (type) {
    case 'FIRST_LEVEL_INDICATIONS':
      return `Indique ${value} amigos`

    case 'CONTRACT_PURCHASE_SUM':
      moneyValue = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value)

      return `Ativar no mínimo R$ ${moneyValue}`

    case 'FIRST_LEVEL_MONEY_SUM':
      moneyValue = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value)

      return `R$ ${moneyValue} em vendas diretas de contratos`

    case 'SECOND_GOAL_AFFILIATE_COUNT':
      return `Obter ${value} indicados no nível Afiliados`

    case 'THIRD_GOAL_AFFILIATE_COUNT':
      return `Obter ${value} indicados no nível Trader`

    case 'FOURTH_GOAL_AFFILIATE_COUNT':
      return `Obter ${value} indicados no nível Trader Gold`

    case 'FIFTH_GOAL_AFFILIATE_COUNT':
      return `Obter ${value} indicados no nível Trader Black`

    case 'SIXTH_GOAL_AFFILIATE_COUNT':
      return `Obter ${value} indicados no nível Broker`

    case 'SEVENTH_GOAL_AFFILIATE_COUNT':
      return `Obter ${value} indicados no nível Broker Gold`

    case 'NETWORK_DEPOSIT_SUM':
      moneyValue = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value)

      return `Obter em toda a lista de afiliados R$ ${moneyValue} em depósitos`

    case 'FIRST_GOAL_AFFILIATE_COUNT_DIFFERENT_LINES':
      return `Obter no seu sistema de afiliados ${value} indicados Star em linhas diferentes (Qualquer nível)`

    case 'SECOND_GOAL_AFFILIATE_COUNT_DIFFERENT_LINES':
      return `Obter no seu sistema de afiliados ${value} indicados Safirastar em linhas diferentes (Qualquer nível)`

    case 'THIRD_GOAL_AFFILIATE_COUNT_DIFFERENT_LINES':
      return `Obter no seu sistema de afiliados ${value} indicados GreenStar em linhas diferentes (Qualquer nível)`

    case 'FOURTH_GOAL_AFFILIATE_COUNT_DIFFERENT_LINES':
      return `Obter no seu sistema de afiliados ${value} indicados Platinumstar em linhas diferentes (Qualquer nível)`

    case 'FIFTH_GOAL_AFFILIATE_COUNT_DIFFERENT_LINES':
      return `Obter no seu sistema de afiliados ${value} indicados Goldstar em linhas diferentes (Qualquer nível)`

    default:
      break
  }
}

// export const getGraduationPin = position => {
//   switch (position) {
//     case SIGNED:
//       return '/icons/pins/cadastrado.png'
//     case GAMBLER:
//       return '/icons/pins/apostador.png'
//     case AFFILIATE:
//       return '/icons/pins/afiliado.png'
//     case TOP_AFFILIATE:
//       return '/icons/pins/top-afiliado.png'
//     case AFFILIATE_MANAGER:
//       return '/icons/pins/gerente-afiliados.png'
//     case EXPERT:
//       return '/icons/pins/expert.png'
//     case EXPERT_VIP:
//       return '/icons/pins/expert-vip.png'
//     case EXPERT_BLACK:
//       return '/icons/pins/expert-black.png'

//     default:
//       break
//   }
// }
