import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useRequestWithdraw = () => {
  const { data, error, makeRequest, loading, statusCode } = useApi(params => {
    const { route, method } = getEndpoint('requestWithdraw')
    return httpClient[method](route, { ...params })
  })

  return {
    statusCodeRequestWithdraw: statusCode,
    dataRequestWithdraw: data,
    errorRequestWithdraw: error,
    loadingRequestWithdraw: loading,
    makeWithdraw: makeRequest,
  }
}
