import httpClient from '@/utils/httpClient'
import { useApi } from '@/hooks'
import { getEndpoint } from '@/endpoints'
import { useEffect, useState } from 'react'

export const useFetchExtract = () => {
  const [queryParams, setQueryParams] = useState({ page: 1 })
  const { data, loading, makeRequest, meta } = useApi(params => {
    const { route, method } = getEndpoint('requestExtract')
    return httpClient[method](route, { params })
  })

  const onFetchExtract = params => {
    makeRequest({ ...params }).finally(() => setQueryParams({ ...params }))
  }

  useEffect(() => {
    onFetchExtract()
  }, [])

  return {
    data,
    loading,
    queryParams,
    meta,
    onFetchExtract,
  }
}
