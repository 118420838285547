import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

export const IconBadge = ({ iconImg, className, ...rest }) => {
  return (
    <div className={twMerge(classNames('bg-primary-50 p-2 rounded shrink-0 flex justify-center items-center', className))}>
      <img className='md:w-4 w-3' src={iconImg} {...rest} />
    </div>
  )
}
