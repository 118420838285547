import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

export const useFormRequestBet = ({ onSubmit }) => {
  const { t } = useTranslation()
  const Schema = Yup.object({
    phone: Yup.string()
      .required(t('global.validation.required'))
      .nullable(t('global.validation.required'))
      .test('lengthMinPhone', t('global.validation.invalidPhone'), value => {
        const clearValue = String(value).replace(/[^0-9]/g, '')
        return clearValue.length === 11 || clearValue.length === 0
      }),
    cpf: Yup.string()
      .required(t('global.validation.required'))
      .test('lengthMinCpf', t('global.validation.cpfLength'), value => {
        const clearValue = String(value).replace(/[^0-9]/g, '')
        return clearValue.length === 11 || clearValue.length === 0
      }),
  })

  return useFormik({
    initialValues: {
      phone: '',
      cpf: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
