import { useAuth } from '@/contexts'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLoginCustomer } from './useLoginCustomer'
import { useFormSignIn } from './useFormSignIn'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { useDisclosure } from '@chakra-ui/react'

export const usePageSignIn = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { token, setToken } = useAuth()
  const { loading, login } = useLoginCustomer()

  const { handleSubmit, handleChange, setFieldValue, values, errors } = useFormSignIn({
    onSubmit: async values => {
      try {
        const response = await login(values)

        const data = response.data.data
        setToken(data.token)
        const from = location.state?.from?.pathname || '/dashboard'
        navigate(from, { replace: true })
      } catch (err) {
        Swal.fire({
          title: t('signIn.userOrPasswordInvalid'),
          text: t('signIn.tryAgain'),
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },
  })

  useEffect(() => {
    if (token) navigate('/dashboard')
  }, [token])

  return {
    loading,
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    isOpen,
    onClose,
    onOpen,
  }
}
