import { useBalance } from '@/contexts'
import MoneyAsText from './MoneyAsText'
import { PiEyeBold, PiEyeClosedBold } from 'react-icons/pi'
import { useState } from 'react'
import { twJoin } from 'tailwind-merge'

export const BalanceCards = () => {
  const { data: dataBalance } = useBalance()
  const [isVisible, setIsViseble] = useState(false)

  const Depositballance = ({ mobile }) => {
    return (
      <p
        className={twJoin(
          'text-slate-100 flex items-center gap-1 border-slate-100',
          mobile ? 'flex lg:hidden' : 'hidden lg:flex'
        )}
      >
        <strong className='font-semibold md:font-normal'>Banca total</strong>:{' '}
        {!isVisible ? (
          <MoneyAsText value={dataBalance?.depositBalance} className='blur-[8px]' />
        ) : (
          <MoneyAsText value={dataBalance?.depositBalance} />
        )}{' '}
        <button className='text-xl z-[2]' onClick={() => setIsViseble(prev => !prev)}>
          {isVisible ? <PiEyeBold /> : <PiEyeClosedBold />}
        </button>
      </p>
    )
  }

  return (
    <div className='w-full flex flex-col lg:flex-row gap-3 relative text-[18px] lg:text-xl !z-[5]'>
      <div className='relative w-full flex flex-wrap justify-start gap-4 items-center p-4 px-5 lg:p-5.5 lg:px-7 lg:pl-[250px] rounded-2 rounded-tl-[2rem] bg-gradient-to-r from-gray-50/5 via-45% lg:via-20% via-gray-50/60 to-gray-50'>
        <img
          className='lg:block hidden h-full max-h-[120px] absolute bottom-[2.5%] left-[-4%] scale-105'
          src='/images/pngwing1.png'
          alt='alt'
        />

        <p className='text-slate-100'>
          <strong className='font-semibold lg:font-normal'>Seu saldo é de</strong>:{' '}
          {!isVisible ? (
            <MoneyAsText value={dataBalance?.balance} className='blur-[8px]' />
          ) : (
            <MoneyAsText value={dataBalance?.balance} />
          )}
        </p>

        <span className='h-[20px] w-[2px] bg-slate-100 hidden lg:block' />

        <Depositballance />

        <img className='absolute h-[80%] bottom-0 right-0' src='/images/graphYellow.svg' alt='graph' />
      </div>

      <div className='w-full flex lg:hidden justify-start items-center p-4 px-5 rounded-2 rounded-tl-[2rem] bg-gradient-to-r from-gray-50/5 via-45% lg:via-0% via-gray-50/60 to-gray-50'>
        <Depositballance mobile />
      </div>

      <img
        className='block lg:hidden w-60 absolute top-1/2 left-0 -translate-x-1/2 -translate-y-1/2'
        src='/images/pngwing1.png'
        alt='alt'
      />
    </div>
  )
}
