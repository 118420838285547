import { SearchInput } from '../SearchInput'
import { Button } from '..'

export const SearchField = ({ values, handleSubmit, handleChange, loading }) => {
  return (
    <form onSubmit={handleSubmit} className='w-full flex flex-col md:flex-row gap-4'>
      <SearchInput
        placeholder='Busque por um Afiliado'
        className='w-full'
        value={values.term}
        onChange={handleChange}
        name='term'
      />
      <Button
        text='Consultar'
        className='lg:min-w-[340px] py-3 sm:py-2 rounded-[0.284rem] bg-primary-50 text-zinc-950'
        type='submit'
        isLoading={loading}
        isDisabled={loading}
      />
    </form>
  )
}
