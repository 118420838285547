import { useTranslation } from 'react-i18next'

export const MASCULINE = 1
export const FEMININE = 2
export const OTHERS = 3

export const SINGLE = 1
export const MARRIED = 2
export const WIDOWER = 3
export const DIVORCED = 4

export const PHYSICAL_PERSON = 1
export const LEGAL_PERSON = 2

export const REAL = 'BRL'
export const DOLLAR = 'USD'
export const EURO = 'EUR'

export const getMaritalStatusText = status => {
  const { t } = useTranslation()
  switch (status) {
    case SINGLE:
      return t('profile.single')
    case MARRIED:
      return t('profile.married')
    case DIVORCED:
      return t('profile.divorced')
    case WIDOWER:
      return t('profile.widower')
    default:
      throw new Error(`Invalid Marital Status "${status}".`)
  }
}

export const getCurrencyCodeText = status => {
  switch (status) {
    case REAL:
      return 'Real'
    case DOLLAR:
      return 'Dólar'
    case EURO:
      return 'Euro'
    default:
      throw new Error(`Invalid Marital Status "${status}".`)
  }
}

export const getGenderText = gender => {
  const { t } = useTranslation()
  switch (gender) {
    case MASCULINE:
      return t('profile.masculine')
    case FEMININE:
      return t('profile.feminine')
    case OTHERS:
      return t('profile.others')
    default:
      throw new Error(`Invalid Gender "${gender}".`)
  }
}

export const getNatureText = nature => {
  const { t } = useTranslation()
  switch (nature) {
    case PHYSICAL_PERSON:
      return t('profile.physicalPerson')
    case LEGAL_PERSON:
      return t('profile.legalPerson')
    default:
      throw new Error(`Invalid Nature "${nature}".`)
  }
}

export const getMaritalStatusOptions = () => {
  return [
    { label: getMaritalStatusText(SINGLE), value: SINGLE },
    { label: getMaritalStatusText(MARRIED), value: MARRIED },
    { label: getMaritalStatusText(DIVORCED), value: DIVORCED },
    { label: getMaritalStatusText(WIDOWER), value: WIDOWER },
  ]
}

export const getGenderOptions = () => {
  return [
    { label: getGenderText(MASCULINE), value: MASCULINE },
    { label: getGenderText(FEMININE), value: FEMININE },
    { label: getGenderText(OTHERS), value: OTHERS },
  ]
}

export const getNatureOptions = () => {
  return [
    { label: getNatureText(PHYSICAL_PERSON), value: PHYSICAL_PERSON },
    { label: getNatureText(LEGAL_PERSON), value: LEGAL_PERSON },
  ]
}

export const getCurrencyCodeOptions = () => {
  return [
    { label: getCurrencyCodeText(REAL), value: REAL },
    { label: getCurrencyCodeText(DOLLAR), value: DOLLAR },
    { label: getCurrencyCodeText(EURO), value: EURO },
  ]
}
