import { useAuth } from '@/contexts'
import sponsorIcon from '@/assets/images/sponsorIcon.svg'
import { IconBadge } from '@/components/Common/IconBadge'

export const PersonalCard = () => {
  const { user } = useAuth()
  return (
    <div className='min-w-[280px] w-full flex gap-3 justify-between items-center lg:p-4 p-3 rounded-2 origin-bottom-left snap-start h-[100px] bg-secondary-100'>
      <div className='flex flex-col items-start py-2 text-text'>
        <p className='text-sm'>Patrocinado por:</p>
        <p className='text-lg font-medium'>{user?.indicatedBy.name}</p>
      </div>
      <div className='w-[70px] h-[70px] flex items-center justify-center'>
        <IconBadge iconImg={sponsorIcon} className='w-[45px] h-[45px] pr-1' />
      </div>
    </div>
  )
}
