import { Button } from '@/components/Form'
import { CpfCnpjField, MoneyField, SelectField, TextField } from '@/components/Form/Fields'
import { useWithdraw } from '../context/WithdrawContext'
import { ClockInformation } from '.'
import { getTypePixOptions } from '../constants'
import { verifyPixKeyType } from '../hooks/useVerifyPixKeyType'
import { useTranslation } from 'react-i18next'

export const FormPix = () => {
  const { t } = useTranslation()
  const { formPix, loadingRequestWithdraw } = useWithdraw()
  const { handleSubmit, values, handleChange, errors, setFieldValue } = formPix

  return (
    <form onSubmit={handleSubmit} className='flex flex-col w-full gap-3'>
      <MoneyField
        name='amount'
        isDisabled={loadingRequestWithdraw}
        value={values.amount}
        onChange={e => {
          setFieldValue('amount', e.numberValue)
        }}
        errorMessage={errors.amount}
        label={t('withdraw.amountField')}
        className='w-full'
        inputClassName='bg-blue-300'
        noMargins
        placeholder='Ex.: R$ 100,00'
      />
      <div className='flex flex-col md:flex-row items-center gap-3'>
        <TextField
          name='name'
          isDisabled={loadingRequestWithdraw}
          value={values.name}
          onChange={handleChange}
          errorMessage={errors.name}
          label={t('global.name')}
          className='w-full'
          inputClassName='bg-blue-300'
          noMargins
          placeholder='Ex.: João'
        />
        <TextField
          name='email'
          isDisabled={loadingRequestWithdraw}
          value={values.email}
          onChange={e => setFieldValue('email', e.target.value.trim())}
          errorMessage={errors.email}
          label={t('global.email')}
          className='w-full'
          inputClassName='bg-blue-300'
          noMargins
          placeholder='Ex.: joaosilva@email.com'
        />
      </div>
      <div className='flex flex-col lg:flex-row gap-3'>
        <CpfCnpjField
          name='cpf'
          isDisabled={loadingRequestWithdraw}
          value={values.cpf}
          onChange={handleChange}
          errorMessage={errors.cpf}
          label={t('global.cpf')}
          className='w-full'
          inputClassName='bg-blue-300'
          noMargins
          placeholder='Ex.: 123.456.789-01'
        />
        <SelectField
          name='pixKey'
          isDisabled={loadingRequestWithdraw}
          value={values.pixKey}
          onChange={e => {
            handleChange(e)
            setFieldValue('key', '')
          }}
          errorMessage={errors.pixKey}
          label={t('withdraw.pixTypeField')}
          className='w-full'
          SelectClassName='py-3 lg:py-2.5 mt-2 text-slate-400 rounded border-gray-200 bg-blue-300'
          noMargins
          placeholder='Ex.: CPF'
          options={getTypePixOptions()}
        />
        {verifyPixKeyType(values.pixKey)}
      </div>
      <ClockInformation />
      <Button
        isLoading={loadingRequestWithdraw}
        isDisabled={loadingRequestWithdraw}
        type='submit'
        text={t('withdraw.buttonConfirm')}
        className='w-full py-3'
      />
    </form>
  )
}
