import { GraduationItem } from './GraduationItem'

export const GraduationList = ({ datagraduation, currentGraduation }) => {
  return (
    <div className='grid md:grid-cols-2 gap-5'>
      <h3 className='text-lg md:col-span-2'>Suas próximas graduações</h3>

      {datagraduation.length > 0 &&
        datagraduation.map(item => <GraduationItem key={item.id} graduation={item} currentGraduation={currentGraduation} />)}
    </div>
  )
}
