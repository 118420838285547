import React from 'react'
import { InputText } from '@/components/Form/InputText'
import { IoIosSearch } from 'react-icons/io'
import classNames from 'classnames'

export const SearchInput = ({ className, ...rest }) => {
  return (
    <div className={classNames('flex items-center border border-white rounded-[0.284rem] py-2 p-3', className)}>
      <IoIosSearch size={25} className='shrink-0 mr-2 text-text' />
      <InputText type='text' className='border-0 p-0 py-2 lg:p-0 lg:py-2 my-0' {...rest} />
    </div>
  )
}
