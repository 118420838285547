export default {
  global: {
    companyName: 'Backoffice Boilerplate',
    successTitle: 'Tudo certo!',
    errorTitle: 'Ocorreu um erro',
    ok: 'OK',
    yes: 'Sim',
    labelCpf: 'Digite o seu CPF',
    placeholderCpf: 'Ex.: 759.219.260-86',
    no: 'Não',
    phone: 'Telefone',
    createdAt: 'Data Cadastro',
    screenLoadingText: 'Carregando...',
    randomKey: 'Chave aleatória',
    warningTitle: 'Atenção!',
    languages: {
      english: 'Inglês',
      portuguese: 'Português (BR)',
    },
    validation: {
      required: 'Este campo é obrigatório',
      invalidEmail: 'Email inválido',
      invalidPhone: 'Telefone inválido',
      invalidValue: 'Valor Inválido.',
      cpfLength: 'O CPF deve conter 11 números',
      cnpjLength: 'O CNPJ deve conter 14 números',
      confirmCpf: 'Confirme o seu CPF',
      rgLength: 'O RG deve conter 9 números',
      confirmPassword: 'As senhas não coincidem.',
      max20: 'Máximo 20 Caracteres.',
      min4: 'Mínimo 4 Caracteres.',
    },
    pix: 'PIX',
    bitcoin: 'Bitcoin',
    ethereum: 'Ethereum',
    tether: 'Tether Dolar',
    pixAbbr: 'Transfêrencia',
    bitcoinAbbr: 'BTC',
    paypalAbbr: 'ETH',
    tetherAbbr: 'USDT',
    name: 'Nome',
    cpf: 'CPF',
    cnpj: 'CNPJ',
    email: 'E-mail',
  },
  extract: {
    title: 'EXTRATO',
    filterBy: 'Filtre por:',
    transactionTypes: {
      allTypes: 'Todos os Tipos',
      contractPurchase: 'Contratos Realizados',
      manualBonus: 'Bônus Manual',
      networkCommission: 'Comissão Indicação',
      manualReversing: 'Estorno Manual',
      withdraw: 'Saque',
      profitability: 'Rentabilidade de operação',
      balanceActivation: 'Ativação por Saldo',
      residualProfit: 'Participação de Lucros (Rev)',
      withdrawRequest: 'Requisição de Saque',
      withdrawRefound: 'Reembolso de Saque',
      withdrawPercentTax: 'Taxa de Saque',
      arbitrationProfit: 'Lucro Arbitragem',
      arbitration: 'Arbitragem',
      deposit: 'Depósito',
      voucher: 'Voucher',
      residualCommission: 'Participação de Lucros (Rev)',
      operationProfit: 'Lucro Operação',
      dailyLimitReversal: 'Estorno Teto Diário',
      contractFees: 'Taxa Compra de Contrato',
    },
  },
  header: {
    dashboard: 'Dashboard',
    bet: 'Contratar Agora!',
    withdraw: 'Sacar',
    extract: 'Extrato',
    network: 'Afiliados',
    careerPath: 'Plano de Qualificação',
    profile: 'Perfil',
    logout: 'Sair',
    support: 'Suporte',
    whatsapp: 'Whatsapp',
    currentGraduation: 'Graduação Atual: ',
    director: 'Diretor',
    boardings: {
      dashboardTitle: 'Bem-vindo de volta, ',
      dashboardDescription: 'Vamos voltar aos contratos?',
      betTitle: 'Contratos',
      betDescription: 'Quanto você vai contratar hoje?',
      careerPathTitle: 'Plano de Qualificação',
      careerPathDescription: 'Veja o quanto você evoluiu!',
      profilePathTitle: 'Seu Perfil',
      profilePathDescription: 'Veja o quanto você evoluiu!',
      withdrawPathTitle: 'Saque',
      withdrawPathDescription: 'Veja o quanto você evoluiu!',
      networkPathTitle: 'Ramificação de Afiliados',
      networkPathDescription: 'Veja o quanto você evoluiu!',
      extractPathTitle: 'Seu Extrato',
      extractPathDescription: 'Veja o quanto você evoluiu!',
    },
  },
  careerPath: {
    CurrentGraduation: 'Graduação Atual',
    NextGraduation: 'Próxima Graduação',
    graduation: {
      signed: 'Cadastrado',
      affiliate: 'Afiliado',
      topAffiliate: 'Top Afiliado',
      affiliateManager: 'Gerente de Afiliados',
      expert: 'Expert',
      expertVip: 'Expert Vip',
      expertBlack: 'Expert Black',
      gambler: 'Apostador',
    },
  },
  dashboard: {
    welcome: 'Bem vindo(a) de volta,',
    howMuchBet: 'Quanto você vai contratar hoje?',
    moreValues: 'Mais valores',
  },
  profile: {
    placeholderName: 'Ex.: João',
    placeholderUser: 'Ex.: joao_user',
    placeholderEmail: 'Ex.: jõao@gmail.com',
    placeholderPhone: 'Ex.: (11) 98783-4343',
    placeholderCpf: 'Ex.: 012.345.678-90',
    placeholderAddress: 'Ex.: Rua dos Alpes, 1322',
  },
  bets: {
    userDetail: 'Exibir Detalhes do Usuário',
    newBets: 'NOVO CONTRATO',
    walletDollar: 'Carteira Tether Dólar',
    walletDollarDetail: 'Exibir Detalhes da Carteira',
    selectAmountBets: 'Escolha o valor que você quer contratar:',
    selectMethodBets: 'Selecione o Método de Pagamento desejado:',
    selectMethodTransfer: 'Realize sua transferência com o método escolhido:',
    pixButton: 'PIX',
    pixVoucher: 'Comprovante',
    pixPasteAndColeButton: 'COPIA COLA',
    bitcoinButton: 'Bitcoin',
    paypalButton: 'PayPal',
    bitcoinDescription: 'BTC',
    usdtButton: 'Tether',
    usdtDescription: 'USDT',
    balance: 'Saldo',
    balanceActivation: 'Ativação',
    balanceTransfer: 'Contrate Agora!',
    transfer: 'Transferência',
    paymentVoucher: 'Anexar Comprovante',
    paymentTotal: 'Valor total da compra:',
    yourBets: 'SEUS CONTRATOS',
    creditInsufficiency: 'Você não tinha créditos o suficiente para concluir essa compra!',
    reason: 'Razão',
    activated: 'Ativa',
    refused: 'Recusada',
    underReview: 'em Análise',
    waitingPayment: 'Aguardando Pagamento',
    date: 'Data:',
    hour: 'Hora:',
    accountName: 'Titular da Conta',
    agency: 'Agência',
    bankName: 'Banco',
    accountCode: 'Nº da Conta',
    accountDetail: 'Exibir Detalhes da Conta',
    toastCopy: 'Copiado com sucesso!',
    updateAmount: 'Valor Atual do ',
    qrCodeGenerator: 'Gerar QR Code',
    confirm: 'Confirmar Compra!',
    cancel: 'Cancelar',
    giftAFriendLabel: 'Deseja Presentear um Amigo?',
    giftAFriendPlaceholder: 'Digite o Login (Opcional)',
    submitVoucher: 'Enviar Comprovante',
    successBallanceDeposit: 'Envio de comprovante enviado com sucesso!',
    errorBlockedDeposit: 'Você está bloqueado para fazer compras, por favor, entre em contato com nosso suporte!',
    networkCode: 'Código da Rede:',
    copyCode: 'Código:',
    walletCode: 'Código da Carteira:',
    pixCode: 'Chave pix',
    errorPurchase: 'Erro ao enviar comprovante!',
    errorBallancePurchase: 'Erro ao ativar saldo!',
    insuficienteBalance: 'Saldo Insuficiente',
    giftCustomerNotFound: 'Amigo não encontrado!',
    minimumLicensePurchase: 'Valor da aposta não pode ser menor que ',
  },
  license: {
    license: 'Investir',
    letsBet: 'Vamos contratar!',
    myLicenses: 'Meus Investimentos',
    formOfPayment: 'Forma de Pagamento',
    selectValue: 'Selecione um valor',
    sendProof: 'Enviar comprovante',
    sendProofText: 'O arquivo deve estar no formato jpg, jpeg ou png e ter no máximo 2 MB.',
    purchase: 'Comprar',
    copy: 'Copiar',
    purshaseRequestTitle: 'Sua solicitação de compra foi feita.',
    purshaseRequestText: 'Aguarde a nossa equipe fazer análise de sua solicitação.',
    purshaseRequestErrorTitle: 'Ocorreu um Erro',
    purshaseRequestErrorText: 'Houve um erro ao tentar solicitar seu pedido',
    datePurchase: 'Data da Compra',
    licenseEmptyMessage: 'Não há investimentos no momento',
  },
  status: {
    pending: 'Pendente',
    approved: 'Aprovado',
    underAnaLysis: 'Em análise',
    refused: 'Recusado',
    awaitingPayment: 'Aguardando Pgto.',
    active: 'Ativo',
    inactive: 'Inativo',
    activeVoucher: 'Ativo',
  },
  signIn: {
    login: 'Login',
    password: 'Senha',
    userName: 'Usuário',
    accessAcount: 'Acessar conta',
    forgotMyPassword: 'Esqueci minha senha',
    enterEmail: 'Digite o e-mail cadastrado',
    enterUsername: 'Digite seu usuário',
    enterPassword: 'Digite sua senha',
    resetPassword: 'Redefinir senha',
    invalidEmail: 'Email inválido',
    validEmail: 'Email valido',
    userOrPasswordInvalid: 'Username ou senha inválido.',
    tryAgain: 'Tentar novamente',
    signUp: 'Não tenho conta, quero me cadastrar',
  },
  signUp: {
    fullName: 'Nome completo',
    email: 'Seu melhor e-mail',
    phone: 'Telefone',
    login: 'Login',
    password: 'Senha',
    passwordConfirm: 'Repita sua senha',
    signUp: 'Quero me cadastrar',
    signIn: 'Já tenho uma conta, quero entrar.',
    validation: {
      validationUsername: 'Somente caracteres alfanuméricos, sublinhados e hífens são permitidos',
      validationPassword: 'A senha é obrigatória',
      validationConfirmPassword: 'A confirmação de senha é obrigatória',
      validationRequiredConfirmPassword: 'As senhas não coincidem',
      invalidEmail: 'Email inválido',
      max20: 'Máximo 20 Caracteres.',
      min4: 'Mínimo 4 Caracteres.',
    },
    usernameTip: 'Utilize apenas letras minúsculas, números, traço e underline.',
    phoneTip: 'DDD + Seu telefone',
    successMessageTitle: 'Seu Cadastro foi Realizado.',
    successMessageText: 'Você agora tem acesso a plataforma da i7Trader',
  },
  withdraw: {
    validation: {
      ops: 'Ops',
      invalidWithdrawalDate: 'Hoje não é dia de fazer saques.',
      minimumWithdrawal: 'Valor mínimo: R$ 100,00. O valor deve ser maior que o saldo',
      insufficientBalance: 'Você não tem saldo suficiente para sacar esse valor.',
      invalidCpf: 'CPF inválido',
      elevenChars: 'O CPF deve ter 11 caracteres',
      withdrawCustomerIsBloqued: 'Houve um problema com seu saque. Entre em contato com o suporte.',
    },
    withdraw: 'Sacar',
    withdrawList: 'Lista de Saque',
    withdrawRequest: 'Solicitar Saque',
    withdrawalDays: 'saque apenas em dias: ',
    withdrawNotday: 'Não é dia de saque',
    namePerson: 'Nome da pessoa destinatária',
    cpf: 'Apenas números de CPF',
    pix: 'Chave PIX',
    value: 'Valor',
    wallet: 'Carteira',
    chooseWallet: 'Escolha sua criptomoeda',
    chooseWalletKey: 'Digite sua carteira',
    chooseValue: 'Digite o valor desejado',
    accountOwner: 'Os dados são os mesmos do proprietário da conta?',
    requestedWithdrawals: 'Saques solicitadas',
    receiverName: 'Nome do recebedor',
    recipientsCpf: 'CPF do destinatário',
    requestDate: 'Data do Pedido',
    refusedDate: 'Data da Recusa',
    status: 'Status',
    refused: 'Recusou',
    close: 'Fechar',
    refusedIn: 'Recusou-se em',
    reason: 'Motivo',
    details: 'Detalhes',
    pending: 'Pendente',
    approved: 'Aprovado',
    withdrawRequestSucess: 'Retirada solicitada com sucesso!!',
    noWithdrawRequest: 'Você ainda não solicitou saques.',

    successWithdraw: 'Saque realizado com sucesso.',
    errorWithdraw: 'Erro ao realizar Saque.',
    titleWithdraw: 'SAQUE',
    timeWithdraw: 'Por favor, espere até 7 dias para o processamento do seu saque. Este é o período que o saque é processado.',
    amountField: 'Valor do Saque',
    cryptoField: 'Carteira de Criptos',
    buttonConfirm: 'Confirmar Saque',
    pixTypeField: 'Tipo da Chave',
    key: 'Chave',
    informationWithdraw: 'Especifique como quer que o Saque seja realizado:',
    insuficienteBalance: 'Saldo Insuficiente',
    minWithdraw: 'Saque Mínimo é: ',

    refusedAt: 'Recusado em',
    withdrawStatus: {
      pending: 'Pendente',
      approved: 'Aprovado',
      refused: 'Recusado',
      canceledByUser: 'Cancelado',
      awaitingPayment: 'Aguardando Pgto.',
      underAnalysis: 'Em Análise',
      active: 'Ativo',
      activeVoucher: 'Voucher',
    },
  },
  network: {
    myNetwork: 'Meus Afiliados',
    actives: 'ativos',
    affiliates: 'investidores',
    directReferences: 'ind. diretos',
    activeDirectReferences: 'ind. dir. ativos',
    level: 'Nível',
    noAffiliatesMessage: 'Não possui investidor no momento',
    Consult: 'Consultar',
    searchAffiliate: 'Busque por um investidor...',
    volume: 'Depósitos',
  },
  transactionTypes: {
    allTypes: 'Todos os Tipos',
    contractPurchase: 'Contratos Realizados',
    manualBonus: 'Bônus Manual',
    networkCommission: 'Comissão Indicação',
    manualReversing: 'Estorno Manual',
    withdraw: 'Saque',
    withdrawalTax: 'Taxa de Saque (U$)',
    withdrawalTaxPercent: 'Taxa de Saque (%)',
    profitability: 'Rentabilidade de operação',
    balanceActivation: 'Ativação por Saldo',
    arbitrationProfit: 'Lucro Arbitragem',
    arbitration: 'Arbitragem',
    deposit: 'Depósito',
    voucher: 'Voucher',
    residualCommission: 'Participação de Lucros (Rev)',
    operationProfit: 'Lucro Operação',
    dailyLimitReversal: 'Estorno Teto Diário',
    contractFees: 'Taxa Compra de Contrato',
  },
}
