import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchGraduationList = () => {
  const { data, error, makeRequest, loading } = useApi(params => {
    const { route, method } = getEndpoint('affiliateProgram')
    return httpClient[method](route, { ...params })
  })

  return { data: data || [], isLoading: loading, fetchGraduationList: makeRequest, error }
}
