import { useAuth } from '@/contexts'
import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchNetwork = () => {
  const { user } = useAuth()
  const { data, loading, makeRequest } = useApi(params => {
    const { route, method } = getEndpoint('requestNetwork', { id: user.id })
    return httpClient[method](route, { params })
  })

  return { data: data || [], loading, makeRequest }
}
