import { BalanceCards } from '@/components/Common/BalanceCards'
import { Boarding } from '@/components/Common/Boarding'
import { GraduationMain } from './components/GraduationMain'
import { GraduationList } from './components/GraduationList'
import { useGraduation } from './hooks'
import { useEffect } from 'react'

export const CarrerPath = () => {
  const { data, requestGraduation } = useGraduation()

  useEffect(() => {
    requestGraduation()
  }, [])

  return (
    <div className='flex flex-col gap-4 px-3 text-slate-200 mb-8'>
      <Boarding text='Acompanhe seu crescimento' icon='/icons/userSteps.svg' hiddeName />

      <BalanceCards />

      <GraduationMain graduation={data.graduationStats} title='Graduação Atual' showBlur showBallance />

      <GraduationList datagraduation={data.graduationList} currentGraduation={data.graduationStats} />
    </div>
  )
}
