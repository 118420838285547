import { DateInput, Label } from '@/components/Form'

export const CustomDateInput = ({ value, name, onChange, placeholder, label, ...rest }) => {
  return (
    <div className='w-full h-full flex relative mt-3 sm:mt-0'>
      <Label text={label} className='absolute top-[-20px] left-0 text-sm' />
      <DateInput
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        className='py-[8px] px-3 lg:px-3 md:py-[12px] text-base rounded-2 leading-3 bg-none border-gray'
        {...rest}
      />
    </div>
  )
}
