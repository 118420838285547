import { SimpleAccordion } from '@/components/Common/Accordion'
import { MoneyCard } from './MoneyCard'
import CoinHandIcon from '/images/coin_with_hand.svg'
import DolarIcon from '/images/dolar_icon.svg'
import BitcoinIcon from '/images/bitcoin_icon.svg'
import CoinIcon from '/images/coins_icon.svg'
import { ResumeValueCard } from './ResumeValueCard'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useBets } from '@/contexts/BetsContext'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { SpinnerInput } from '@/components/Form'
import { BALLANCE, BITCOIN, DOLLAR, PIX, PIXPASTEANDCOLE } from '@/constants/betsConstants'

export function ValuesBoard() {
  const { dataCoins, paymentValue, setPaymentValue, contractOffers, isDisable, paymentMethod } = useBets()
  const { betValue } = useParams()

  const handleIncrease = () => {
    setPaymentValue(prev => {
      if (prev === null || prev === undefined) return 0
      return prev + 1
    })
  }

  const handleDecrease = () => {
    if (paymentValue === 0) return
    setPaymentValue(prev => {
      if (prev === null || prev === undefined) return 0
      return prev - 1
    })
  }

  const handleAddSelectAmount = offer => {
    setPaymentValue(() => offer)
  }

  const handleSelectAmount = value => {
    setPaymentValue(prev => {
      if (prev === value) {
        return 0
      }
      return value
    })
  }

  const getPrefix = paymentMethod => {
    switch (paymentMethod) {
      case BITCOIN:
        return '₿ '
      case DOLLAR:
        return 'U$ '
      default:
        return 'R$ '
    }
  }

  const getConvertValue = () => {
    const value = paymentValue
    if (paymentMethod === PIX || paymentMethod === PIXPASTEANDCOLE || paymentMethod === BALLANCE) {
      return <MoneyAsText prefixProp={getPrefix(paymentMethod)} value={value} />
    }

    if (paymentMethod === BITCOIN) {
      return `₿ ${(value / dataCoins?.BTCBRL?.bid).toFixed(7)}`
    }

    if (paymentMethod === DOLLAR) {
      return <MoneyAsText prefix={getPrefix(paymentMethod)} value={value / dataCoins?.USDBRL?.bid} />
    }

    return value
  }

  useEffect(() => {
    if (betValue) {
      setPaymentValue(Number(betValue))
    }
  }, [betValue])

  return (
    <div className='mt-[46px]'>
      <SimpleAccordion>
        <SimpleAccordion.Content title='Escolha o valor desejado'>
          <SimpleAccordion.Item>
            <div>
              <div className='flex justify-center w-full'>
                <SpinnerInput
                  name='spinner'
                  value={paymentValue}
                  onChange={e => setPaymentValue(e.numberValue)}
                  handleDecrease={handleDecrease}
                  handleIncrease={handleIncrease}
                  inputClassName='mt-0 text-center'
                  isDisabled={isDisable}
                />
              </div>
              <div className='grid gap-2.5 grid-cols-1 mt-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 lg:gap-3 justify-center'>
                {contractOffers &&
                  contractOffers
                    .sort((a, b) => a - b)
                    .map(offer => (
                      <MoneyCard
                        key={offer}
                        value={offer}
                        path={CoinHandIcon}
                        isActive={paymentValue === offer}
                        handleSelect={() => {
                          handleSelectAmount(offer)
                          handleAddSelectAmount(offer)
                        }}
                      />
                    ))}
              </div>
              <div className='mt-[25px] flex flex-col lg:flex-row gap-3 lg:border border-[#363C4D] rounded-2'>
                <ResumeValueCard>
                  <ResumeValueCard.Icon path={CoinIcon} imgClasses='lg:w-4' />
                  <ResumeValueCard.Text>Valor total: {getConvertValue()}</ResumeValueCard.Text>
                </ResumeValueCard>
                <ResumeValueCard>
                  <ResumeValueCard.Icon path={DolarIcon} imgClasses='lg:w-2.5' />
                  <ResumeValueCard.Text>
                    Valor do Dólar: <MoneyAsText value={dataCoins ? Number(dataCoins?.USDBRL?.bid) : 0} /> (BRL)
                  </ResumeValueCard.Text>
                </ResumeValueCard>
                <ResumeValueCard>
                  <ResumeValueCard.Icon path={BitcoinIcon} imgClasses='lg:w-3' />
                  <ResumeValueCard.Text>
                    BTC: <MoneyAsText value={dataCoins ? Number(dataCoins?.BTCBRL?.bid) : 0} /> (BRL)
                  </ResumeValueCard.Text>
                </ResumeValueCard>
              </div>
            </div>
          </SimpleAccordion.Item>
        </SimpleAccordion.Content>
      </SimpleAccordion>
    </div>
  )
}
