import { ModalCloseButton, ModalHeader as Header } from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const ModalHeader = ({ classNameHeader, text, status, closeButton }) => (
  <Header className={classNameHeader}>
    {text}
    {status}
    {closeButton && <ModalCloseButton />}
  </Header>
)

ModalHeader.propTypes = {
  classNameHeader: PropTypes.string,
  closeButton: PropTypes.bool,
}

ModalHeader.defaultProps = {
  classNameHeader: '',
  closeButton: true,
}
