import { CustomCircularProgress } from '@/components/Common/CustomCircularProgress'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

export const GraduationPercentCard = ({ value, name, icon = '/icons/goalIconNull.svg', className, profileScreen = false }) => {
  return (
    <div
      className={twMerge(
        classNames(
          'min-w-[280px] w-full flex gap-3 justify-evenly p-2 lg:p-3 rounded-2 border border-gray origin-bottom-left snap-start',
          className,
          {
            'justify-between items-center bg-secondary-100 border-none h-[100px] p-3 lg:p-4': profileScreen,
          }
        )
      )}
    >
      <div className='flex flex-col items-start py-2'>
        <p className='text-sm text-text mb-2 md:mb-none'>
          {profileScreen ? 'Graduação Atual' : value >= 100 ? 'Graduação Concluída' : 'Próxima Graduação'}:
        </p>

        <div className='flex items-center gap-2'>
          <img className='w-5.5' src={icon} />

          <p className='text-lg font-medium text-text'>{name}</p>
        </div>
      </div>

      <CustomCircularProgress value={value} size='70px' className='self-center'>
        <p className='text-sm font-medium text-text'>{value}%</p>
      </CustomCircularProgress>
    </div>
  )
}
