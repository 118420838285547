import { Skeleton } from '@chakra-ui/react'

export const GridPaymentItem = ({ title, value, isLoading }) => {
  return (
    <div className='flex flex-col items-start pb-3 border-b border-gray overflow-hidden self-stretch w-full'>
      <p className='text-white font-bold'>{title}</p>
      {isLoading ? <Skeleton w='100%' h='12px' className='mt-2' /> : <p>{value}</p>}
    </div>
  )
}
