import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { twMerge } from 'tailwind-merge'

export const Loader = ({ className }) => {
  return (
    <div className={twMerge('flex justify-center items-center py-2', className)}>
      <AiOutlineLoading3Quarters className='animate-spin h-[23px] w-[23px]' />
    </div>
  )
}
