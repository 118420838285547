import { FaCheck } from 'react-icons/fa'
import { twJoin } from 'tailwind-merge'

export function PaymentMethodCard({ path, title, isSelected, handleSelect, isDisable }) {
  const card = {
    selected: 'bg-primary ',
    normal: 'bg-zinc-400 hover:bg-primary/75 ',
  }

  const status = {
    selected: 'text-[#FFF]',
    normal: 'text-[#8C8C8C]',
  }

  return (
    <div onClick={isDisable ? null : handleSelect} className='flex gap-[20px] items-center cursor-pointer'>
      <div
        className={`flex items-center justify-center w-[76px] h-[53px] lg:w-[56px] lg:h-[33px] rounded-[5px] ${
          isSelected ? card['selected'] : card['normal']
        }`}
      >
        <img src={path} className={twJoin('lg:max-w-[18px] lg:max-h-[18px]')} />
      </div>
      <div>
        <p className='text-[#F5F5F5] text-[18px] lg:text-sm'>{title}</p>
        <div className='flex gap-2 items-center'>
          <span className={`${isSelected ? status['selected'] : status['normal']} text-base`}>
            {isSelected ? 'Selecionado' : 'Selecionar'}
          </span>
          {isSelected && (
            <span className='flex justify-center items-center w-3 h-3 bg-primary-50 rounded-full'>
              <FaCheck className='text-[8px] lg:text-[6px] text-slate-500' />
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
