import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useEditProfile = () => {
  const { data, error, makeRequest, loading, statusCode } = useApi(params => {
    const { route, method } = getEndpoint('editProfile')
    return httpClient[method](route, { ...params })
  })

  return {
    statusCodeEditProfile: statusCode,
    dataEditProfile: data,
    errorEditProfile: error,
    loadingEditProfile: loading,
    makeEditProfile: makeRequest,
  }
}
