import { useTranslation } from 'react-i18next'
import BitcoinWhiteIcon from '/images/bitcoin_white_icon.svg'
import PixIcon from '/images/pix_icon.svg'
import DolarWhiteIcon from '/images/dolar_white_icon.svg'
import CoinHandIcon from '/images/coin_hand_md.svg'

export const in_analyze = 3
export const active = 5
export const refused = 4

export const BITCOIN = 'bitcoin'
export const PIX = 'pix'
export const PIXPASTEANDCOLE = 'pixpastandcole'
export const DOLLAR = 'USTD'
export const BALLANCE = 'ballance'

export const getPaymentMethod = () => {
  const { t } = useTranslation()
  return [
    { label: t('bets.pixButton'), description: t('bets.pixPasteAndColeButton'), value: 'pixpastandcole', icon: PixIcon },
    { label: t('bets.bitcoinButton'), description: t('bets.bitcoinDescription'), value: 'bitcoin', icon: BitcoinWhiteIcon },
    { label: t('bets.usdtButton'), description: t('bets.usdtDescription'), value: 'USTD', icon: DolarWhiteIcon },
    { label: t('bets.pixButton'), description: t('bets.pixVoucher'), value: 'pix', icon: PixIcon },
    { label: t('bets.balance'), description: t('bets.balanceActivation'), value: 'ballance', icon: CoinHandIcon },
  ]
}
