import { useWithdraw } from '../context/WithdrawContext'
import { USDT, getTypeWithdrawOptions } from '../constants'
import { Badge } from '@/components/Common'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/contexts'
import { SelectItem } from './SelectItem'

export const SelectMethodSection = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { type, setType, formCrypto, formPix, formPaypal } = useWithdraw()
  const { resetForm: resetFormCrypto } = formCrypto
  const { resetForm: resetFormPix } = formPix
  const { resetForm: resetFormPaypal } = formPaypal

  const handleClickMethod = value => {
    setType(value)
    if (value !== type) {
      resetFormCrypto()
      resetFormPix()
      resetFormPaypal()
    }
  }

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex items-center gap-2 md:p-0'>
        <Badge
          text='01.'
          bg='bg-primary'
          textColor='text-slate-900'
          className='font-semibold text-md py-2 px-1 aspect-square w-[40px] h-[40px] flex justify-center items-center'
        />
        <p className='text-base md:text-[18px]'>{t('withdraw.informationWithdraw')}</p>
      </div>
      <div className='grid min-[480px]:grid-cols-2 md:grid-cols-4 gap-4'>
        {getTypeWithdrawOptions().map(method => {
          let select = type === method.value
          return (
            <SelectItem
              key={method.value}
              withdrawButton
              className='col-span-1'
              isSelected={select}
              onClick={() => handleClickMethod(method.value)}
              icon={method.icon}
              iconClassName='w-[20px] h-[20px]'
            >
              <div className='flex flex-col text-center'>
                <p>
                  {method.label}
                  {method.value === USDT && ` (${user.company.usdt.network.split(' ')[0]})`}
                </p>
                <abbr className='text-sm font-normal text-slate-500'>{method.abbr}</abbr>
              </div>
            </SelectItem>
          )
        })}
      </div>
    </div>
  )
}
