import { useAuth } from '@/contexts'
import { FaCheck } from 'react-icons/fa'
import ReferenceLink from './ReferenceLink'
import { useNavigate } from 'react-router-dom'
import { PiGearSix } from 'react-icons/pi'
import { useState } from 'react'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { avatarDropdownItems } from '../Menu/utils/menuItems'
import { useTranslation } from 'react-i18next'

export const Boarding = ({ icon, text, hiddeName }) => {
  const { user, setToken } = useAuth()
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className='flex flex-col gap-3'>
      <div className='w-full flex justify-between items-center !z-10'>
        <div className='flex items-center gap-2'>
          <img src={icon || '/icons/sportField.svg'} alt='sport field' />
          <p className='text-slate-100 text-lg'>
            {text} <br className='md:hidden' />{' '}
            {!hiddeName && (
              <div className='flex items-center gap-1'>
                {user?.name}
                {user?.isActive && (
                  <span className='flex justify-center items-center w-3 h-3 bg-primary-50 rounded-full'>
                    <FaCheck className='text-[8px] text-slate-500' />
                  </span>
                )}
              </div>
            )}
          </p>
        </div>

        <div className='flex gap-3 items-stretch !z-[10]'>
          <Menu
            placement='bottom-end'
            isOpen={isOpenMenu}
            onOpen={() => setIsOpenMenu(true)}
            onClose={() => setIsOpenMenu(false)}
          >
            <MenuButton>
              <div className='bg-gray-50 p-2.5 rounded-full text-slate-100 gap-2 md:flex md:bg-gray-50/0 md:p-0 md:gap-1 items-center'>
                <PiGearSix className='text-xl' />
                <p className='md:flex hidden'>Configurações</p>
              </div>
            </MenuButton>

            <MenuList style={{ zIndex: 10 }}>
              {avatarDropdownItems.map((item, ind) => {
                if (item.name === 'Grupo Whatsapp') return null
                if (item.name === 'APN') return null
                if (item.name === 'header.profile' && !user?.isActive) return null
                return (
                  <MenuItem
                    key={ind}
                    py='12px'
                    onClick={() => {
                      if (item.isExit) setToken()
                      if (item.route) {
                        navigate(item.route)
                      } else {
                        item.action(item.name, user)
                      }
                      isOpenMenu && setIsOpenMenu()
                    }}
                    className='flex items-end gap-2 text-typography'
                    color='#111315'
                    _hover={{ bg: item.isExit && 'rgb(248, 113, 113, .5)' }}
                  >
                    <item.Icon size={item.iconSize ? item.iconSize : 18} />
                    <p>{t(item.name)}</p>
                  </MenuItem>
                )
              })}
            </MenuList>
          </Menu>
        </div>
      </div>

      <ReferenceLink />
    </div>
  )
}
