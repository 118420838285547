import { ExtractCard } from './ExtractCard'
import { getTypeName } from '../utils/constants'
import { Spinner } from '@chakra-ui/react'
import { TbFolderOff } from 'react-icons/tb'
import InfiniteScroll from 'react-infinite-scroll-component'

export const ExtractList = ({ extract, fetchExtract }) => {
  const { loading, meta, onFetchExtract, queryParams } = fetchExtract
  const pagination = meta && meta.pagination.page

  return (
    <div className='w-full flex flex-col gap-3 mt-5'>
      {extract.length === 0 && loading && (
        <div className='w-full flex justify-center mt-6'>
          <Spinner size='xl' className='text-primary' thickness='4px' />
        </div>
      )}

      {extract.length === 0 && !loading && (
        <div className='w-full p-2 py-4 bg-red-300/25 text-slate-200 rounded-2 flex justify-center items-center gap-2'>
          <TbFolderOff className='text-xl' />
          <p>Nenhum registro encontrado.</p>
        </div>
      )}

      {extract.length > 0 && (
        <InfiniteScroll
          className='flex flex-col gap-4'
          dataLength={extract.length}
          next={() => {
            onFetchExtract({ ...queryParams, page: pagination + 1 })
          }}
          loader={
            loading && (
              <p className='flex gap-2 mt-3 items-center py-2 justify-center'>
                <Spinner />
                Carregando mais registros...
              </p>
            )
          }
          hasMore={extract.length <= meta.pagination.count}
          endMessage={<p>Sem mais registros para carregar.</p>}
        >
          {extract.map(item => (
            <ExtractCard
              key={item.id}
              date={item.createdAt}
              description={item.description}
              type={getTypeName(item.type)}
              value={item.amount}
              item={item}
            />
          ))}
        </InfiniteScroll>
      )}
    </div>
  )
}
