import { useBalance } from '@/contexts'
import { CardWithdrawSection, SelectMethodSection, WithdrawInformations } from './components'
import { WithdrawProvider } from './context/WithdrawContext'
import MoneyAsText from '@/components/Common/MoneyAsText'

export const Withdraw = () => {
  const { data } = useBalance()

  return (
    <WithdrawProvider>
      <main className='flex flex-col px-3 gap-5 mb-6 text-slate-200'>
        <CardWithdrawSection />
        <>
          <ul className='flex flex-row gap-3 border p-3 rounded-lg border-primary'>
            <li>
              Saldo de Equipe: <MoneyAsText value={data.networkBalance} />
            </li>
            <li>
              Saldo de Lucros: <MoneyAsText value={data.operationBalance} />
            </li>
          </ul>
          <SelectMethodSection />
          <WithdrawInformations />
        </>
      </main>
    </WithdrawProvider>
  )
}
