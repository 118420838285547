import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'

export const NavItem = ({ path, text = '', className, children }) => {
  const { pathname } = useLocation()

  return (
    <Link
      to={path}
      className={classNames('hover:text-gray-300 relative', className, {
        'text-gray-100 before:bg-secondary before:absolute before:left-[50%] before:-translate-x-1/2 before:mx-auto before:-bottom-2 before:h-2 before:w-2 before:rounded-full':
          pathname === path,
      })}
    >
      {text ? text : children}
    </Link>
  )
}
