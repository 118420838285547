import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'
import { Boarding } from './Boarding'

export const Section = ({ title, children, sectionClassName, icon }) => {
  return (
    <section className={twMerge(classNames('flex flex-col mx-4', sectionClassName))}>
      <Boarding text={title} icon={icon} />
      {children}
    </section>
  )
}
