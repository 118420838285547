import { MoneyField, TextField } from '@/components/Form/Fields'
import { useWithdraw } from '../context/WithdrawContext'
import { Button } from '@/components/Form'
import { ClockInformation } from '.'
import { useTranslation } from 'react-i18next'

export const FormCrypto = () => {
  const { t } = useTranslation()
  const { formCrypto, loadingRequestWithdraw } = useWithdraw()
  const { handleSubmit, values, handleChange, errors, setFieldValue } = formCrypto

  return (
    <form onSubmit={handleSubmit}>
      <div className='flex flex-col md:flex-row gap-3 items-center w-full'>
        <MoneyField
          isDisabled={loadingRequestWithdraw}
          label={t('withdraw.amountField')}
          errorMessage={errors.amount}
          name='amount'
          onChange={e => setFieldValue('amount', e.numberValue)}
          className='w-full'
          inputClassName='bg-blue-300'
          value={values.amount}
          placeholder='Ex.: R$ 100,00'
          noMargins
        />
        <TextField
          isDisabled={loadingRequestWithdraw}
          label={t('withdraw.cryptoField')}
          errorMessage={errors.cryptoWallet}
          name='cryptoWallet'
          onChange={handleChange}
          className='w-full'
          inputClassName='bg-blue-300'
          value={values.cryptoWallet}
          placeholder='Ex.: ihuasdgahsdb1231231yui23gu1b23'
          noMargins
        />
      </div>
      <ClockInformation className='my-3' />
      <Button
        isLoading={loadingRequestWithdraw}
        isDisabled={loadingRequestWithdraw}
        type='submit'
        text={t('withdraw.buttonConfirm')}
        className='w-full py-3'
      />
    </form>
  )
}
