import { useFormik } from 'formik'
import * as Yup from 'yup'

export const useFormFilters = ({ onSubmit }) => {
  const Schema = Yup.object({
    term: Yup.string(),
  })

  return useFormik({
    initialValues: {
      term: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
