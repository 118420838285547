import MoneyAsText from '@/components/Common/MoneyAsText'
import classNames from 'classnames'
import { twJoin } from 'tailwind-merge'

export const BetItem = ({ value = 0, active, setBetValue, hide }) => {
  return (
    <div
      onClick={() => setBetValue(value)}
      className={classNames('w-full bg-gradient-to-b from-blue-600 to-80% to-blue-500 p-[2px] rounded cursor-pointer', {
        'hidden lg:flex': hide,
      })}
    >
      <div
        className={classNames('bg-blue-500 w-full p-3 flex flex-col items-center gap-3 rounded', {
          'bg-primary-50 text-zinc-900 font-semibold': active,
        })}
      >
        <img className={twJoin('w-5', active && 'scale-110 brightness-0')} src='/icons/handCoin.svg' alt='coins' />

        <MoneyAsText value={value} />
      </div>
    </div>
  )
}
