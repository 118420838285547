import React, { createContext, useContext, useEffect, useState } from 'react'
import { useFormCrypto, useFormPaypal, useFormPix, useRequestWithdraw } from '../hooks'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useAuth, useBalance } from '@/contexts'
import Swal from 'sweetalert2'
import { useFetchtWithdrawals } from '../hooks/useFetchWithdrawals'

const WithdrawContext = createContext({
  type: 1,
  loadingRequestWithdraw: false,
  statusCodeRequestWithdraw: 0,
  setType: () => {},
  requestWithdraw: () => {},
  dataWithdraw: {},
  isLoadingWithdraw: {},
  formCrypto: {},
  formPaypal: {},
  formPix: {},
})

export const WithdrawProvider = ({ children }) => {
  const { t } = useTranslation()
  const { balance, refreshBalance } = useBalance()
  const { user } = useAuth()
  const [type, setType] = useState(1)

  const minimumWithdrawal = user.company.withdraw.minimumAmount

  const { dataRequestWithdraw, errorRequestWithdraw, loadingRequestWithdraw, makeWithdraw, statusCodeRequestWithdraw } =
    useRequestWithdraw()
  const { dataWithdrawals, loadingWithdrawals, makeWithdrawals } = useFetchtWithdrawals()

  const verifyAmount = amount => {
    if (amount > balance) {
      toast.error(t('withdraw.insuficienteBalance'), {
        position: 'top-center',
        autoClose: 2000,
      })
      return true
    }
    if (amount < minimumWithdrawal) {
      const formattedValue = minimumWithdrawal.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
      toast.error(`${t('withdraw.minWithdraw')}${formattedValue}`, {
        position: 'top-center',
        autoClose: 2000,
      })
      return true
    }
  }

  const handleResetForm = () => {
    formCrypto.resetForm()
    formPix.resetForm()
    formPaypal.resetForm()
  }

  const formCrypto = useFormCrypto({
    onSubmit: values => {
      if (verifyAmount(values.amount)) return
      makeWithdraw({
        wallet: values.cryptoWallet,
        amount: values.amount,
        type: 2,
        cryptoCurrency: type === 4 ? 'USDT' : 'BTC',
      })
    },
  })

  const formPix = useFormPix({
    onSubmit: values => {
      if (verifyAmount(values.amount)) return
      makeWithdraw({
        receiverName: values.name,
        receiverCpf: values.cpf,
        pix: values.key,
        pixType: values.pixKey,
        amount: values.amount,
        type: 1,
      })
    },
  })

  const formPaypal = useFormPaypal({
    onSubmit: values => {
      if (verifyAmount(values.amount)) return
      makeWithdraw({
        paypalEmailOrPhone: values.paypalEmailOrPhone,
        amount: values.amount,
        type: 3,
      })
    },
  })

  useEffect(() => {
    makeWithdrawals()
  }, [])

  useEffect(() => {
    if (!dataRequestWithdraw) return
    handleResetForm()
    refreshBalance()
    makeWithdrawals()
    Swal.fire({
      title: t('global.successTitle'),
      text: t('withdraw.successWithdraw'),
      icon: 'success',
      confirmButtonText: 'Ok',
    })
    setType(1)
  }, [dataRequestWithdraw])

  useEffect(() => {
    if (!errorRequestWithdraw) return

    if (errorRequestWithdraw.error === 'withdrawCustomerIsBlocked') {
      toast.error('Bloqueado para realizar saque!', {
        position: 'top-center',
        autoClose: 2000,
      })
      return
    }

    if (errorRequestWithdraw.error === 'invalidWithdrawTimeRange') {
      toast.error('Pedido de Saque fora do horário permitido!', {
        position: 'top-center',
        autoClose: 2000,
      })
      return
    }

    toast.error(t('withdraw.errorWithdraw'), {
      position: 'top-center',
      autoClose: 2000,
    })
  }, [errorRequestWithdraw])

  return (
    <WithdrawContext.Provider
      value={{
        type,
        setType,
        loadingRequestWithdraw,
        statusCodeRequestWithdraw,
        formCrypto,
        formPix,
        formPaypal,
        dataWithdrawals,
        loadingWithdrawals,
      }}
    >
      {children}
    </WithdrawContext.Provider>
  )
}

export const useWithdraw = () => ({ ...useContext(WithdrawContext) })
