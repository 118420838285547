import classNames from 'classnames'
import { Progress } from '@chakra-ui/react'
import { FaCheck } from 'react-icons/fa6'
import { GoTasklist } from 'react-icons/go'
import { calculateGraduationProgress, getGraduationName } from '@/constants/careerPath'
import { twJoin } from 'tailwind-merge'

export const GraduationItem = ({ graduation, hiddeRules }) => {
  const name = getGraduationName(graduation.position)
  const progressValue = calculateGraduationProgress(graduation)
  const isGraduated = graduation.isGraduated
  const pendingRules = graduation.rules?.filter(item => item.done === false).length

  return (
    <div
      className={classNames('flex flex-col gap-2 justify-between items-center border border-blue-600 rounded-2', {
        'bg-blue-100': isGraduated && !hiddeRules,
        'border-0': hiddeRules,
      })}
    >
      <div className={classNames('w-full flex flex-col items-center gap-2 p-3', { 'pb-0': !hiddeRules })}>
        <img
          className={twJoin('w-[50%] max-w-[120px]', hiddeRules && 'mb-2')}
          src={isGraduated ? '/icons/goalIconFull.svg' : '/icons/goalIconNull1.svg'}
          alt='diamond'
        />

        <div className='flex items-center gap-1 text-center'>
          <p className={classNames({ 'text-lg': hiddeRules }, { 'text-sm': !hiddeRules })}>{name}</p>
          {isGraduated && (
            <span className='flex justify-center items-center w-3 h-3 bg-primary-50 rounded-full'>
              <FaCheck className='text-[10px] text-slate-500' />
            </span>
          )}
        </div>
      </div>

      {!hiddeRules && <div className={classNames('w-full h-[1px] bg-slate-500/50', { 'bg-opacity-75': isGraduated })} />}

      {!hiddeRules && (
        <div className='w-full p-3 pt-0 flex flex-col gap-2'>
          <div className={classNames('flex gap-1 items-center text-xs', { 'text-gray-300': isGraduated })}>
            <GoTasklist className='text-lg text-primary-50' />
            {isGraduated ? <p>Tudo concluído!</p> : <p className='flex items-center'>{pendingRules} Tarefas Restantes</p>}
          </div>

          <Progress
            value={progressValue}
            size='sm'
            sx={{
              background: '#9D9D9D',
              '& > div': {
                background: '#D4F101',
              },
            }}
            rounded={12}
          />
        </div>
      )}
    </div>
  )
}
