import httpClient from '@/utils/httpClient'
import { useApi } from '@/hooks'
import { getEndpoint } from '@/endpoints'

export const useSendContract = () => {
  const { route, method } = getEndpoint('sendContract')
  const { data, loading, makeRequest, error } = useApi(values => httpClient[method](route, values))

  return {
    errorPurchase: error,
    dataPurchase: data,
    isLoading: loading,
    sendContract: makeRequest,
  }
}
