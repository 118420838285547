import { Modal } from '@/components/Common/Modal/Modal'
import { Spinner } from '@chakra-ui/spinner'
import { WithdrawModalCard } from './WithdrawModalCard'
import { TbFolderOff } from 'react-icons/tb'
import { useWithdraw } from '../context/WithdrawContext'

export const ModalWithdrawalsExtract = ({ isOpen, onClose }) => {
  const { dataWithdrawals, loadingWithdrawals } = useWithdraw()

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose} bg='#101828' color='#f8f9fa'>
      <Modal.Header text='Suas solicitações de saque' />

      <Modal.Body className='flex flex-col gap-3'>
        {loadingWithdrawals && (
          <div className='w-full flex justify-center items-center'>
            <Spinner className='text-primary' thickness='4px' />
          </div>
        )}

        {dataWithdrawals && dataWithdrawals.length > 0 ? (
          dataWithdrawals.map((item, ind) => {
            return <WithdrawModalCard key={ind} item={item} />
          })
        ) : (
          <div className='w-full p-2 py-4 bg-red-300/50 rounded-2 flex justify-center items-center gap-2'>
            <TbFolderOff className='text-xl' />
            <p>Nenhum saque encontrado nos registros.</p>
          </div>
        )}
      </Modal.Body>
    </Modal.Root>
  )
}
