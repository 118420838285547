import { Button } from '@/components/Form'
import { useAuth } from '@/contexts'

export const WhatsAppButton = () => {
  const { user } = useAuth()
  return (
    <a href={user?.company.whatsapp.supportGroupUrl} target='_blank' rel='noopener noreferrer'>
      <Button className='bg-primary/80 flex items-center gap-2 py-2.5'>
        <img className='w-4 drop-shadow-2xl shadow-black-100' src='/icons/whatsapp.svg' alt='whatsapp icon' />
        <p className='font-normal text-black'>Comunidade</p>
      </Button>
    </a>
  )
}
