import { useAuth } from '@/contexts'
import { BsWhatsapp } from 'react-icons/bs'

export const WhatsappButton = () => {
  const { user } = useAuth()
  return (
    <>
      <a
        href={`http://wa.me/${user?.company.whatsapp.supportPhone}?text=${user?.company.whatsapp.supportText}`}
        target='_blank'
        rel='noopener noreferrer'
        className='flex p-3 rounded-full bg-[#25D366] hover:bg-[#128C7E] transition fixed bottom-4 right-4 cursor-pointer shadow-xl z-10'
      >
        <BsWhatsapp color='#f8f8f8' size={32} />
      </a>
    </>
  )
}
