import * as Yup from 'yup'
import { useFormik } from 'formik'

export const useFormBalanceBet = ({ onSubmit }) => {
  const Schema = Yup.object({
    username: Yup.string(),
  })

  return useFormik({
    initialValues: {
      username: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
