import httpClient from '@/utils/httpClient'
import { useApi } from '@/hooks'
import { getEndpoint } from '@/endpoints'

export const usePurchaseWithBalance = () => {
  const { route, method } = getEndpoint('pusrchaseWithBalance')
  const { data, loading, error, makeRequest } = useApi(values => {
    return httpClient[method](route, { ...values, validatePrice: false })
  })

  return {
    purchaseWithBalanceData: data,
    purchaseWithBalanceLoading: loading,
    purchaseWithBalance: makeRequest,
    purchaseWithBalanceError: error,
  }
}
