import React from 'react'
import { Logo } from '../Common'
import SwitchLanguage from '../SwitchLanguage'

export const LoginHeader = () => {
  return (
    <header className='flex items-center justify-center py-3 border-b border-blue-100 w-full'>
      <div className='w-full md:max-w-[625px] flex flex-col md:flex-row items-center lg:justify-between gap-2'>
        <Logo className='max-w-[9.375rem]' />
        <SwitchLanguage />
      </div>
    </header>
  )
}
