export const CONTRACT_PURCHASE = 1
export const NETWORK_COMMISSION = 2
export const WITHDRAW_REQUEST = 3
export const WITHDRAW_PERCENT_TAX = 4
export const MANUAL_BONUS = 5
export const MANUAL_REVERSING = 6
export const OPERATION_PROFITABILITY = 7
export const RESIDUAL_PROFIT = 8
export const DAILY_LIMIT_REVERSING = 9
export const OPERATION_COMMISSION = 10
export const CONTRACT_FEES = 11
export const BALANCE_ACTIVATION = 12
export const WITHDRAW = 13
export const WITHDRAW_REFUND = 14

export const getTypeName = type => {
  switch (type) {
    case CONTRACT_PURCHASE:
      return 'extract.transactionTypes.contractPurchase'
    case OPERATION_COMMISSION:
      return 'extract.transactionTypes.residualCommission'
    case CONTRACT_FEES:
      return 'extract.transactionTypes.contractFees'
    case DAILY_LIMIT_REVERSING:
      return 'extract.transactionTypes.dailyLimitReversal'
    case NETWORK_COMMISSION:
      return 'extract.transactionTypes.networkCommission'
    case WITHDRAW:
      return 'extract.transactionTypes.withdraw'
    case MANUAL_BONUS:
      return 'extract.transactionTypes.manualBonus'
    case OPERATION_PROFITABILITY:
      return 'extract.transactionTypes.operationProfit'
    case MANUAL_REVERSING:
      return 'extract.transactionTypes.manualReversing'
    case BALANCE_ACTIVATION:
      return 'extract.transactionTypes.balanceActivation'
    case RESIDUAL_PROFIT:
      return 'extract.transactionTypes.residualProfit'
    case WITHDRAW_REQUEST:
      return 'extract.transactionTypes.withdrawRequest'
    case WITHDRAW_REFUND:
      return 'extract.transactionTypes.withdrawRefound'
    case WITHDRAW_PERCENT_TAX:
      return 'extract.transactionTypes.withdrawPercentTax'

    default:
      break
  }
}

export const getAllTypes = () => {
  return [
    { value: CONTRACT_PURCHASE, label: getTypeName(CONTRACT_PURCHASE) },
    { value: OPERATION_COMMISSION, label: getTypeName(OPERATION_COMMISSION) },
    { value: DAILY_LIMIT_REVERSING, label: getTypeName(DAILY_LIMIT_REVERSING) },
    { value: NETWORK_COMMISSION, label: getTypeName(NETWORK_COMMISSION) },
    { value: WITHDRAW, label: getTypeName(WITHDRAW) },
    { value: MANUAL_BONUS, label: getTypeName(MANUAL_BONUS) },
    { value: OPERATION_PROFITABILITY, label: getTypeName(OPERATION_PROFITABILITY) },
    { value: MANUAL_REVERSING, label: getTypeName(MANUAL_REVERSING) },
  ]
}
