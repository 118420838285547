import { useTranslation } from 'react-i18next'
import { Avatar, FormProfile, PersonalInfo, PersonalCard } from './components'
import { useAuth } from '@/contexts'
import { useEditProfile, useFormProfile } from './hooks'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Section, BoxRoot, GraduationPercentCard } from '@/components/Common'
import { getGraduationName } from '@/constants/careerPath'
import { useGraduation } from '../CarrerPath/hooks'

export const Profile = () => {
  const { t } = useTranslation()
  const { user, refresh, loading } = useAuth()
  const [editCpf, setEditCpf] = useState(false)
  const { data: graduateData, requestGraduation } = useGraduation()

  const formProfile = useFormProfile({
    onSubmit: values => {
      const formatedCpf = values.cpf.replace('.', '').replace('.', '').replace('-', '')
      makeEditProfile({ ...values, indicationCode: user.indicationCode, cpf: formatedCpf })
    },
  })

  const { setFieldValue } = formProfile

  const { dataEditProfile, errorEditProfile, loadingEditProfile, makeEditProfile } = useEditProfile()

  useEffect(() => {
    if (!dataEditProfile) return
    refresh().then(() => {
      toast.success('Dados editados com sucesso!')
    })
  }, [dataEditProfile])

  useEffect(() => {
    if (!errorEditProfile) return
    toast.error('Erro ao tentar editar os dados!')
  }, [errorEditProfile])

  useEffect(() => {
    setFieldValue('name', user?.name)
    setFieldValue('cpf', user?.cpf)
    setFieldValue('username', user?.username)
    setFieldValue('email', user?.email)
    setFieldValue('phone', user?.phone)
    setFieldValue('address', user?.address)
    setFieldValue('currencyCode', user?.currencyCode)

    if (!user?.cpf) {
      setEditCpf(true)
    }
    requestGraduation()
  }, [user])

  if (loading) return

  return (
    <Section title='Bem-Vindo(a) ao Perfil' icon='/icons/profileIcon.svg' sectionClassName='mb-4'>
      <div className='flex flex-col md:flex-row gap-5 md:gap-6 w-full'>
        <div className='flex flex-col w-full md:max-w-[400px] gap-5 md:gap-3'>
          <BoxRoot className='w-full rounded-b-none border-b border-primary md:border-0 p-[40px] md:rounded-b-2 bg-blue-400 mt-3'>
            <div className='flex flex-col gap-3 justify-center items-center'>
              <Avatar />
              <div className='text-center text-text'>
                <p className='font-bold'>{user?.name}</p>
                <p>
                  {t('signIn.login')}: {user?.username}
                </p>
              </div>
            </div>
          </BoxRoot>
          <BoxRoot className='w-full rounded-t-none md:hidden -mt-5 bg-blue-400'>
            <PersonalInfo className='flex flex-col gap-3' />
          </BoxRoot>
          <div className='flex flex-col gap-3 w-full'>
            <GraduationPercentCard profileScreen name={getGraduationName(graduateData?.graduationStats?.position)} value={100} />
            <PersonalCard />
          </div>
        </div>
        <div className='w-full flex flex-col gap-4'>
          <PersonalInfo />
          <FormProfile
            editCpf={editCpf}
            setEditCpf={setEditCpf}
            formProfile={formProfile}
            loadingEditProfile={loadingEditProfile}
          />
        </div>
      </div>
    </Section>
  )
}
