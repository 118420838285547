import React, { createContext, useContext, useState } from 'react'
import { useEffect } from 'react'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { GetawayBallanceForm } from '@/pages/License/components/GetawayBallanceForm'
import { GetawayForm } from '@/pages/License/components/GetawayForm'
import { BALLANCE, BITCOIN, DOLLAR, PIX, PIXPASTEANDCOLE } from '@/constants/betsConstants'
import { useCancelPix, useFetchAccountData, useFetchAmountBet, useFetchBetList, useFetchPrice } from '@/pages/License/hooks'
import { GetawayPixForm } from '@/pages/License/components/GetawayPixForm'
import { PixAndColeForm } from '@/pages/License/components'
import { useGeneratePixCode } from '@/pages/License/hooks/useGeneratePixCode'
import { GetawayPixPasteAndCole } from '@/pages/License/components/GetawayPixPasteAndCole'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { useVerifyContract } from '@/pages/License/hooks/useVerifyPix'

const BetsContext = createContext({
  paymentMethod: PIXPASTEANDCOLE,
  paymentValue: 100,
  formVisible: false,
  dataCoins: {},
  qrCodeData: {},
  betList: [],
  contractOffers: [],
  dataPixCode: [],
  isLoadingBetList: false,
  loadingAmountBet: false,
  isLoadingQrData: false,
  isLoading: false,
  isDisable: false,
  purchaseWithBalanceLoading: false,
  setPaymentMethod: () => {},
  setPaymentValue: () => {},
  setIsFormVisible: () => {},
  setPaymentReceiptAttached: () => {},
  renderPaymentSections: () => {},
  renderPaymentForms: () => {},
  getConvertValue: () => {},
  generatePixCode: () => {},
  setQrCodeData: () => {},
  handleFileChange: () => {},
  handleFileSubmit: () => {},
  handleCancelFile: () => {},
  handleRequestDeposit: () => {},
  handleResetLocalStorage: () => {},
  handleCancelPix: () => {},
})

export const BetsProvider = ({ children }) => {
  const [paymentMethod, setPaymentMethod] = useState(PIXPASTEANDCOLE)
  const [paymentReceiptAttached, setPaymentReceiptAttached] = useState(null)
  const [paymentValue, setPaymentValue] = useState(100)
  const [minAmount, setMinAmount] = useState(100)
  const [formVisible, setIsFormVisible] = useState(false)
  const [betsList, setBetsList] = useState([])
  const [qrCodeData, setQrCodeData] = useState({})
  const [isDisable, setIsDisable] = useState(false)
  const { dataCoins, makeRequestCoins } = useFetchPrice()
  const { betList, fetchBetLis, isLoadingBetList } = useFetchBetList()
  const { dataAccount, fetchAccount, isLoadingAccount } = useFetchAccountData()
  const { fetchAmountBet, contractOffers, loadingAmountBet } = useFetchAmountBet()
  const { dataPixCode, generatePixCode, isLoading } = useGeneratePixCode()
  const { verifyContract } = useVerifyContract()
  const { makeCancelPix } = useCancelPix()
  const [timerVerify, setTimer] = useState(0)

  const renderPaymentSections = () => {
    switch (paymentMethod) {
      case PIXPASTEANDCOLE:
        return <PixAndColeForm />
      case PIX:
        return <GetawayPixForm />
      case BALLANCE:
        return <GetawayBallanceForm />
      default:
        return <GetawayForm />
    }
  }

  const renderPaymentForms = () => {
    switch (paymentMethod) {
      case PIXPASTEANDCOLE:
        return <GetawayPixPasteAndCole />
      default:
        return <GetawayForm cancelbtn />
    }
  }

  const handleFileChange = e => {
    const hasAttachment = e.target.files.length > 0

    const acceptFiles = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']

    if (e.target.files[0].size > 2097152) {
      toast.warn('Tamanho máximo de arquivo excedido!')
      return
    }

    if (!acceptFiles.includes(e.target.files[0].type)) {
      toast.warn('Tipo de arquivo não permitido!')
      return
    }

    hasAttachment && setPaymentReceiptAttached(e.target.files[0])
  }

  const handleRequestDeposit = values => {
    if (values.cpf === '' || values.cpf === undefined || values.cpf === null) return
    if (paymentValue === 0 || paymentValue === null || paymentValue === undefined) {
      toast.error('O valor do contrato deve ser maior que zero'),
        {
          position: 'top-right',
          autoClose: 2000,
        }
      return
    }
    if (paymentValue < minAmount) {
      toast.warn(`O valor do contrato deve ser maior que R$ ${minAmount.toFixed(2).replace('.', ',')}`),
        {
          position: 'top-right',
          autoClose: 2000,
        }
      return
    }
    generatePixCode({ ...values, amount: paymentValue }).catch(err => {
      const errorResponse = err.response.data.data.error

      if (errorResponse === 'invalidCpf' || errorResponse === 'cpfWithRepeatedChars') {
        toast.error('Cpf inválido!')
        return
      }

      if (errorResponse === 'invalidPhone') {
        toast.error('Telefone inválido!')
        return
      }

      if (errorResponse === 'invalidAmount') {
        toast.error('Valor de compra inválida!')
        return
      }
    })
  }

  const handleCancelPix = () => {
    Swal.fire({
      title: 'Cancelar Pagamento!',
      text: 'Deseja continuar?',
      icon: 'warning',
      confirmButtonText: 'Cancelar',
      showCancelButton: true,
      cancelButtonText: 'Fechar',
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        makeCancelPix(qrCodeData.id)
          .then(({ data }) => {
            if (data.status === 'success') {
              toast.warning('Aposta Cancelada!'),
                {
                  position: 'top-right',
                  autoClose: 2000,
                }
              handleResetLocalStorage()
              fetchBetLis()
            }
          })
          .catch(() => {
            toast.error('Ocorreu um erro!'),
              {
                position: 'top-right',
                autoClose: 2000,
              }
          })
      }
    })
  }

  const handleResetLocalStorage = () => {
    const allKeys = Object.keys(localStorage)
    const permanentKeys = ['auth:user', '_grecaptcha']
    const removedKeys = allKeys.filter(keys => !permanentKeys.includes(keys))
    removedKeys.forEach(keys => {
      localStorage.removeItem(keys)
    })
    setPaymentMethod(PIXPASTEANDCOLE)
    setQrCodeData({})
    setIsDisable(false)
    setIsFormVisible(false)
    clearInterval(timerVerify)
    setTimer(0)
  }

  const handleFileSubmit = () => {
    setPaymentReceiptAttached(null)
    makeRequestCoins()
    fetchBetLis()
    fetchAmountBet()
    fetchAccount()
  }

  const handleCancelFile = () => {
    setPaymentReceiptAttached(null)
  }

  const getPrefix = paymentMethod => {
    switch (paymentMethod) {
      case PIXPASTEANDCOLE:
      case PIX:
      case BALLANCE:
      case BITCOIN:
        return '₿ '
      case DOLLAR:
        return 'U$ '
      default:
        return 'R$ '
    }
  }

  const getConvertValue = value => {
    if (paymentMethod === PIXPASTEANDCOLE || paymentMethod === PIX || paymentMethod === BALLANCE) {
      return <MoneyAsText prefixProp={getPrefix(paymentMethod)} value={value} />
    }

    if (paymentMethod === BITCOIN) {
      return `₿ ${(value / dataCoins?.BTCBRL?.bid).toFixed(7)}`
    }

    if (paymentMethod === DOLLAR) {
      return <MoneyAsText prefixProp={getPrefix(paymentMethod)} value={value / dataCoins?.USDBRL?.bid} />
    }

    return value
  }

  const verifyPixTimer = id => {
    let timer = setInterval(() => {
      verifyContract(id).then(({ data }) => {
        if (data.data.status === 5) {
          toast.success('Pagamento confirmado!', {
            position: 'top-right',
            autoClose: 2000,
          })
          handleResetLocalStorage()
          fetchBetLis()
        }
        if (data.data.status === 4) {
          toast.warning('Pagamento cancelado!', {
            position: 'top-right',
            autoClose: 2000,
          })
          handleResetLocalStorage()
          fetchBetLis()
        }
      })
    }, 5000)

    setTimer(timer)
  }

  useEffect(() => {
    makeRequestCoins()
    fetchBetLis()
    fetchAmountBet()
    fetchAccount()
  }, [])

  useEffect(() => {
    setBetsList(betList)
  }, [betList])

  useEffect(() => {
    handleCancelFile()
  }, [paymentMethod])

  useEffect(() => {
    if (!dataPixCode) return
    setQrCodeData(dataPixCode)
    setIsFormVisible(true)
    setIsDisable(true)
    window.localStorage.setItem('dataPixCode', JSON.stringify(dataPixCode))
    window.localStorage.setItem('typePayment', paymentMethod)
    window.localStorage.setItem('amountPayment', paymentValue)
    window.localStorage.setItem('isButtonDisabled', isDisable)

    const timer = verifyPixTimer(dataPixCode.id)

    return () => clearInterval(timer)
  }, [dataPixCode])

  useEffect(() => {
    if (contractOffers.length === 0) return
    const newMinAmount = Math.min(...contractOffers)

    setMinAmount(newMinAmount)
    setPaymentValue(newMinAmount)
  }, [contractOffers])

  useEffect(() => {
    makeRequestCoins()
    fetchBetLis()
    fetchAmountBet()
    fetchAccount()
    const getQrCode = window.localStorage.getItem('dataPixCode')
    const getPaymentType = window.localStorage.getItem('typePayment')
    const getPaymentAmount = window.localStorage.getItem('amountPayment')
    const getIsButtonDisabled = window.localStorage.getItem('isButtonDisabled')
    if (getQrCode && getPaymentType && getPaymentAmount) {
      setIsFormVisible(true)
      const objectData = JSON.parse(getQrCode)

      setPaymentMethod(getPaymentType)
      setPaymentValue(Number(getPaymentAmount))
      setQrCodeData(objectData)
      setIsDisable(getIsButtonDisabled)

      const timer = verifyPixTimer(JSON.parse(getQrCode).id)

      return () => clearInterval(timer)
    }
  }, [])

  return (
    <BetsContext.Provider
      value={{
        paymentMethod,
        paymentValue,
        formVisible,
        dataCoins,
        betsList,
        qrCodeData,
        dataPixCode,
        dataAccount,
        contractOffers,
        paymentReceiptAttached,
        isLoadingBetList,
        isLoadingAccount,
        isLoading,
        isDisable,
        loadingAmountBet,
        setPaymentMethod,
        setPaymentValue,
        setIsFormVisible,
        setQrCodeData,
        setPaymentReceiptAttached,
        renderPaymentSections,
        renderPaymentForms,
        getConvertValue,
        generatePixCode,
        handleFileChange,
        handleFileSubmit,
        handleCancelFile,
        handleRequestDeposit,
        handleResetLocalStorage,
        handleCancelPix,
      }}
    >
      {children}
    </BetsContext.Provider>
  )
}

export const useBets = () => {
  return { ...useContext(BetsContext) }
}
