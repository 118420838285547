import httpClient from '@/utils/httpClient'
import { useApi } from '@/hooks'
import { getEndpoint } from '@/endpoints'

export const useCancelPix = () => {
  const { data, loading, makeRequest, error } = useApi(id => {
    const { route, method } = getEndpoint('cancelPix', { id })
    return httpClient[method](route)
  })

  return {
    errorContract: error,
    dataContract: data,
    isLoading: loading,
    makeCancelPix: makeRequest,
  }
}
