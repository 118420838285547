/**
 * ~> Private Route Example:
 * {
 *   path: '/licenses',
 *   pageComponent: ComponentName,
 *   breadcrumb: [{ name: 'Licenças', to: '/licenses' }, { name: 'Consulta' }],
 *   l: LoginComponentName, (default: Layout)
 *   enabled: true, (default: true)
 * }
 *
 * ~> Public Route Example:
 * {
 *   path: '/sign-in',
 *   pageComponent: SignIn,
 *   breadcrumb: [],
 *   isPublic: true, (default: false)
 *   layoutComponent: LoginComponentName, (default: Layout)
 *   enabled: true, (default: true)
 * }
 */

import { Dashboard } from '@/pages/Dashboard'
import { Extract } from '@/pages/Extract'
import { License } from '@/pages/License'
import { Profile } from '@/pages/Profile'
import { Withdraw } from '@/pages/Withdraw'
import { SignIn } from '@/pages/SignIn'
import { SignUp } from '@/pages/SignUp'
import { Network } from '@/pages/Network'
import { Logout } from '@/pages/Logout'
import { DesignPage } from '@/pages/Home'
import { ResetPassword } from '@/pages/ResetPassword'
import { LoginLayout } from '@/pages/LoginLayout'
import { BetsProvider } from './contexts/BetsContext'
import { CarrerPath } from './pages/CarrerPath'

export const routes = [
  {
    path: '/',
    pageComponent: SignIn,
    breadcrumb: [],
    isPublic: true,
    layoutComponent: LoginLayout,
  },
  {
    path: '/design',
    pageComponent: DesignPage,
    breadcrumb: [],
  },
  {
    path: '/dashboard',
    pageComponent: Dashboard,
    breadcrumb: [],
  },
  {
    path: '/extract',
    pageComponent: Extract,
    breadcrumb: [],
  },
  {
    path: '/bets/',
    pageComponent: () => (
      <BetsProvider>
        <License />
      </BetsProvider>
    ),
    breadcrumb: [],
  },
  {
    path: '/bets/:betValue',
    pageComponent: () => (
      <BetsProvider>
        <License />
      </BetsProvider>
    ),
    breadcrumb: [],
  },
  {
    path: '/network',
    pageComponent: Network,
    breadcrumb: [],
  },
  {
    path: '/profile',
    pageComponent: Profile,
    isPublic: true,
    breadcrumb: [],
  },
  {
    path: '/withdraw',
    pageComponent: Withdraw,
    breadcrumb: [],
  },
  {
    path: '/career-path',
    pageComponent: CarrerPath,
    breadcrumb: [],
  },
  {
    path: '/sign-in',
    pageComponent: SignIn,
    breadcrumb: [],
    isPublic: true,
    layoutComponent: LoginLayout,
  },
  {
    path: '/sign-up',
    pageComponent: SignUp,
    breadcrumb: [],
    isPublic: true,
    layoutComponent: LoginLayout,
  },
  {
    path: '/reset-senha/:verificationCode',
    pageComponent: ResetPassword,
    layoutComponent: LoginLayout,
    isPublic: true,
  },
  {
    path: '/clients/:indicatedCode',
    pageComponent: SignUp,
    isPublic: true,
    layoutComponent: LoginLayout,
  },
  {
    path: '/logout',
    pageComponent: Logout,
    isPublic: true,
  },
]
