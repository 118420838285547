import { IconBadge } from '@/components/Common/IconBadge'
import crypto from '/images/crypto.svg'
import { useWithdraw } from '../context/WithdrawContext'
import { BITCOIN, PAYPAL, PIX, USDT, getTypeWithdraw, getTypeWithdrawIcon } from '../constants'
import { FormCrypto, FormPaypal, FormPix } from '.'
import { useAuth, useBalance } from '@/contexts'
import { Loader } from '@/components/Common'
import { FaInfoCircle } from 'react-icons/fa'
import { Tooltip } from '@chakra-ui/react'

export const WithdrawInformations = () => {
  const { type } = useWithdraw()
  const { user, loadingBalance } = useAuth()
  const isCryptoType = () => type === BITCOIN || type === USDT

  const tooltipLabel = () => {
    const now = new Date()
    const dayOfWeekAllowed = user.company.withdraw.daysOfWeek.find(({ day }) => day === now.getDay())
    const days = user?.company?.withdraw.fixedDays
    const isWithdrawFixDay = days.find(({ day }) => day === now.getDate())
    const { data } = useBalance()

    if (dayOfWeekAllowed) {
      const type = dayOfWeekAllowed.type

      if (type.length === 1) {
        return type[0] === 1 ? `Você pode sacar: R$ ${data.networkBalance}` : `Você pode sacar: R$ ${data.operationBalance}`
      }

      if (type.length === 2) {
        return `Você pode sacar: R$ ${data.balance}`
      }
    }

    if (isWithdrawFixDay) {
      const type = isWithdrawFixDay.type

      if (type.length === 1) {
        return type[0] === 1 ? `Você pode sacar: R$ ${data.networkBalance}` : `Você pode sacar: R$ ${data.operationBalance}`
      }

      if (type.length === 2) {
        return `Você pode sacar: R$ ${data.balance}`
      }
    }
  }

  return (
    <div className='flex gap-4 flex-col'>
      {loadingBalance && (
        <div className='h-full w-full flex justify-center items-center'>
          <Loader />
        </div>
      )}
      {!loadingBalance && (
        <>
          <div className='flex items-center gap-2'>
            <IconBadge
              size={20}
              iconImg={isCryptoType() ? crypto : type === PAYPAL ? getTypeWithdrawIcon(PAYPAL) : getTypeWithdrawIcon(PIX)}
            />
            <p className='text-lg'>
              {isCryptoType() && 'Criptomoeda | '}
              {getTypeWithdraw(type)}
              {type === USDT && ` (${user.company.usdt.network.split(' ')[0]})`}
            </p>

            <small className='sm:hidden text-sx'>{tooltipLabel()}</small>

            <div className='hidden sm:flex'>
              <Tooltip label={tooltipLabel()} placement='top'>
                <span className='cursor-pointer'>
                  <FaInfoCircle />
                </span>
              </Tooltip>
            </div>
          </div>
          {isCryptoType() && <FormCrypto />}
          {type === PAYPAL && <FormPaypal />}
          {type === PIX && <FormPix />}
        </>
      )}
    </div>
  )
}
