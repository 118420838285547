import React from 'react'
import { useBets } from '@/contexts/BetsContext'

export const FormSection = () => {
  const { renderPaymentSections, formVisible, renderPaymentForms } = useBets()
  return (
    <div className='flex flex-col p-3 w-full rounded-3 bg-surface md:p-4'>
      <div className='flex flex-col gap-3'>
        {!formVisible && renderPaymentSections()}
        {formVisible && renderPaymentForms()}
      </div>
    </div>
  )
}
