import { Select } from '@/components/Form'
import { FiList } from 'react-icons/fi'

export const CustomSelect = ({ value, onChange, optionPrimary, options }) => {
  return (
    <div className='w-full mt-1.5 border border-gray rounded-2 flex items-center gap-2 relative' data-bs-theme='dark'>
      <Select
        value={value}
        name='type'
        onChange={onChange}
        optionPrimary={optionPrimary}
        className='h-full py-[10px] px-3 lg:py-[10px] lg:px-3 appearance-none focus:outline-none border border-gray-200 text-slate-300 bg-none rounded'
        options={options}
      />

      <FiList className='text-lg absolute right-3 pointer-events-none' />
    </div>
  )
}
