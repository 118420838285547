import { useEffect } from 'react'
import { ProfitItem } from '../components/ProfitItem'
import { Progress, Text } from '@chakra-ui/react'
import { useFetchCustomerProfits } from '../hooks/useFetchCustomerProfits'
import { useFetchProfitTotal } from '../hooks/useFetchProfitTotal'
import { LiaTrophySolid } from 'react-icons/lia'
import { useFetchPercentLicense } from '../hooks/useFetchPercentLicense'
import { BalanceCards } from '@/components/Common/BalanceCards'

export const Ballance = () => {
  const { dataCustomerProfit, fetchCustomerProfit } = useFetchCustomerProfits()
  const { dataProfitTotal, fetchProfitTotal } = useFetchProfitTotal()
  const { dataPercentLicense, fetchPercentLicense } = useFetchPercentLicense()

  useEffect(() => {
    fetchCustomerProfit()
    fetchProfitTotal()
    fetchPercentLicense()
  }, [])

  return (
    <div className='w-full flex flex-col gap-4 mt-3'>
      <BalanceCards />

      <div className='w-full flex flex-col items-center lg:flex-row gap-4 mt-2'>
        <div className='w-full flex items-center gap-3'>
          <ProfitItem
            className='lg:justify-start'
            icon='/icons/calendar.svg'
            title='Lucro do Mês'
            value={dataCustomerProfit?.month}
            border
          />

          <ProfitItem icon='/icons/monthPercent.svg' title='Porcentagem do Mês' value={dataProfitTotal?.month} border percent />
        </div>

        <div className='w-full flex items-center gap-3'>
          <ProfitItem icon='/icons/handIcon1.svg' title='Lucro do Dia' value={dataCustomerProfit?.day} border />

          <ProfitItem
            className='lg:justify-end'
            icon='/icons/dayPercent.svg'
            title='Porcentagem do Dia'
            value={dataProfitTotal?.day}
            percent
          />
        </div>
      </div>

      <div className='w-full border border-blue-500 rounded-2 p-4 flex flex-col gap-3 mt-3'>
        <h3 className='text-lg'>Sua porcentagem de ganhos %</h3>
        <div className='flex flex-col items-center justify-center md:flex-row gap-3'>
          <Progress
            isAnimated
            hasStripe
            value={dataPercentLicense?.percent || 0}
            max={dataPercentLicense?.dailyLimit}
            sx={{
              background: '#9D9D9D',
              '& > div': {
                background: '#D4F101',
              },
            }}
            height={25}
            rounded={12}
            className='w-full'
          >
            <Text
              className='text-sm font-bold'
              position='absolute'
              left='50%'
              top='50%'
              transform='translate(-50%, -50%)'
              color='#22293D'
            >
              {dataPercentLicense?.percent || 0}%
            </Text>
          </Progress>

          <div className='flex justify-between items-center -mt-2 text-sm'>
            <span>{dataPercentLicense?.dailyLimit}%</span> <LiaTrophySolid className='text-lg' />
          </div>
        </div>
      </div>
    </div>
  )
}
