import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useEditPassword = () => {
  const { makeRequest, loading, data } = useApi((verificationCode, values) =>
    httpClient.post(`/auth/reset-password/${verificationCode}`, values)
  )

  return {
    loadingPassword: loading,
    editPassword: makeRequest,
    dataPassword: data,
  }
}
