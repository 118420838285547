import { useTranslation } from 'react-i18next'
import pixIcon from '/images/pixIcon.svg'
import bitcoinIcon from '/images/bitcoinIcon.svg'
import tether from '/images/tether.svg'
import paypalIcon from '/images/paypalIcon.svg'

export const PENDING = 1
export const APPROVED = 2
export const REFUSED = 3
export const CANCELLED_BY_USER = 4
export const AWAITING_PAYMENT = 'AWAITING_PAYMENT'
export const UNDER_ANALYSIS = 'UNDER_ANALYSIS'
export const ACTIVE = 'ACTIVE'
export const ACTIVE_VOUCHER = 'ACTIVE_VOUCHER'

export const getWithdrawalsStatus = status => {
  let statusText
  let color

  switch (status) {
    case PENDING:
      statusText = 'withdraw.withdrawStatus.pending'
      color = 'bg-sky-400'
      break
    case APPROVED:
      statusText = 'withdraw.withdrawStatus.approved'
      color = 'bg-green-500'
      break
    case REFUSED:
      statusText = 'withdraw.withdrawStatus.refused'
      color = 'bg-red-500'
      break
    case CANCELLED_BY_USER:
      statusText = 'withdraw.withdrawStatus.canceledByUser'
      color = 'bg-red-500'
      break
    case AWAITING_PAYMENT:
      statusText = 'withdraw.withdrawStatus.awaitingPayment'
      color = 'bg-yellow-500'
      break
    case UNDER_ANALYSIS:
      statusText = 'withdraw.withdrawStatus.underAnalysis'
      color = 'bg-orange-500'
      break
    case ACTIVE:
      statusText = 'withdraw.withdrawStatus.active'
      color = 'bg-green-500'
      break
    case ACTIVE_VOUCHER:
      statusText = 'withdraw.withdrawStatus.activeVoucher'
      color = 'bg-green-500'
      break
    default:
      statusText = status
      color = 'bg-gray-500'
      break
  }

  return { statusText, color }
}

// Method Type
export const PIX = 1
export const BITCOIN = 2
export const PAYPAL = 3
export const USDT = 4

//PIX Type
export const CPF = 'CPF'
export const CNPJ = 'CNPJ'
export const EMAIL = 'EMAIL'
export const PHONE = 'PHONE'
export const RANDOM_KEY = 'RANDOM'

export const getTypePix = type => {
  const { t } = useTranslation()
  switch (type) {
    case CPF:
      return t('global.cpf')
    case CNPJ:
      return t('global.cnpj')
    case EMAIL:
      return t('global.email')
    case PHONE:
      return t('global.phone')
    case RANDOM_KEY:
      return t('global.randomKey')
  }
}

export const getTypePixOptions = () => {
  return [
    { label: getTypePix(CPF), value: CPF },
    { label: getTypePix(CNPJ), value: CNPJ },
    { label: getTypePix(EMAIL), value: EMAIL },
    { label: getTypePix(PHONE), value: PHONE },
    { label: getTypePix(RANDOM_KEY), value: RANDOM_KEY },
  ]
}

export const getTypeWithdraw = type => {
  const { t } = useTranslation()
  switch (type) {
    case PIX:
      return t('global.pix')
    case PAYPAL:
      return t('bets.paypalButton')
    case BITCOIN:
      return t('global.bitcoin')
    case USDT:
      return t('global.tether')
  }
}

export const getTypeWithdrawIcon = type => {
  switch (type) {
    case PIX:
      return pixIcon
    case PAYPAL:
      return paypalIcon
    case BITCOIN:
      return bitcoinIcon
    case USDT:
      return tether
  }
}

export const getTypeWithdrawAbbr = type => {
  const { t } = useTranslation()
  switch (type) {
    case PIX:
      return t('global.pixAbbr')
    case PAYPAL:
      return t('global.pixAbbr')
    case BITCOIN:
      return t('global.bitcoinAbbr')
    case USDT:
      return t('global.tetherAbbr')
  }
}

export const getTypeWithdrawOptions = () => {
  return [
    { label: getTypeWithdraw(PIX), icon: getTypeWithdrawIcon(PIX), abbr: getTypeWithdrawAbbr(PIX), value: PIX },
    { label: getTypeWithdraw(PAYPAL), icon: getTypeWithdrawIcon(PAYPAL), abbr: getTypeWithdrawAbbr(PAYPAL), value: PAYPAL },
    { label: getTypeWithdraw(BITCOIN), icon: getTypeWithdrawIcon(BITCOIN), abbr: getTypeWithdrawAbbr(BITCOIN), value: BITCOIN },
    { label: getTypeWithdraw(USDT), icon: getTypeWithdrawIcon(USDT), abbr: getTypeWithdrawAbbr(USDT), value: USDT },
  ]
}
