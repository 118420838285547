import MoneyAsText from '@/components/Common/MoneyAsText'
import classNames from 'classnames'
import { IoCalendarOutline } from 'react-icons/io5'
import { getWithdrawalsStatus } from '../constants'
import { DateAsText } from '@/components/Common'
import { useTranslation } from 'react-i18next'

export const WithdrawModalCard = ({ item }) => {
  const { t } = useTranslation()
  return (
    <div className='flex flex-col md:flex-row items-center bg-gray-400/25 p-3 rounded-2 gap-2'>
      <p
        className={classNames('w-full text-lg', {
          'text-red-500 flex flex-col gap-2': item.status === 'REFUSED',
        })}
      >
        <span>
          {t('withdraw.value')}: <MoneyAsText value={item.amount} />
        </span>
        {item.status === 'REFUSED' && (
          <>
            <span className='underline max-w-[80%]'>
              {t('withdraw.reason')}: {item.refusedReason || ''}
            </span>

            <span>
              {t('withdraw.refusedAt')}: <DateAsText date={item.createdAt} type='datetime' />
            </span>
          </>
        )}
      </p>

      <div className='flex items-center gap-3 whitespace-nowrap self-end'>
        <div className='flex items-center gap-2'>
          <IoCalendarOutline className='text-lg' />
          <DateAsText type='datetime' date={item.createdAt} />
        </div>

        <span className={classNames('text-center py-1 px-2 text-white rounded', getWithdrawalsStatus(item.status).color)}>
          {t(getWithdrawalsStatus(item.status).statusText)}
        </span>
      </div>
    </div>
  )
}
