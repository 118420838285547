import { Button } from '@/components/Form'
import { FaUserEdit } from 'react-icons/fa'

export const ButtonSection = ({ loadingEditProfile }) => {
  return (
    <div className='flex flex-col xl:flex-row gap-3 xl:gap-4 items-center mt-2 md:mt-4'>
      <Button
        type='submit'
        isDisabled={loadingEditProfile}
        isLoading={loadingEditProfile}
        className='w-full py-3'
        iconLeft={loadingEditProfile ? '' : <FaUserEdit className='mb-1' />}
        text='Atualizar modificações'
      />
    </div>
  )
}
