import { useState } from 'react'
import { SidebarMobile } from './SidebarMobile'
import { HiOutlineMenuAlt2 } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { Logo } from '@/components/Common'

export const MenuMobile = () => {
  const [isMenuOpen, setOpenMenu] = useState()
  return (
    <div className='xl:hidden w-full flex justify-between items-center'>
      <div className='w-full flex justify-between items-center relative'>
        <button className='absolute left-2 self-center text-gray-50' onClick={() => setOpenMenu(true)}>
          <HiOutlineMenuAlt2 size={36} />
        </button>

        <Link className='flex items-center shrink-0 mx-auto' to='/'>
          <Logo width={120} />
        </Link>
      </div>

      <SidebarMobile isMenuOpen={isMenuOpen} setOpenMenu={setOpenMenu} />
    </div>
  )
}
