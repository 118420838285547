import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { MdOutlineCopyAll } from 'react-icons/md'
import { useAuth } from '@/contexts'
import { RoundedIcon } from '.'
import TelegramIcon from '/images/telegram_icon.svg'
import WhatsAppIcon from '/images/whatsapp_icon.svg'
import InstagramIcon from '/images/instagram_icon.svg'
import FacebookIcon from '/images/facebook_icon.svg'

const ReferenceLink = () => {
  const { user } = useAuth()
  const indicationUrl = `${import.meta.env.VITE_BASE_URL}/clients/${user?.indicationCode}`

  return (
    <div className='w-full flex items-center gap-3 cursor-pointer'>
      <CopyToClipboard
        text={indicationUrl}
        onCopy={() =>
          toast.success('Copiado com sucesso!', {
            position: 'top-center',
            autoClose: 2000,
          })
        }
      >
        <div className='flex p-3 rounded-md gap-2 items-center justify-center lg:justify-between bg-blue-400 text-slate-200 w-full text-center'>
          <div className='text-sm truncate'>{indicationUrl}</div>

          <div className='text-lg'>
            <MdOutlineCopyAll />
          </div>
        </div>
      </CopyToClipboard>
      <div className='hidden gap-3 items-center lg:flex'>
        <RoundedIcon url={TelegramIcon} path='' />
        <RoundedIcon url={WhatsAppIcon} path='' />
        <RoundedIcon url={InstagramIcon} path='' />
        <RoundedIcon url={FacebookIcon} path='' />
      </div>
    </div>
  )
}

export default ReferenceLink
