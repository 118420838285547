export function ResumeValueCard({ children }) {
  return (
    <div className='lg:w-full flex gap-[14px] justify-center items-center min-w-[295px] h-[51px] rounded-[10px] max-lg:border border-[#363C4D] backdrop-blur[20.69229507446289px]'>
      {children}
    </div>
  )
}

function Icon({ path, imgClasses }) {
  return (
    <div className='w-auto'>
      <img className={imgClasses} src={path} />
    </div>
  )
}

function TextContent({ children }) {
  return (
    <div className='w-4/6'>
      <p className='w-full truncate'>{children}</p>
    </div>
  )
}

ResumeValueCard.Icon = Icon
ResumeValueCard.Text = TextContent
