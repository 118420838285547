import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

export const SelectItem = ({ children, isSelected, onClick, icon, alt, iconClassName, className }) => {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        classNames(
          'flex flex-col justify-center items-center py-2.5 rounded cursor-pointer gap-2 font-semibold bg-blue-300 text-slate-300',
          className,
          {
            'ring-4 ring-inset ring-primary/50 border border-slate-200/25': isSelected,
            'border border-gray-500': !isSelected,
          }
        )
      )}
    >
      <span className='p-2 bg-primary-50 rounded-1'>
        <img src={icon} alt={alt} className={twMerge(classNames('w-3', iconClassName))} />
      </span>
      {children}
    </button>
  )
}
